import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import InnerPagesComponent from "../../components/InnerPagesComponent/InnerPagesComponent";
import LoadMoneyComponent from "../../components/LoadMoneyComponent/LoadMoneyComponent";
import {
  getPayments,
  stripePay,
  getRates,
  coinPay
} from "../../store/actions/BillingActions";
import Loader from "../../shared/Loader";
import { resetReducer } from "../../store/actions/resetReducerAction";

class LoadMoneyContainer extends Component {
  state = {
    loader: false
  };

  componentWillMount() {
    this.props.getPayments({
      provider: this.props.optionsData.provider
    });
  }

  componentDidMount() {
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/";
    script.async = true;
    document.body.appendChild(script);
  }

  componentWillUnmount() {
    this.props.resetReducer("RESET_BILLING_REDUCER");
  }

  onLoading = loader => {
    this.setState({
      loader
    });
  };

  render() {
    if (this.props.StripePaymentData.fetched) {
      return <Redirect to="/control/billing" />;
    }
    return (
      <div>
        <InnerPagesComponent
          pageName="Load Money"
          logo={this.props.optionsData.options.logo}
        >
          <Helmet>
            <title>
              Load Money | {this.props.optionsData.displayName} Control Panel
            </title>
          </Helmet>
          {this.props.PaymentData.pending && <Loader />}
          {this.props.StripePaymentData.pending && <Loader />}
          {this.state.loader && <Loader />}
          {this.props.PaymentData.fetched && (
            <LoadMoneyComponent
              PaymentData={this.props.PaymentData}
              stripePay={this.props.stripePay}
              onLoading={this.onLoading}
              getRates={this.props.getRates}
              RatesData={this.props.RatesData}
              coinPay={this.props.coinPay}
              CoinData={this.props.CoinData}
              optionsData={this.props.optionsData}
            />
          )}
        </InnerPagesComponent>
      </div>
    );
  }
}

const mapStateFromProps = store => ({
  optionsData: store.DashboardReducer.optionsData,
  PaymentData: store.BillingInfoReducer.PaymentData,
  StripePaymentData: store.BillingInfoReducer.StripePaymentData,
  RatesData: store.BillingInfoReducer.RatesData,
  CoinData: store.BillingInfoReducer.CoinData
});

const mapDispatchFromProps = dispatch => ({
  getPayments: data => dispatch(getPayments(data)),
  stripePay: data => dispatch(stripePay(data)),
  resetReducer: name => dispatch(resetReducer(name)),
  getRates: () => dispatch(getRates()),
  coinPay: data => dispatch(coinPay(data))
});

export default withRouter(
  connect(
    mapStateFromProps,
    mapDispatchFromProps
  )(LoadMoneyContainer)
);
