import axios from "axios";
import { ENV } from "../../environment";
import Auth from "../Auth/Auth";

const HTTP = {
  async get(path, body, currentState, domain = null) {
    const token = await this.refreshToken();
    const URL = `${!domain ? ENV.HOST : domain}/${path}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token || ""
    };
    return axios.get(URL, { headers });
  },

  async post(path, body, currentState, domain = null) {
    const URL = `${!domain ? ENV.HOST : domain}/${path}`;
    const token = await this.refreshToken();
    const headers = {
      "Content-Type": "application/json",
      Authorization: token || ""
    };
    return axios({
      method: "post",
      url: URL,
      data: JSON.stringify(body),
      timeout: 40000,
      headers
    });
  },

  async put(path, body, currentState, domain = null) {
    const URL = `${!domain ? ENV.HOST : domain}/${path}`;
    const token = await this.refreshToken();
    const headers = {
      "Content-Type": "application/json",
      Authorization: token || ""
    };
    return axios({
      method: "put",
      url: URL,
      data: JSON.stringify(body),
      timeout: 40000,
      headers
    });
  },
  async delete(path, body, currentState, domain = null) {
    const URL = `${!domain ? ENV.HOST : domain}/${path}`;
    const token = await this.refreshToken();
    const headers = {
      "Content-Type": "application/json",
      Authorization: token || ""
    };
    return axios({ url: URL, method: "delete", timeout: 40000, headers });
  },

  refreshToken() {
    return new Promise(async (resolve, reject) => {
      try {
        const data = await Auth.currentSession();
        resolve(data.idToken.jwtToken);
      } catch (e) {
        resolve(null);
      }
    });
  }
};

const creator = (actionType, payload = null) => ({ type: actionType, payload });

const HttpService = (
  method,
  domain = undefined,
  path,
  actionType,
  body = null
) => (dispatch, currentState) => {
  dispatch(creator(actionType.PENDING));
  // const token = await refreshAccessToken();
  HTTP[method.toLowerCase()](path, body, currentState, domain, "")
    .then(response => dispatch(creator(actionType.SUCCESS, response.data)))
    .catch(error =>
      dispatch(
        creator(
          actionType.ERROR,
          error.response ? error.response.data.message : error.message
        )
      )
    );
};

export default HttpService;
