import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import InnerPagesComponent from "../../components/InnerPagesComponent/InnerPagesComponent";
import ReferralsComponent from "../../components/ReferralsComponent/ReferralsComponent";
import {
  getReferrals,
  getReferralsLast
} from "../../store/actions/referralsActions";

class ReferralsContainer extends Component {
  componentDidMount() {
    const { getReferrals, optionsData: { provider } } = this.props;
    getReferrals({
      filter: {
        userType: ["referral"],
        type: ["date"]
      },
      provider
    });
  }

  render() {
    const {
      optionsData: {
        options: { logo },
        displayName,
        provider
      },
      getReferralsLast,
      referralsData
    } = this.props;

    return (
      <div>
        <InnerPagesComponent pageName="" logo={logo}>
          <Helmet>
            <title>Referrals | {displayName} Control Panel</title>
          </Helmet>
          <ReferralsComponent
            referralsData={referralsData}
            getReferralsLast={getReferralsLast}
            provider={provider}
          />
        </InnerPagesComponent>
      </div>
    );
  }
}

const mapStateFromProps = store => ({
  optionsData: store.DashboardReducer.optionsData,
  referralsData: store.ReferralsReducer.referralsData
});

const mapDispatchFromProps = dispatch => ({
  getReferrals: data => dispatch(getReferrals(data)),
  getReferralsLast: data => dispatch(getReferralsLast(data))
});

export default withRouter(
  connect(
    mapStateFromProps,
    mapDispatchFromProps
  )(ReferralsContainer)
);
