import React from "react";
import { PropTypes } from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import "./inputs.scss";

library.add(faInfo);

const ENTER_KEY = 13;
const regexp = {
  text: /^[a-z0-9]+$/i,
  number: /^\d*[1-9]\d*$/,
  name: /^[a-zA-ZàáâäãåèéêëìíîïòóôöõøùúûüÿýñçčšžÀÁÂÄÃÅÈÉÊËÌÍÎÏÒÓÔÖÕØÙÚÛÜŸÝÑßÇŒÆČŠŽ∂ð',.\- ]+$/,
  search: /^[a-zA-ZàáâäãåèéêëìíîïòóôöõøùúûüÿýñçčšžÀÁÂÄÃÅÈÉÊËÌÍÎÏÒÓÔÖÕØÙÚÛÜŸÝÑßÇŒÆČŠŽ∂ð',.\- ]+$/,
  // utf8: /^[0-9a-zA-Z$@$!%*?&.+-/ /]+$/,
  // userName:/^[A-Za-z0-9]{3,30}(?:[_-][A-Za-z0-9]+)*$/,
  companyName: /^(?!\s)(?!.*\s$)(?=.*[a-zA-Z0-9])[a-zA-Z0-9 '~?!][A-Za-z0-9_.]{2,}[a-zA-Z0-9_ ]*$/,
  phone: /^\d{6,}(?:,(?:\+\d{2})?\d{6,})*$/,
  address: /^.[^&]{2,29}$/,
  // social: /(https?:\/\/[^\s]+)/,
  // ssn: /^([0-9]){3}(([ ]|[-])?([0-9]){2})(([ ]|[-])?([0-9]){4})?$/,
  // domain: /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/,
  // zip: {
  //   US: /^\d{5}(?:[-\s]\d{4})?$/,
  //   CA: /^\d{5}-\d{4}|\d{5}|[A-Z]\d[A-Z] \d[A-Z]\d$/,
  //   AU: /^\d{4}(?:[-\s]\d{4})?$/,
  //   GB: /[A-Za-z]{1,2}[0-9Rr][0-9A-Za-z]? [0-9][ABD-HJLNP-UW-Zabd-hjlnp-uw-z]{2}/,
  //   ALL: /^([a-zA-Z0-9_-]){1,8}$/
  // },
  zip: /^([a-zA-Z0-9_-]){1,8}$/,
  // cvc: /^\d{3,4}$/,
  // password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@#$!%*?&])[A-Za-z\d$@$!%*?&]{8,}/,
  password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,}$/,
  email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  bgUpload: /^(([a-zA-Z]:)|(\\{2}\w+)\$?)(\\(\w[\w].*))+(.jpg|.jpeg)$/
};

const onChangeHandler = (e, onChange, password, confirmPassword) => {
  const { name, value, type } = e.target;
  let valid;
  valid = regexp[type].test(value);
  if (
    type === "confirmPassword" &&
    regexp[type].test(value) &&
    password !== confirmPassword
  )
    valid = false;
  onChange(name, value, valid);
};

const Input = React.memo(props => {
  const {
    type,
    name,
    title,
    value,
    valid,
    placeholder,
    inputClass,
    icon,
    inputSize,
    dropdownIcon,
    onChange,
    password,
    confirmPassword,
    disabled,
  } = props;

  let errorMessage;
  const passwordsArentEqual =
    name === "confirmPassword" &&
    regexp.password.test(confirmPassword) &&
    password !== confirmPassword;
  if (passwordsArentEqual && confirmPassword && password)
    errorMessage = "Passwords doesn't match";
  if (!value) errorMessage = "Can't be empty";
  if (
    type === "password" &&
    (name.toLowerCase().includes("password") &&
      (!regexp.password.test(password) ||
        !regexp.password.test(confirmPassword)))
  )
    errorMessage = "Incorrect password !";
  if (type === "number" && +value < 0) errorMessage = "Incorrect value";
  if (type === "text" && !regexp.text.test(value))
    errorMessage = "Incorrect input value";
  return (
    <div className={`input-box ${inputSize} mx-auto text-left`}>
      {title && <label>{title}</label>}
      <div className={`input-wrapper mb-0 ${!valid && "border-error"}`}>
        {name === "password" && (
          <span className="password-info-icon">
            <FontAwesomeIcon icon="info" className="my-icons fa-2x" />
          </span>
        )}
        <input
          style={{
            paddingLeft: `${icon ? (dropdownIcon ? "60px" : "50px") : "20px"}`
          }}
          className={`${inputClass} standart-input form-control`}
          type={type || ""}
          name={name || ""}
          value={value}
          placeholder={placeholder || ""}
          onChange={e =>
            onChangeHandler(e, onChange, password, confirmPassword)
          }
          disabled={disabled}
          // onKeyPress={e => console.log(e.which)}
        />
      </div>
      {(!valid || passwordsArentEqual) && name !== "search" && (
        <span>{errorMessage}</span>
      )}
    </div>
  );
});

export default Input;

Input.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired
  ]).isRequired,
  password: PropTypes.string,
  confirmPassword: PropTypes.string,
  valid: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  inputClass: PropTypes.string.isRequired,
  inputSize: PropTypes.string,
  icon: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
};
