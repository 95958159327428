import React, { useEffect, Fragment } from "react";
import { connect } from "react-redux";
import HeaderComponent from "./HeaderComponent/HeaderComponent";
import NavbarComponent from "./NavbarComponent/NavbarComponent";
import {
  getPermissions,
  getFinance
} from "../../store/actions/DashboarActions";
import "./InnerPagesComponent.scss";

const InnerPagesComponent = ({
  getFinance,
  logo,
  financeData: { data },
  pageName,
  children, provider
}) => {

  useEffect(() => {
    getFinance({ provider });
  }, [getFinance]);

  return (
    <Fragment>
      <HeaderComponent logo={logo} financeData={data} />
      <section className="inner-section">
        <NavbarComponent />
        <section className="main-content">
          <div className="inner-panel">
            <div className="container-fluid">
              {pageName !== "" && (
                <div className="content-header clearfix">
                  <h1>{pageName}</h1>
                </div>
              )}
              {children}
            </div>
          </div>
        </section>
      </section>
    </Fragment>
  );
};
const mapStateFromProps = store => ({
  provider: store.DashboardReducer.optionsData.provider,
  financeData: store.DashboardReducer.financeData
});

const mapDispatchFromProps = dispatch => ({
  getPermissions: provider => dispatch(getPermissions(provider)),
  getFinance: (data) => dispatch(getFinance(data))
});

export default connect(
  mapStateFromProps,
  mapDispatchFromProps
)(InnerPagesComponent);
