import { handleActions } from "redux-actions";
import {
  LOGIN,
  CHECK_EMAIL,
  REGISTRATION,
  VERIFY_EMAIL,
  PROFILE_INFO,
  RESET_LOGIN_ERRORS,
  SECOND_STEP_SUBMIT,
  GET_USER_INFO
} from "../constants";

const defaultState = {
  needPasswordChange: false,
  needMFA: false,
  user: null,
  success: false,
  error: null,
  logout: false,
  pending: false,
  firstTime: false,
  checkEmailData: {
    pending: false,
    success: false,
    error: null
  },
  registrationData: {
    pending: false,
    success: false,
    error: null
  },
  verifyEmailData: {
    pending: false,
    success: false,
    error: null
  },
  ProfileInfoData: {
    pending: false,
    success: false,
    error: null,
    data: null
  },
  userInfo: {
    pending: false,
    success: false,
    data: null
  }
};

// const loginReducer = handleActions(
//   {
//     [LOGIN.PASSWORD_CHANGE_REQUIRED]: (state = defaultState, { payload }) => ({
//       ...state,
//       needPasswordChange: true,
//       user: payload
//     }),

//     [LOGIN.MFA_REQUIRED]: (state = defaultState, { payload }) => ({
//       ...state,
//       pending: false,
//       success: true,
//       message: payload,
//       redirect: true
//     }),

//     [LOGIN.PENDING]: (state = defaultState) => ({
//       ...state,
//       needPasswordChange: false,
//       needMFA: false,
//       error: null,
//       logout: false,
//       pending: true
//     }),

//     [LOGIN.SUCCESS]: (
//       state = defaultState,
//       { payload: { token, firstTime } }
//     ) => ({
//       ...state,
//       success: true,
//       needPasswordChange: false,
//       needMFA: false,
//       pending: false,
//       firstTime,
//       user: token
//     }),

//     [LOGIN.ERROR]: (state = defaultState, { payload: { error } }) => ({
//       ...state,
//       error,
//       pending: false,
//       success: false
//     }),

//     [LOGIN.LOGOUT_SUCCESS]: (state = defaultState) => ({
//       ...state,
//       logout: true,
//       needPasswordChange: false,
//       needMFA: false,
//       user: null,
//       success: false,
//       error: null,
//       checkEmailData: {
//         pending: false,
//         success: false,
//         error: null
//       },
//       registrationData: {
//         pending: false,
//         success: false,
//         error: null
//       },
//       verifyEmailData: {
//         pending: false,
//         success: false,
//         error: null
//       },
//       ProfileInfoData: {
//         pending: false,
//         success: false,
//         error: null
//       }
//     }),

//     [CHECK_EMAIL.PENDING]: (state = defaultState) => ({
//       ...state,
//       checkEmailData: {
//         pending: true,
//         success: false,
//         error: null
//       }
//     }),

//     [CHECK_EMAIL.SUCCESS]: (
//       state = defaultState,
//       { payload: { status, message } }
//     ) => ({
//       ...state,
//       checkEmailData: {
//         pending: false,
//         success: status,
//         error: !status ? message : null
//       }
//     }),

//     [CHECK_EMAIL.SUCCESS]: (state = defaultState, { error }) => ({
//       ...state,
//       checkEmailData: {
//         pending: false,
//         success: false,
//         error
//       }
//     }),

//     [VERIFY_EMAIL.PENDING]: (state = defaultState) => ({
//       ...state,
//       verifyEmailData: {
//         pending: true,
//         success: false,
//         error: null
//       }
//     }),

//     [VERIFY_EMAIL.SUCCESS]: (state = defaultState, { payload }) => ({
//       ...state,
//       verifyEmailData: {
//         pending: false,
//         success: payload,
//         error: null
//       }
//     }),

//     [VERIFY_EMAIL.ERROR]: (state = defaultState, { payload }) => ({
//       ...state,
//       verifyEmailData: {
//         pending: false,
//         success: false,
//         error: payload
//       }
//     }),

//     [PROFILE_INFO.PENDING]: (state = defaultState) => ({
//       ...state,
//       ProfileInfoData: {
//         pending: true,
//         success: false,
//         error: null
//       }
//     }),

//     [PROFILE_INFO.SUCCESS]: (state = defaultState, { payload }) => ({
//       ...state,
//       ProfileInfoData: {
//         pending: false,
//         success: true,
//         error: null,
//         data: payload
//       }
//     }),

//     [PROFILE_INFO.ERROR]: (state = defaultState, { payload }) => ({
//       ...state,
//       ProfileInfoData: {
//         pending: false,
//         success: false,
//         error: payload
//       }
//     }),

//     [RESET_LOGIN_ERRORS]: (state = defaultState) => ({
//       ...state,
//       error: null
//     }),

//     [GET_USER_INFO.PENDING]: (state = defaultState) => ({
//       ...state,
//       userInfo: {
//         ...state.userInfo,
//         pending: true,
//         success: false
//       }
//     }),

//     [GET_USER_INFO.SUCCESS]: (state = defaultState, { payload }) => ({
//       ...state,
//       userInfo: {
//         ...state.userInfo,
//         pending: false,
//         success: true,
//         data: payload
//       }
//     })
//   },
//   defaultState
// );

const loginReducer = (state = defaultState, action) => {
  const { type, payload, error } = action;
  switch (type) {
    case LOGIN.PASSWORD_CHANGE_REQUIRED: {
      return {
        ...state,
        needPasswordChange: true,
        user: payload
      };
    }
    case LOGIN.MFA_REQUIRED: {
      return {
        ...state,
        needMFA: true,
        user: payload
      };
    }
    case LOGIN.PENDING: {
      return {
        ...state,
        needPasswordChange: false,
        needMFA: false,
        error: null,
        logout: false,
        pending: true
      };
    }
    case LOGIN.SUCCESS: {
      return {
        ...state,
        success: true,
        needPasswordChange: false,
        needMFA: false,
        pending: false,
        firstTime: payload.firstTime,
        user: payload.token
      };
    }
    case LOGIN.ERROR: {
      return {
        ...state,
        error,
        pending: false,
        success: false
      };
    }
    case GET_USER_INFO.SUCCESS:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          success: true,
          data: payload
        }
      };
    case LOGIN.LOGOUT_SUCCESS: {
      return {
        ...state,
        logout: true,
        needPasswordChange: false,
        needMFA: false,
        user: null,
        success: false,
        error: null,
        checkEmailData: {
          pending: false,
          success: false,
          error: null
        },
        registrationData: {
          pending: false,
          success: false,
          error: null
        },
        verifyEmailData: {
          pending: false,
          success: false,
          error: null
        },
        ProfileInfoData: {
          pending: false,
          success: false,
          error: null
        }
      };
    }
    case CHECK_EMAIL.PENDING: {
      return {
        ...state,
        checkEmailData: {
          pending: true,
          success: false,
          error: null
        }
      };
    }
    case CHECK_EMAIL.SUCCESS: {
      return {
        ...state,
        checkEmailData: {
          pending: false,
          success: payload.status,
          error: !payload.status ? payload.message : null
        }
      };
    }
    case CHECK_EMAIL.ERROR: {
      return {
        ...state,
        checkEmailData: {
          pending: false,
          success: false,
          error
        }
      };
    }
    case VERIFY_EMAIL.PENDING: {
      return {
        ...state,
        verifyEmailData: {
          pending: true,
          success: false,
          error: null
        }
      };
    }
    case VERIFY_EMAIL.SUCCESS: {
      return {
        ...state,
        verifyEmailData: {
          pending: false,
          success: payload,
          error: null
        }
      };
    }
    case VERIFY_EMAIL.ERROR: {
      return {
        ...state,
        verifyEmailData: {
          pending: false,
          success: false,
          error: payload
        }
      };
    }
    case PROFILE_INFO.PENDING: {
      return {
        ...state,
        ProfileInfoData: {
          pending: true,
          success: false,
          error: null
        }
      };
    }
    case PROFILE_INFO.SUCCESS: {
      return {
        ...state,
        ProfileInfoData: {
          pending: false,
          success: true,
          error: null,
          data: payload
        }
      };
    }
    case PROFILE_INFO.ERROR: {
      return {
        ...state,
        ProfileInfoData: {
          pending: false,
          success: false,
          error: payload
        }
      };
    }
    case RESET_LOGIN_ERRORS:
      return {
        ...state,
        error: null
      };
    default:
      return state;
  }
};

export default loginReducer;
