import {GET_BILLING_INFO,GET_PAYMENTS,STRIPE_PAYMENT,GET_RATES,COIN_PAYMENT,COIN_WITHDRAW} from '../constants';

const defaultState = {
    BillingInfoData: {
        pending: false,
        fetched: false,
        error: null,
        balance: null,
        invoices: null,
        LastEvaluatedKey: null
    },
    PaymentData: {
        pending: false,
        fetched: false,
        error: null,
        data: null
    },
    StripePaymentData: {
        pending: false,
        fetched: false,
        error: null,
    },
    RatesData: {
        pending: false,
        fetched: false,
        error: null,
        data: null
    },
    CoinData: {
        pending: false,
        fetched: false,
        error: null,
        data: null
    },
    CoinWithdrawData: {
        pending: false,
        fetched: false,
        error: null,
    },
}
const BillingInfoReducer = (state = defaultState, action) => {
    const { type, payload, error } = action;
    switch (type) {
        case GET_BILLING_INFO.PENDING: {
            return {
                ...state,
                BillingInfoData: {
                    pending: true,
                    fetched: false,
                    error: null,
                    data: null
                }
            }
        }
        case GET_BILLING_INFO.SUCCESS: {
            return {
                ...state,
                BillingInfoData: {
                    pending: false,
                    fetched: true,
                    error: null,
                    balance: payload.balance,
                    invoices: payload.invoices.Items,
                    LastEvaluatedKey: payload.invoices.LastEvaluatedKey
                }
            }
        }
        // case GET_REFERRALS_LAST.SUCCESS: {
        //     return {
        //         ...state,
        //         referralsData: {
        //             pending: false,
        //             fetched: true,
        //             error: null,
        //             data: [...state.referralsData.data,...payload.Items],
        //             LastEvaluatedKey: payload.LastEvaluatedKey
        //         }
        //     }
        // }
        case GET_BILLING_INFO.ERROR: {
            return {
                ...state,
                BillingInfoData: {
                    pending: false,
                    fetched: false,
                    error: error,
                    balance: null,
                    invoices: null,
                    LastEvaluatedKey: null
                }
            }
        }
        case GET_PAYMENTS.PENDING: {
            return {
                ...state,
                PaymentData: {
                    pending: true,
                    fetched: false,
                    error: null,
                    data: null
                }
            }
        }
        case GET_PAYMENTS.SUCCESS: {
            return {
                ...state,
                PaymentData: {
                    pending: false,
                    fetched: true,
                    error: null,
                    data: payload
                }
            }
        }
        case GET_PAYMENTS.ERROR: {
            return {
                ...state,
                PaymentData: {
                    pending: false,
                    fetched: false,
                    error: error,
                    data: null
                }
            }
        }
        case STRIPE_PAYMENT.PENDING: {
            return {
                ...state,
                StripePaymentData: {
                    pending: true,
                    fetched: false,
                    error: null,
                }
            }
        }
        case STRIPE_PAYMENT.SUCCESS: {
            return {
                ...state,
                StripePaymentData: {
                    pending: false,
                    fetched: true,
                    error: null,
                }
            }
        }
        case STRIPE_PAYMENT.ERROR: {
            return {
                ...state,
                StripePaymentData: {
                    pending: false,
                    fetched: false,
                    error: error,
                }
            }
        }
        case GET_RATES.PENDING: {
            return {
                ...state,
                RatesData: {
                    pending: true,
                    fetched: false,
                    error: null,
                    data: null
                }
            }
        }
        case GET_RATES.SUCCESS: {
            return {
                ...state,
                RatesData: {
                    pending: false,
                    fetched: true,
                    error: null,
                    data: payload
                }
            }
        }
        case GET_RATES.ERROR: {
            return {
                ...state,
                RatesData: {
                    pending: false,
                    fetched: false,
                    error: error,
                    data: null
                }
            }
        }
        case COIN_PAYMENT.PENDING: {
            return {
                ...state,
                CoinData: {
                    pending: true,
                    fetched: false,
                    error: null,
                    data: null
                }
            }
        }
        case COIN_PAYMENT.SUCCESS: {
            return {
                ...state,
                CoinData: {
                    pending: false,
                    fetched: true,
                    error: null,
                    data: payload
                }
            }
        }
        case COIN_PAYMENT.ERROR: {
            return {
                ...state,
                CoinData: {
                    pending: false,
                    fetched: false,
                    error: error,
                    data: null
                }
            }
        }
        case COIN_WITHDRAW.PENDING: {
            return {
                ...state,
                CoinWithdrawData: {
                    pending: true,
                    fetched: false,
                    error: null,
                }
            }
        }
        case COIN_WITHDRAW.SUCCESS: {
            return {
                ...state,
                CoinWithdrawData: {
                    pending: false,
                    fetched: true,
                    error: null,
                }
            }
        }
        case COIN_WITHDRAW.ERROR: {
            return {
                ...state,
                CoinWithdrawData: {
                    pending: false,
                    fetched: false,
                    error: error,
                }
            }
        }
        case "RESET_BILLING_REDUCER": {
            return {
                ...state,
                BillingInfoData: {
                    pending: false,
                    fetched: false,
                    error: null,
                    balance: null,
                    invoices: null,
                    LastEvaluatedKey: null
                },
                PaymentData: {
                    pending: false,
                    fetched: false,
                    error: null,
                    data: null
                },
                StripePaymentData: {
                    pending: false,
                    fetched: false,
                    error: null,
                },
                RatesData: {
                    pending: false,
                    fetched: false,
                    error: null,
                    data: null
                },
                CoinData: {
                    pending: false,
                    fetched: false,
                    error: null,
                    data: null
                },
                CoinWithdrawData: {
                    pending: false,
                    fetched: false,
                    error: null,
                },
            }
        }
        default: return state;
    }
}

export default BillingInfoReducer;