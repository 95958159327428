import { RECOVER_PASSWORD, SUBMITTING_RECOVERED_PASSWORD } from "../constants";
import Auth from "../../shared/Auth/Auth";

export const RecoverPasswordAction = userName => {
  return dispatch => {
    dispatch({ type: RECOVER_PASSWORD.PENDING });
    Auth.forgotPassword(userName)
      .then(payload => dispatch({ type: RECOVER_PASSWORD.SUCCESS, payload }))
      .catch(error => dispatch({ type: RECOVER_PASSWORD.ERROR, error }));
  };
};

export const submitRecoveredPassword = (userName, code, password) => {
  return dispatch => {
    dispatch({ type: SUBMITTING_RECOVERED_PASSWORD.PENDING });
    Auth.forgotPasswordSubmit(userName, code, password)
      .then(res => console.log("RES =>", res))
      .then(payload =>
        dispatch({ type: SUBMITTING_RECOVERED_PASSWORD.SUCCESS, payload })
      )
      .catch(error =>
        dispatch({ type: SUBMITTING_RECOVERED_PASSWORD.SUCCESS }, error)
      );
  };
};
export default RecoverPasswordAction;
