import React, { useState, useCallback, useEffect, Fragment } from "react";
import _ from "lodash";
import { Card, Button, Label } from "semantic-ui-react";
import { Col } from "reactstrap";
import AppModal from "./AppModal";
import DeleteModal from "./DeleteModal";
import "./App.scss";

const App = React.memo(
  ({
    data: {
      name,
      media_type: mediaType,
      category,
      genre,
      status,
      url,
      app_id: appId
    },
    data,
    handleSave,
    onDeleteModal,
    provider
  }) => {
    const [modal, setModal] = useState(false);
    const [secondModal, setSecondModal] = useState(false);
    const toggle = useCallback(() => setModal(!modal), [modal]);
    const deleteToggle = useCallback(() => setSecondModal(!secondModal), [
      secondModal
    ]);
    const [values, setValues] = useState(null);
    useEffect(() => {
      const initialValues = {
        app_id: appId,
        provider
      };
      // _.map(data, (value, key) => {
      //   if (
      //     key === "name" ||
      //     key === "url" ||
      //     key === "genre" ||
      //     key === "category" ||
      //     key === "media_type"
      //   ) {
      //     initialValues[key] = value;
      //   }
      // });
      initialValues.app_id = appId;
      initialValues.category = category;
      initialValues.genre = genre;
      initialValues.media_type = mediaType;
      initialValues.name = name;
      initialValues.url = url;

      setValues({ ...initialValues });
    }, [appId, category, data, genre, mediaType, name, provider, url]);

    return (
      <Fragment>
        {modal && (
          <AppModal
            open={modal}
            toggle={toggle}
            initialValues={values}
            handleSave={handleSave}
            provider={provider}
          />
        )}
        {secondModal && (
          <DeleteModal
            open={secondModal}
            toggle={deleteToggle}
            id={appId}
            handleDelete={onDeleteModal}
          />
        )}
        <Col
          sm="3"
          className="d-flex align-items-center flex-column app-card-col"
        >
          <Card raised className="app-card">
            <Card.Content className="row mt-3">
              <Card.Header className="col-sm-12">{name}</Card.Header>
              <Card.Description className="col-sm-12">
                {mediaType} | {category} | {genre}
              </Card.Description>
            </Card.Content>
            <Card.Content extra>
              <Label.Group
                color="yellow"
                className="d-flex justify-content-start"
              >
                <Label as="a" className="m-0 w-50 m-3 text-center">
                  {status}
                </Label>
              </Label.Group>
            </Card.Content>
            <Card.Content extra>
              <a href={url} className="app-card-link">
                {url}
              </a>
            </Card.Content>
            <Card.Content extra>
              <Col sm="12" className="p-0 d-flex justify-content-around">
                <Button
                  inverted
                  color="green"
                  className="col-sm-5 p-0"
                  onClick={() => toggle()}
                >
                  Edit
                </Button>
                <Button
                  inverted
                  color="red"
                  className="col-sm-5 p-0"
                  onClick={() => deleteToggle()}
                >
                  Delete
                </Button>
              </Col>
            </Card.Content>
          </Card>
        </Col>
      </Fragment>
    );
  }
);

export default App;
