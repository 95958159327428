import React, { Component } from 'react';
import './CreateCompaignsComponent.scss';
import Step1 from './Step1/Step1';
import Step2 from './Step2/Step2';
import Step3 from './Step3/Step3';
import uuid from 'uuid/v1';
import { ENV } from '../../environment';
import Loader from '../../shared/Loader/index';

const regExp = {
  url: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
};
class CreateCompaignsComponent extends Component {
  state = {
    step: '1',
    step1: {
      platform: 'AR',
      title: '',
    },
    url: {},
    urlType: 'webside',
    skippable: false,
    adType: 'fbxAd',
    videos360: {},
    object3d: null,
    adult: false,
    gambling: false,
    step3: {
      OS: 'all',
      Geo: 'all',
      Category: 'all',
      Time: 'all',
      CPM: '',
      totalBudget: '',
      dailyBudget: '',
    },
    urlValid: false,
    loader: false,
  };

  onStepChange = step => {
    this.setState({ step: step });
  };

  onStep1Changes = (name, value) => {
    let step1 = this.state.step1;
    step1[name] = value;
    if (name === 'platform' && value === 'AR') {
      this.setState({ adType: '3dObject' });
    }
    if (name === 'platform' && value === 'VR') {
      this.setState({ adType: '360video' });
    }
    this.setState({ step1 });
  };

  onUrlTypeChange = type => {
    this.setState({
      urlType: type,
    });
  };

  handleAdultChange = () => this.setState({adult: !this.state.adult});

  handleGambleChange = () => this.setState({gambling: !this.state.gambling});

  onChangeUrels = (value, type) => {
    let urls = document.getElementsByClassName('urlInput');
    if (this.state.urlType === 'webside') {
      let url = this.state.url;
      url['webSiteUrl'] = value;
      this.setState({ url: url });
      if (!regExp.url.test(value)) {
        this.setState({ urlValid: false });
        urls[0].style.border = '1px solid red';
      } else {
        this.setState({ urlValid: true });
        urls[0].style.border = '1px solid #e0e4e8';
      }
    } else if (this.state.urlType === 'app') {
      let url = this.state.url;
      delete url.webSiteUrl;
      if (type === 'google') {
        url['googlePlay'] = value;
        if (!regExp.url.test(value)) {
          this.setState({ urlValid: false });
          urls[0].style.border = '1px solid red';
        } else {
          urls[0].style.border = '1px solid #e0e4e8';
          this.setState({ urlValid: true });
        }
      } else if (type === 'appStore') {
        url['appStore'] = value;
        if (!regExp.url.test(value)) {
          urls[1].style.border = '1px solid red';
          this.setState({ urlValid: false });
        } else {
          urls[1].style.border = '1px solid #e0e4e8';
          this.setState({ urlValid: true });
        }
      }
      this.setState({ url: url });
    }
  };

  onSkipAbleChange = value => {
    this.setState({
      skippable: value,
    });
  };

  onChangeAdType = value => {
    this.setState({
      adType: value,
    });
  };

  onAllAndCustomeChange = (value, name) => {
    let step3 = this.state.step3;
    step3[name] = value;
    if (name === 'Time' && value === 'custom') {
      step3['Times'] = [{ startTime: '', endTime: '' }];
    }
    this.setState({ step3 });
  };

  onChangeTimes = (index, time, type) => {
    let step3 = this.state.step3;
    step3.Times[index][type] = time;
    this.setState({ step3 });
  };

  onAddTimeLine = () => {
    let step3 = this.state.step3;
    step3.Times.push({ startTime: '', endTime: '' });
    this.setState({ step3 });
  };

  onTimeLineDelete = index => {
    let step3 = this.state.step3;
    let times = [];
    step3.Times.map((item, key) => {
      if (key !== index) {
        times.push(item);
      }
    });
    step3.Times = times;
    this.setState({ step3 });
  };

  onCategoryChange = value => {
    let step3 = this.state.step3;
    step3.Category = value;
    this.setState({ step3 });
  };

  onGeoChange = value => {
    let step3 = this.state.step3;
    step3.Geo = value;
    this.setState({ step3 });
  };

  onOsChange = () => {
    let step3 = this.state.step3;
    let os = document.getElementsByClassName('oscheckbox');
    let OS = [];
    for (let i = 0; i < os.length; i++) {
      if (os[i].checked) {
        OS.push(os[i].value);
      }
    }
    if (OS.length > 0) {
      step3.OS = OS;
      this.setState({ step3 });
    }
    if (OS.length === 0) {
      step3.OS = '';
      this.setState({ step3 });
    }
  };

  onPricingChanges = (name, value) => {
    let step3 = this.state.step3;
    step3[name] = Number(value.replace(/\D/g, ''));
    this.setState({ step3 });
  };

  onVideoAd = (type, video, duration) => {
    if (type === 'flatVideo') {
      this.setState({
        flatVideo: {
          data: video,
          duration: duration,
        },
      });
    }
    if (type === 'sceneAd') {
      this.setState({
        sceneAd: {
          data: video,
          duration: duration,
        },
      });
    }
  };

  on360VideoAdd = (type, video, duration) => {
    let videos360 = this.state.videos360;
    videos360[type] = {
      data: video,
      duration: duration,
    };
    this.setState({ videos360 });
  };

  on3dObjectAdd = object => this.setState(prevState => {
    const state = {...prevState};
    state.object3d = object;
    return {...state};
  })

  onSave = async data => {
    const body = {
      adType: this.state.adType,
      globalType: ['Unity 3D'],
      categoryTargeting: this.state.step3.Category,
      geoTargeting: this.state.step3.Geo,
      osTargeting: this.state.step3.OS,
      platform: this.state.step1.platform,
      timeTargeting: this.state.step3.Time,
      title: this.state.step1.title,
      maxBid: this.state.step3.CPM,
      budget: this.state.step3.totalBudget,
      dailyBudget: this.state.step3.dailyBudget,
      adult: this.state.adult,
      gambling: this.state.gambling,
      creative: {
        urlType: this.state.urlType,
        skippable: this.state.skippable,
        url: this.state.url,
        frequencyCapping: data,
        object3d: this.state.object3d
      },
    };
    console.log('BODY =>',body);
    if (this.state.adType === 'flatVideo') {
      this.setState({ loader: true });
      let id = uuid();
      body['folderId'] = id;
      body.creative['flatVideo'] = {
        duration: this.state.flatVideo.duration,
        url: `https://adlooker-creative.s3.${
          ENV.REGION
        }.amazonaws.com/flatVideo/${id}/flatVideo.mp4`,
      };
      this.props
        .onCompainCreate(this.state.flatVideo, id, 'flatVideo', 'flatVideo.mp4')
        .then(() => {
          this.setState({ loader: false });
          this.props.addCompaings({ ...body, provider: this.props.provider });
        });
    }
    if (this.state.adType === 'sceneAd') {
      this.setState({ loader: true });
      let id = uuid();
      body['folderId'] = id;
      body.creative['video'] = {
        duration: this.state.sceneAd.duration,
        url: `https://adlooker-creative.s3.${
          ENV.REGION
        }.amazonaws.com/scenead/${id}/scenead.mp4`,
      };
      this.props
        .onCompainCreate(this.state.sceneAd, id, 'scenead', 'scenead.mp4')
        .then(() => {
          this.setState({ loader: false });
          this.props.addCompaings({ ...body, provider: this.props.provider });
        });
    }

    if(this.state.adType === '3dObject') {
      this.setState({ loader: true });
      let id = uuid();
      body['folderId'] = id;
      body.creative['object3d'] = {
        url: `https://adlooker-creative.s3.${
          ENV.REGION
        }.amazonaws.com/3dObject/${id}/3dObject.glb`,
      };
      this.props
        .onCompainCreate(this.state.object3d, id, '3dObject', '3dObject.glb')
        .then(() => {
          const mutable = {...body, provider: this.props.provider };
          mutable.adType = 'fbxAd'
          this.setState({ loader: false });
          this.props.addCompaings(mutable);
        });
    }

    if (this.state.adType === '360video') {
      let id = uuid();
      body['folderId'] = id;
      for (let key in this.state.videos360) {
        if (key === '480') {
          this.setState({ loader: true });
          body.creative['videos'] = {
            ...body.creative['videos'],
            video480: {
              duration: this.state.videos360[key].duration,
              url: `https://adlooker-creative.s3.${
                ENV.REGION
              }.amazonaws.com/videoad/${id}/video480.mp4`,
            },
          };
          await this.props.onCompainCreate(
            this.state.videos360[key],
            id,
            'videoad',
            'video480.mp4'
          );
          this.setState({ loader: false });
        }

        if (key === '1080') {
          this.setState({ loader: true });
          body.creative['videos'] = {
            ...body.creative['videos'],
            video1080: {
              duration: this.state.videos360[key].duration,
              url: `https://adlooker-creative.s3.${
                ENV.REGION
              }.amazonaws.com/videoad/${id}/video1080.mp4`,
            },
          };
          await this.props.onCompainCreate(
            this.state.videos360[key],
            id,
            'videoad',
            'video1080.mp4'
          );
          this.setState({ loader: false });
        }

        if (key === '2k') {
          this.setState({ loader: true });
          body.creative['videos'] = {
            ...body.creative['videos'],
            video2k: {
              duration: this.state.videos360[key].duration,
              url: `https://adlooker-creative.s3.${
                ENV.REGION
              }.amazonaws.com/videoad/${id}/video2k.mp4`,
            },
          };
          await this.props.onCompainCreate(
            this.state.videos360[key],
            id,
            'videoad',
            'video2k.mp4'
          );
          this.setState({ loader: false });
        }
        if (key === '4k') {
          this.setState({ loader: true });
          body.creative['videos'] = {
            ...body.creative['videos'],
            video4k: {
              duration: this.state.videos360[key].duration,
              url: `https://adlooker-creative.s3.${
                ENV.REGION
              }.amazonaws.com/videoad/${id}/video4k.mp4`,
            },
          };
          await this.props.onCompainCreate(
            this.state.videos360[key],
            id,
            'videoad',
            'video4k.mp4'
          );
          this.setState({ loader: false });
        }
      }
      await this.props.addCompaings({ ...body, provider: this.props.provider });
    }
  };

  render() {
    return (
      <div className="container-non-responsive new-campaigns">
        {this.props.addCompaignsData.pending && <Loader />}
        {this.state.loader && <Loader />}
        <div className="row">
          <div className="col-sm-9 new-campaigns-body">
            <div className="shadow-widget colored-blue">
              <ul className="nav nav-tabs">
                <li className={this.state.step === '1' ? 'active' : ''}>
                  <a>Step 1 | General</a>
                </li>
                <li className={this.state.step === '2' ? 'active' : ''}>
                  <a>Step 2 | Creative</a>
                </li>
                <li className={this.state.step === '3' ? 'active' : ''}>
                  <a>Step 3 | Targetings</a>
                </li>
              </ul>
              {this.state.step === '1' && (
                <Step1
                  onStepChange={this.onStepChange}
                  platform={this.state.step1.platform}
                  title={this.state.step1.title}
                  onStep1Changes={this.onStep1Changes}
                />
              )}
              {this.state.step === '2' && (
                <Step2
                  onStepChange={this.onStepChange}
                  platform={this.state.step1.platform}
                  urlType={this.state.urlType}
                  skippable={this.state.skippable}
                  adType={this.state.adType}
                  step2AR={this.state.step2AR}
                  onUrlTypeChange={this.onUrlTypeChange}
                  onChangeUrels={this.onChangeUrels}
                  onSkipAbleChange={this.onSkipAbleChange}
                  onChangeAdType={this.onChangeAdType}
                  onVideoAd={this.onVideoAd}
                  on360VideoAdd={this.on360VideoAdd}
                  urlValid={this.state.urlValid}
                  videos360={this.state.videos360}
                  on3dObjectAdd={this.on3dObjectAdd}
                  handleAdultChange={this.handleAdultChange}
                  handleGambleChange={this.handleGambleChange}
                  checkMarks={this.state}
                />
              )}
              {this.state.step === '3' && (
                <Step3
                  step3={this.state.step3}
                  onStepChange={this.onStepChange}
                  onAllAndCustomeChange={this.onAllAndCustomeChange}
                  onChangeTimes={this.onChangeTimes}
                  onAddTimeLine={this.onAddTimeLine}
                  onTimeLineDelete={this.onTimeLineDelete}
                  onCategoryChange={this.onCategoryChange}
                  onGeoChange={this.onGeoChange}
                  onOsChange={this.onOsChange}
                  onPricingChanges={this.onPricingChanges}
                  onSave={this.onSave}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateCompaignsComponent;
