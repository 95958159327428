export const LOGIN = {
  PENDING: "LOGIN_PENDING",
  SUCCESS: "LOGIN_SUCCESS",
  MFA_NEEDED: "LOGIN_MFA_NEEDED",
  PASSWORD_CHANGE_REQUIRED: "LOGIN_PASSWORD_CHANGE_REQUIRED",
  MFA_REQUIRED: "LOGIN_MFA_REQUIRED",
  ERROR: "LOGIN_ERROR",
  RESET: "RESET_LOGIN_REDUCER",
  LOGOUT_PENDING: "LOGOUT_PENDING",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_ERROR: "LOGOUT_ERROR"
};
export const OPTIONS_GET = {
  PENDING: "OPTIONS_GET_PENDING",
  SUCCESS: "OPTIONS_GET_SUCCESS",
  ERROR: "OPTIONS_GET_ERROR"
};
export const CHECK_EMAIL = {
  PENDING: "CHECK_EMAIL_PENDING",
  SUCCESS: "CHECK_EMAIL_SUCCESS",
  ERROR: "CHECK_EMAIL_ERROR"
};
export const PERMISSIONS_GET = {
  PENDING: "PERMISSIONS_GET_PENDING",
  SUCCESS: "PERMISSIONS_GET_SUCCESS",
  ERROR: "PERMISSIONS_GET_ERROR"
};
export const REGISTRATION = {
  PENDING: "REGISTRATION_PENDING",
  SUCCESS: "REGISTRATION_SUCCESS",
  ERROR: "REGISTRATION_ERROR"
};
export const VERIFY_EMAIL = {
  PENDING: "VERIFY_EMAIL_PENDING",
  SUCCESS: "VERIFY_EMAIL_SUCCESS",
  ERROR: "VERIFY_EMAIL_ERROR"
};
export const PROFILE_INFO = {
  PENDING: "PROFILE_INFO_PENDING",
  SUCCESS: "PROFILE_INFO_SUCCESS",
  ERROR: "PROFILE_INFO_ERROR"
};
export const GET_DASHBOARD_DATA = {
  PENDING: "GET_DASHBOARD_DATA_PENDING",
  SUCCESS: "GET_DASHBOARD_DATA_SUCCESS",
  ERROR: "GET_DASHBOARD_DATA_ERROR"
};
export const GET_ACCOUNT_DATA = {
  PENDING: "GET_ACCOUNT_DATA_PENDING",
  SUCCESS: "GET_ACCOUNT_DATA_SUCCESS",
  ERROR: "GET_ACCOUNT_DATA_ERROR"
};
export const GET_FINANCE_DATA = {
  PENDING: "GET_FINANCE_DATA_PENDING",
  SUCCESS: "GET_FINANCE_DATA_SUCCESS",
  ERROR: "GET_FINANCE_DATA_ERROR"
};
export const GET_REPORTS = {
  PENDING: "GET_REPORTS_PENDING",
  SUCCESS: "GET_REPORTS_SUCCESS",
  ERROR: "GET_REPORTS_ERROR"
};
export const GET_REPORTS_LAST = {
  PENDING: "GET_REPORTS_PENDING",
  SUCCESS: "GET_REPORTS_LAST_SUCCESS",
  ERROR: "GET_REPORTS_ERROR"
};
export const GET_COMPAIGNS = {
  PENDING: "GET_COMPAIGNS_PENDING",
  SUCCESS: "GET_COMPAIGNS_SUCCESS",
  ERROR: "GET_COMPAIGNS_ERROR"
};
export const ADD_COMPAIGNS = {
  PENDING: "ADD_COMPAIGNS_PENDING",
  SUCCESS: "ADD_COMPAIGNS_SUCCESS",
  ERROR: "ADD_COMPAIGNS_ERROR"
};
export const DELETE_COMPAIGNS = {
  PENDING: "DELETE_COMPAIGNS_PENDING",
  SUCCESS: "DELETE_COMPAIGNS_SUCCESS",
  ERROR: "DELETE_COMPAIGNS_ERROR"
};
export const CHANGE_STATUS_COMPAIGNS = {
  PENDING: "CHANGE_STATUS_COMPAIGNS_PENDING",
  SUCCESS: "CHANGE_STATUS_COMPAIGNS_SUCCESS",
  ERROR: "CHANGE_STATUS_COMPAIGNS_ERROR"
};
export const GET_APPS = {
  PENDING: "GET_APPS_PENDING",
  SUCCESS: "GET_APPS_SUCCESS",
  ERROR: "GET_APPS_ERROR"
};
export const ADD_APPS = {
  PENDING: "ADD_APPS_PENDING",
  SUCCESS: "ADD_APPS_SUCCESS",
  ERROR: "ADD_APPS_ERROR"
};

export const EDIT_APPS = {
  PENDING: "EDIT_APPS_PENDING",
  SUCCESS: "EDIT_APPS_SUCCESS",
  ERROR: "EDIT_APPS_ERROR"
};
export const DELETE_APPS = {
  PENDING: "DELETE_APPS_PENDING",
  SUCCESS: "DELETE_APPS_SUCCESS",
  ERROR: "DELETE_APPS_ERROR"
};

export const GET_ADSPACES = {
  PENDING: "GET_ADSPACES_PENDING",
  SUCCESS: "GET_ADSPACES_SUCCESS",
  ERROR: "GET_ADSPACES_ERROR"
};
export const DELETE_ADSPACES = {
  PENDING: "DELETE_ADSPACES_PENDING",
  SUCCESS: "DELETE_ADSPACES_SUCCESS",
  ERROR: "DELETE_ADSPACES_ERROR"
};
export const GET_REFERRALS = {
  PENDING: "GET_REFERRALS_PENDING",
  SUCCESS: "GET_REFERRALS_SUCCESS",
  ERROR: "GET_REFERRALS_ERROR"
};
export const GET_REFERRALS_LAST = {
  PENDING: "GET_REFERRALS_PENDING",
  SUCCESS: "GET_REFERRALS_LAST_SUCCESS",
  ERROR: "GET_REFERRALS_ERROR"
};
export const GET_BILLING_INFO = {
  PENDING: "GET_BILLING_INFO_PENDING",
  SUCCESS: "GET_BILLING_INFO_SUCCESS",
  ERROR: "GET_BILLING_INFO_ERROR"
};
export const GET_PAYMENTS = {
  PENDING: "GET_PAYMENTS_PENDING",
  SUCCESS: "GET_PAYMENTS_SUCCESS",
  ERROR: "GET_PAYMENTS_ERROR"
};
export const STRIPE_PAYMENT = {
  PENDING: "STRIPE_PAYMENT_PENDING",
  SUCCESS: "STRIPE_PAYMENT_SUCCESS",
  ERROR: "STRIPE_PAYMENT_ERROR"
};
export const GET_RATES = {
  PENDING: "GET_RATES_PENDING",
  SUCCESS: "GET_RATES_SUCCESS",
  ERROR: "GET_RATES_ERROR"
};
export const COIN_PAYMENT = {
  PENDING: "COIN_PAYMENT_PENDING",
  SUCCESS: "COIN_PAYMENT_SUCCESS",
  ERROR: "COIN_PAYMENT_ERROR"
};
export const COIN_WITHDRAW = {
  PENDING: "COIN_WITHDRAW_PENDING",
  SUCCESS: "COIN_WITHDRAW_SUCCESS",
  ERROR: "COIN_WITHDRAW_ERROR"
};
export const EDIT_CAMPAIGN_BY_ID = {
  PENDING: "EDIT_CAMPAIGN_BY_ID_PENDING",
  SUCCESS: "EDIT_CAMPAIGN_BY_ID_SUCCESS",
  ERROR: "EDIT_CAMPAIGN_BY_ID_ERROR",
  RESET: "RESET_EDIT_CAMPAIGN_BY_ID_REDUCER"
};
export const RECOVER_PASSWORD = {
  PENDING: "RECOVER_PASSWORD_PENDING",
  SUCCESS: "RECOVER_PASSWORD_SUCCESS",
  ERROR: "RECOVER_PASSWORD_ERROR",
  RESET: "RECOVER_PASSWORD_REDUCER"
};
export const SUBMITTING_RECOVERED_PASSWORD = {
  PENDING: "SUBMITTING_RECOVERED_PASSWORD_PENDING",
  SUCCESS: "SUBMITTING_RECOVERED_PASSWORD_SUCCESS",
  ERROR: "SUBMITTING_RECOVERED_PASSWORD_ERROR"
};
export const GET_USER_INFO = {
  PENDING: "GET_USER_INFO_PENDING",
  SUCCESS: "GET_USER_INFO_SUCCESS",
  ERROR: "GET_USER_INFO_ERROR"
};
export const SECOND_STEP_SUBMIT = {
  PENDING: "SECOND_STEP_SUBMIT_PENDING",
  SUCCESS: "SECOND_STEP_SUBMIT_SUCCESS",
  ERROR: "SECOND_STEP_SUBMIT_ERROR"
};

export const RESET_CAMPAIGNS_DATA_REDUCER = "RESET_CAMPAIGNS_DATA_REDUCER";
export const RESET_REGISTRATION = "RESET_REGISTRATION";
export const RESET_LOGIN_ERRORS = "RESET_LOGIN_ERRORS";
export const RESET_REPORTS_REDUCER = "RESET_REPORTS_REDUCER";
