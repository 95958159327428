import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import LoginComponent from "../../components/Auth/LoginComponent/LoginComponent";

import {
  LoginAction,
  LoginPasswordChangeAction,
  LoginMFAAction,
  resetRegistration,
  onVerifyEmail,
  resetLoginErrors
} from "../../store/actions/LoginAction";
import Loader from "../../shared/Loader";
import "./LoginContainer.scss";

const LoginContainer = ({
  error,
  login,
  dashboardReducer,
  dashboardReducer: {
    optionsData: { displayName }
  },
  loading,
  registration,
  resetReducer,
  resetErrors,
  firstTime,
  user
}) => {
  const handleSubmit = data => {
    login({ ...data });
    resetReducer();
  };

  useEffect(() => {
    return () => {
      resetErrors();
    };
  }, [firstTime, resetErrors]);

  return (
    <Fragment>
      {user && !firstTime && <Redirect to="/control/advertiser/dashboard" />}
      {user && firstTime && <Redirect to="/registration-second" />}
      {dashboardReducer.optionsPending && <Loader />}
      <Helmet>
        <title>Login | {displayName} Control Panel</title>
      </Helmet>
      <LoginComponent
        dashboardReducer={dashboardReducer}
        handleSubmit={handleSubmit}
        loading={loading}
        error={error}
        registration={registration}
        resetRegistration={resetRegistration}
      />
    </Fragment>
  );
};

const mapStateFromProps = store => ({
  needPasswordChange: store.loginReducer.needPasswordChange,
  needMFA: store.loginReducer.needMFA,
  success: store.loginReducer.success,
  error: store.loginReducer.error,
  user: store.loginReducer.user,
  dashboardReducer: store.DashboardReducer,
  loading: store.loginReducer.pending,
  registration: store.RegistrationReducer,
  firstTime: store.loginReducer.firstTime
});

const mapDispatchFromProps = dispatch => ({
  login: data => dispatch(LoginAction(data)),
  loginPasswordChange: data => dispatch(LoginPasswordChangeAction(data)),
  loginMFA: data => dispatch(LoginMFAAction(data)),
  resetReducer: () => dispatch(resetRegistration()),
  resetErrors: () => dispatch(resetLoginErrors())
});

export default connect(
  mapStateFromProps,
  mapDispatchFromProps
)(LoginContainer);
