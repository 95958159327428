import React, { Component } from 'react';
import './FAQComponent.scss'

class FAQComponent extends Component {
    render() {
        return (
            <div className="container-non-responsive">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="shadow-widget">
                            <div className="panel-group" id="accordion">
                                <div className="card panel panel-default">
                                    <a className="btn collapsed panel-heading" data-toggle="collapse" data-target="#Question1">
                                        <div className="card-header">
                                            <h5 class="panel-title">Can I control what type of ads are published on my websites?</h5>
                                        </div>
                                    </a>
                                    <div id="Question1" className="collapse" data-parent="#accordion">
                                        <div className="card-body">You can control the type of ads published on your websites by selecting the type of ad space your are adding, for example "General, Automotive, Computers, Games..." But you can be sure that no gambling, adult and warez ads will be published on your ad spaces.</div>
                                    </div>
                                </div>
                                <div className="card panel panel-default">
                                    <a className="btn collapsed panel-heading" data-toggle="collapse" data-target="#Question2">
                                        <div className="card-header">
                                            <h5 class="panel-title">The approval of your websites or apps can take up to 72 hours.</h5>
                                        </div>
                                    </a>
                                    <div id="Question2" className="collapse" data-parent="#accordion">
                                        <div className="card-body">You can control the type of ads published on your websites by selecting the type of ad space your are adding, for example "General, Automotive, Computers, Games..." But you can be sure that no gambling, adult and warez ads will be published on your ad spaces.</div>
                                    </div>
                                </div>
                                <div className="card panel panel-default">
                                    <a className="btn collapsed panel-heading" data-toggle="collapse" data-target="#Question3">
                                        <div className="card-header">
                                            <h5 class="panel-title">How many ad spaces am I allowed to monetize?</h5>
                                        </div>
                                    </a>
                                    <div id="Question3" className="collapse" data-parent="#accordion">
                                        <div className="card-body">There is no limit, you can monetize as many ad spaces or apps as you want to.</div>
                                    </div>
                                </div>
                                <div className="card panel panel-default">
                                    <a className="btn collapsed panel-heading" data-toggle="collapse" data-target="#Question4">
                                        <div className="card-header">
                                            <h5 class="panel-title">How much traffic on my website is required that I can work with you?</h5>
                                        </div>
                                    </a>
                                    <div id="Question4" className="collapse" data-parent="#accordion">
                                        <div className="card-body">There is no minimum traffic required to start working with us.</div>
                                    </div>
                                </div>
                                <div className="card panel panel-default">
                                    <a className="btn collapsed panel-heading" data-toggle="collapse" data-target="#Question5">
                                        <div className="card-header">
                                            <h5 class="panel-title">How much will I earn with ADLOOKER?</h5>
                                        </div>
                                    </a>
                                    <div id="Question5" className="collapse" data-parent="#accordion">
                                        <div className="card-body">We have a dynamic bid system, this is why it's difficult to say how much you can earn finally. It also depends on so many factors, the traffic, the type of websites, etc... I would like to invite you to have a try, you will quickly see that working with us is worth it.</div>
                                    </div>
                                </div>
                                <div className="card panel panel-default">
                                    <a className="btn collapsed panel-heading" data-toggle="collapse" data-target="#Question6">
                                        <div className="card-header">
                                            <h5 class="panel-title">I don't have my login details anymore, how can I login again?</h5>
                                        </div>
                                    </a>
                                    <div id="Question6" className="collapse" data-parent="#accordion">
                                        <div className="card-body">You can reinitialize your password by going on „Sign in“ and then clicking on „Forgot password“. Then, you will receive a link per email to follow where you can reinitialize your password.</div>
                                    </div>
                                </div>
                                <div className="card panel panel-default">
                                    <a className="btn collapsed panel-heading" data-toggle="collapse" data-target="#Question7">
                                        <div className="card-header">
                                            <h5 class="panel-title">My address has changed, how can I change it?</h5>
                                        </div>
                                    </a>
                                    <div id="Question7" className="collapse" data-parent="#accordion">
                                        <div className="card-body">To modify your address just login to your account and then click right on „My Profile“. You can then edit your account and modify it.</div>
                                    </div>
                                </div>
                                <div className="card panel panel-default">
                                    <a className="btn collapsed panel-heading" data-toggle="collapse" data-target="#Question8">
                                        <div className="card-header">
                                            <h5 class="panel-title">What are the payment methods available?</h5>
                                        </div>
                                    </a>
                                    <div id="Question8" className="collapse" data-parent="#accordion">
                                        <div className="card-body">The following payment methods are available on ADLOOKER: - Paypal - Wire Transfer</div>
                                    </div>
                                </div>
                                <div className="card panel panel-default">
                                    <a className="btn collapsed panel-heading" data-toggle="collapse" data-target="#Question9">
                                        <div className="card-header">
                                            <h5 class="panel-title">What are the requirements for becoming a new publisher?</h5>
                                        </div>
                                    </a>
                                    <div id="Question9" className="collapse" data-parent="#accordion">
                                        <div className="card-body">There is no special requirement to join ADLOOKER as a publisher but gambling, adult and warez websites are not allowed.</div>
                                    </div>
                                </div>
                                <div className="card panel panel-default">
                                    <a className="btn collapsed panel-heading" data-toggle="collapse" data-target="#Question10">
                                        <div className="card-header">
                                            <h5 class="panel-title">What is your minimum payout?</h5>
                                        </div>
                                    </a>
                                    <div id="Question10" className="collapse" data-parent="#accordion">
                                        <div className="card-body">If you choose PayPal withdrawal method minimum payout is 100$ and if you choose wire transfer the payout is 500$.</div>
                                    </div>
                                </div>
                                <div className="card panel panel-default">
                                    <a className="btn collapsed panel-heading" data-toggle="collapse" data-target="#Question11">
                                        <div className="card-header">
                                            <h5 class="panel-title">When do I get paid?</h5>
                                        </div>
                                    </a>
                                    <div id="Question11" className="collapse" data-parent="#accordion">
                                        <div className="card-body">We have a total time of 14 days payout. It takes 7 days to approve your revenue and 7 more days until the money arrives on your bank or paypal account.</div>
                                    </div>
                                </div>
                                <div className="card panel panel-default">
                                    <a className="btn collapsed panel-heading" data-toggle="collapse" data-target="#Question12">
                                        <div className="card-header">
                                            <h5 class="panel-title">Who is ADLOOKER, what do we do?</h5>
                                        </div>
                                    </a>
                                    <div id="Question12" className="collapse" data-parent="#accordion">
                                        <div className="card-body">ADLOOKER brings publisher and advertiser to the same table. Our platform connects you with reliable,trustworthy advertisers and helps you to turn your unused advertising space into cash. You choose when to start using your free space for advertising, and you choose when to stop. Inappropriate content can be immediately removed from your site and blocked for the future, protecting your brand and reputation.</div>
                                    </div>
                                </div>
                                <div className="card panel panel-default">
                                    <a className="btn collapsed panel-heading" data-toggle="collapse" data-target="#Question13">
                                        <div className="card-header">
                                            <h5 class="panel-title">Do you offer a referral program?</h5>
                                        </div>
                                    </a>
                                    <div id="Question13" className="collapse" data-parent="#accordion">
                                        <div className="card-body">Yes, if you refer a publisher to us, you will get 3% of his earnings. If you refer an advertiser, you will get 5% of his spendings.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FAQComponent;
