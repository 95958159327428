import React, { Component } from 'react';
import CoinSucces from './CoinSucces/CoinSucces';

class CoinPayments extends Component {
    state = {
        coin: ""
    }
    componentWillMount() {
        this.props.getRates()
    }
    loader = () => {
        return <div className="loaderContainer">
            <div className="cssload-loader" style={{ top: "105px" }}>
                <div className="cssload-side"></div>
                <div className="cssload-side"></div>
                <div className="cssload-side"></div>
                <div className="cssload-side"></div>
                <div className="cssload-side"></div>
                <div className="cssload-side"></div>
                <div className="cssload-side"></div>
                <div className="cssload-side"></div>
            </div>
        </div>
    }
    renderCoinList = () => {
        let options = []
        for (let key in this.props.RatesData.data) {
            options.push(<option value={this.props.RatesData.data[key].cryptoCode} key={key}>{this.props.RatesData.data[key].name}</option>)
        }
        return options
    }
    onChooseCoin = (coin) => {
        this.setState({ coin: coin })
    }
    onAmountRender = () => {
        let amount = 0;
        for (let key in this.props.RatesData.data) {
            if (this.state.coin === this.props.RatesData.data[key].cryptoCode) {
                amount = this.props.amount / this.props.RatesData.data[key].rate_usd
            }
        }
        return <h5 style={{ marginTop: "10px" }}>Amount: <span style={{ fontSize: "16px" }}>{amount.toFixed(8)} {this.state.coin}</span></h5>
    }
    onPay = () => {
        let data = {
            type: "coinpayment",
            amount: this.props.amount,
            currency1: "USD",
            currency2: this.state.coin
        }
        this.props.coinPay(data)
    }
    render() {
        return (
            <React.Fragment>
                {this.props.CoinData.fetched && <CoinSucces CoinData={this.props.CoinData} amount={this.props.amount} coin={this.state.coin}/>}
                <div className="coinPaymentsContainer">
                    <h5>Payment Details</h5>
                    {this.props.RatesData.pending && this.loader()}
                    {this.props.CoinData.pending && this.loader()}
                    {!this.props.CoinData.pending && this.props.RatesData.fetched && <div className="coinPaymentPart">
                        <div className="form-group">
                            <small>Choose Coin</small>
                            <select className="form-control" disabled={this.props.amount <= 0} onChange={(e) => this.onChooseCoin(e.target.value)}>
                                <option value="">---select---</option>
                                {this.renderCoinList()}
                            </select>
                            {this.state.coin && this.onAmountRender()}
                        </div>
                        <button
                            style={{
                                marginTop: "10px",
                                width: "100%",
                                padding: "7px",
                                background: "#0095ff",
                                borderRadius: "5px",
                                border: "none",
                                color: "white",
                                fontWeight: "bold",
                                fontSize: "20px"
                            }}
                            disabled={this.props.amount < this.props.paymentGateways[0].min|| this.state.coin === "" || this.props.amount > this.props.paymentGateways[0].max }
                            onClick={this.onPay}
                        >Pay </button>
                    </div>}
                </div>
            </React.Fragment>
        );
    }
}

export default CoinPayments;
