import React, { Component } from 'react';
import {
    CardNumberElement,
    CardExpiryElement,
    CardCVCElement,
    injectStripe,
} from 'react-stripe-elements';

const handleBlur = () => {
    console.log('[blur]');
};
const handleChange = (change) => {
    console.log('[change]');
};
const handleClick = () => {
    console.log('[click]');
};
const handleFocus = () => {
    console.log('[focus]');
};
const handleReady = () => {
    console.log('[ready]');
};

class StripeForm extends Component {
    constructor(props) {
        super(props);
    }

    handleSubmit = (ev) => {
        ev.preventDefault();
        // this.props.onLoader()
        this.props.onLoading(true)
        if (this.props.stripe) {
            this.props.stripe
                .createToken()
                .then((payload) => {
                    this.props.onLoading(false)
                    this.props.stripePay({
                        token: payload.token,
                        amount: this.props.amount*100
                    })
                });
        } else {
            console.log("Stripe.js hasn't loaded yet.");
        }
    };
    render() {
        return (
            <div className="stripeFormContainer">
                <form onSubmit={this.handleSubmit}>
                    <div className="form-group">
                        <small>Card number</small>
                        <CardNumberElement
                            onBlur={handleBlur}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            onReady={handleReady}
                        />
                    </div>
                    <div className="cvsContainer">
                        <div className="form-group" style={{width: "65%"}}>
                            <small>Expiration date</small>
                            <CardExpiryElement
                                onBlur={handleBlur}
                                onChange={handleChange}
                                onFocus={handleFocus}
                                onReady={handleReady}
                            />
                        </div>
                        <div className="form-group" style={{width: "30%"}}>
                            <small>CVC</small>
                            <CardCVCElement
                                onBlur={handleBlur}
                                onChange={handleChange}
                                onFocus={handleFocus}
                                onReady={handleReady}
                            />
                        </div>
                    </div>
                    {/* {this.props.loader ? 
            <Spinner style={{ height: '30px', marginTop: "15px" }}/>
          : */}
                    <button
                        style={{
                            marginTop: "15px",
                            width: "100%",
                            padding: "7px",
                            background: "#0095ff",
                            borderRadius: "5px",
                            border: "none",
                            color: "white",
                            fontWeight: "bold",
                            fontSize: "20px"
                        }}
                        disabled={this.props.amount<this.props.paymentGateways[2].min || this.props.amount>this.props.paymentGateways[2].max}
                    >Pay </button>
                    {/* } */}
                </form>
            </div>
        );
    }
}

export default injectStripe(StripeForm);