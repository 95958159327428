import React, { Component } from 'react';
import { Link } from "react-router-dom";
import './LoadMoneyComponent.scss';
import Stripe from './Stripe/Stripe';
import CoinPayments from './CoinPayments/CoinPayments';
import { PayPalButton } from 'react-paypal-button'
import { ENV } from '../../environment';

class LoadMoneyComponent extends Component {
    state = {
        step: this.props.PaymentData.data[0].type,
        amount: 0
    }
    onStepChange = (step) => {
        this.setState({ step: step })
    }
    onChangeAmount = (amount) => {
        let money = amount.replace(/\D/g, '')
        this.setState({
            amount: Number(money)
        })
    }
    renderTabs = () => {
        let tabs = []
        this.props.PaymentData.data.map((item, key) => {
            if (item.type === "coinPayments" && item.status === "active") {
                tabs.push(
                    <div className="coinPayments" key={key} onClick={() => this.onStepChange("coinPayments")}>
                        <div className="coinPaymentsHeader">
                            <div className="Bitcoin"></div>
                            <div className="BlackCoin"></div>
                            <div className="Dash"></div>
                            <div className="Ether"></div>
                            <div className="Namecoin"></div>
                            <div className="LitecoinTestnet"></div>
                        </div>
                        <div style={{ textAlign: "center", fontWeight: "bold" }}>Crypto Payment</div>
                    </div>
                )
            }
            if (item.type === "paypal" && item.status === "active") {
                tabs.push(
                    <div className="paypal" key={key} onClick={() => this.onStepChange("paypal")}>
                        <div className="paypalHeader"></div>
                        <div style={{ textAlign: "center", fontWeight: "bold" }}>PayPal</div>
                    </div>
                )
            }
            if (item.type === "stripe" && item.status === "active") {
                tabs.push(
                    <div className="stripe" key={key} onClick={() => this.onStepChange("stripe")}>
                        <div className="stripeHeader"></div>
                        <div style={{ textAlign: "center", fontWeight: "bold" }}>Credit Card</div>
                    </div>
                )
            }
        })
        return tabs
    }
    render() {
        console.log(process.env.NODE_ENV)
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-8">
                        <div className="shadow-widget">
                            <div className="row">
                                <div className="col-md-7" style={{borderRight: "1px solid #eaedef"}}>
                                    <h5 style={{ paddingTop: "20px" }}>Amount</h5>
                                    <div className="amountPart">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={this.state.amount}
                                                onChange={(e) => this.onChangeAmount(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <h5>Payment Type</h5>
                                    <div className="paymentPart">
                                        {this.renderTabs()}
                                    </div>
                                    {this.state.step === "stripe" &&
                                        <Stripe
                                            amount={this.state.amount}
                                            stripePay={this.props.stripePay}
                                            onLoading={this.props.onLoading}
                                            paymentGateways={this.props.optionsData.paymentGateways}
                                        />
                                    }
                                    {
                                        this.state.step === "coinPayments" &&
                                        <CoinPayments
                                            getRates={this.props.getRates}
                                            RatesData={this.props.RatesData}
                                            amount={this.state.amount}
                                            coinPay={this.props.coinPay}
                                            CoinData={this.props.CoinData}
                                            paymentGateways={this.props.optionsData.paymentGateways}
                                        />
                                    }
                                    {
                                        this.state.step === "paypal" && this.state.amount >= this.props.optionsData.paymentGateways[1].min && this.state.amount <= this.props.optionsData.paymentGateways[1].max &&
                                        <div className="coinPaymentsContainer">
                                            <h5>Payment Details</h5>
                                            {process.env.NODE_ENV === "development" ? <PayPalButton
                                                env='sandbox'
                                                sandboxID="AYQRFh0wZXPG8_-n7FDQHLVz6FvvGNTzBemJ6ycUIOGEBK01ZZw1lAQlLn2Bq_eMraSrtezwhdxuTR1s"
                                                amount={this.state.amount}
                                                currency='USD'
                                                commit={true}
                                            /> :
                                                <PayPalButton
                                                    env='production'
                                                    productionID={this.props.optionsData.paymentGateways[1].clientId}
                                                    amount={this.state.amount}
                                                    currency='USD'
                                                    commit={true}
                                                />
                                            }
                                        </div>
                                    }
                                    <div className="footer-buttons">
                                        <Link className="btn btn-default" to="/control/billing">Cancel</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default LoadMoneyComponent;
