import React, { Fragment, useEffect, useState, useCallback } from "react";
import "numeral/locales/pt-br";
import { Table as STable, Header } from "semantic-ui-react";
import _ from "lodash";
import Loader from "../Loader";
import RenderRows from "./RenderRows";
import "./index.scss";

const Table = React.memo(
  ({
    pending,
    action,
    cells,
    infinite = false,
    lastEvaluatedKey,
    getItems,
    data,
    reportsData,
    changeStatus,
    compaignsStatus,
    deleteCompaignsData,
    isLoading,
    type = "campaigns",
    provider
  }) => {
    const [items, setItems] = useState([]);

    const handleScroll = useCallback(() => {
      const scroller = Array.from(
        document.getElementsByClassName("main-content")
      )[0];
      if (
        scroller.scrollTop + scroller.offsetHeight === scroller.scrollHeight &&
        lastEvaluatedKey
      ) {
        getItems({
          LastEvaluatedKey: { ...lastEvaluatedKey },
          provider
        });
      }
    }, [getItems, lastEvaluatedKey]);

    /**
     * @param useEffect ==> extracting fetched data from store
     */
    useEffect(() => {
      if (data && !isLoading) {
        setItems([...data]);
      }
    }, [data, isLoading]);

    useEffect(() => {
      if (data) {
        const scroller = document.getElementsByClassName("main-content")[0];
        if (infinite) {
          scroller.addEventListener("scroll", handleScroll);
        }
        return () => scroller.removeEventListener("scroll", handleScroll);
      }
      return () => {};
    }, [data, handleScroll, infinite]);

    useEffect(() => {
      if (type === "campaigns") {
        if (
          data &&
          data.data &&
          !compaignsStatus.pending &&
          !compaignsStatus.fetchedItem &&
          !deleteCompaignsData.fetched
        ) {
          setItems(prevState => [...prevState, ...data.data]);
        }
      }
    }, [compaignsStatus, data, deleteCompaignsData, type]);

    useEffect(() => {
      if (
        data &&
        data.data &&
        !compaignsStatus.pending &&
        !compaignsStatus.fetchedItem &&
        deleteCompaignsData.fetched &&
        deleteCompaignsData.id
      ) {
        setItems(prevState => {
          const state = [...prevState].filter(
            it => it.id !== deleteCompaignsData.id
          );
          return [...state];
        });
      }
    }, [compaignsStatus, data, deleteCompaignsData]);

    useEffect(() => {
      if (reportsData && reportsData.data) {
        setItems(prevState => [...prevState, ...reportsData.data]);
      }
    }, [reportsData]);

    useEffect(() => {
      if (
        compaignsStatus &&
        compaignsStatus.fetched &&
        compaignsStatus.fetchedItem
      ) {
        setItems(prevState => {
          const mutable = prevState.map(it => {
            if (it.id === compaignsStatus.fetchedItem.id) {
              const item = { ...compaignsStatus.fetchedItem };
              return item;
            }
            return it;
          });
          return mutable;
        });
      }
    }, [compaignsStatus]);

    return (
      <Fragment>
        <STable columns={cells.length} celled style={{ border: "none" }}>
          <STable.Header>
            <STable.Row>
              {cells &&
                _.map(cells, (value, key) => {
                  return (
                    <STable.HeaderCell
                      key={
                        type === "campaigns"
                          ? value.name + key
                          : value.title + key
                      }
                      className="text-center"
                    >
                      <Header as="h3">
                        {type === "campaigns" ? value.name : value.title}
                      </Header>
                    </STable.HeaderCell>
                  );
                })}
            </STable.Row>
          </STable.Header>
          <STable.Body>
            {items &&
              _.map(items, (dataValues, key) => {
                return (
                  <STable.Row key={key}>
                    <RenderRows
                      data={dataValues}
                      cells={cells}
                      action={action}
                      id={dataValues.id}
                      changeStatus={changeStatus}
                      provider={provider}
                    />
                  </STable.Row>
                );
              })}
          </STable.Body>
        </STable>
        {pending && <Loader inline />}
      </Fragment>
    );
  }
);

export default Table;
