import React, { Component } from 'react';
import './Table.scss';
import Loader from 'react-loader-spinner'
import { Link } from "react-router-dom";
class Table extends Component {
    renderTableBody = () => {
        let body = []
        this.props.adSpacesData.data.map((item, key) => {
            return body.push(
                <div className="my-table-row" key={key}>
                    <div className="my-table-column  border-bottom">{item.name}</div>
                    <div className="my-table-column  border-bottom">{item.type}</div>
                    <div className="my-table-column  border-bottom">
                        <i className="fa fa-code" onClick={this.props.onOpenSdk}></i>
                        <Link to={`/control/publisher/apps/adspaces/edit/${this.props.id}?id=${item.adspace_id}`} ><i className="fa fa-pencil-square-o" style={{marginRight: "10px", marginLeft: "10px"}}></i></Link>
                        <i className="fa fa-trash-o" onClick={()=>this.props.onDeleteModal(item.adspace_id)}></i>
                    </div>
                </div>)
        })
        return body
    }
    onScrollTop = () => {
        let div = document.getElementById("scroledDiv");
        let bottom = div.scrollHeight - div.scrollTop
        if (bottom === 500 && this.props.adSpacesData.LastEvaluatedKey) {
            
        }

    }
    render() {
        return (
            <React.Fragment>
                {<div className="reports shadow-widget table-widget">
                    <div className="my-table">
                        <div className="my-table-header">
                            <div className="my-table-row border-bottom">
                                <div className="my-table-column">Name</div>
                                <div className="my-table-column">Type</div>
                                <div className="my-table-column">Actions</div>
                            </div>
                        </div>
                        <div className="my-table-body" id="scroledDiv" onScroll={() => this.onScrollTop()}>
                            {this.props.adSpacesData.data && this.renderTableBody()}
                        </div>
                        {this.props.adSpacesData.pending && <div style={{ textAlign: "center", margin: '10px' }}>
                            <Loader
                                type="Bars"
                                color="#00BFFF"
                                height="50"
                                width="30"
                            />
                        </div>
                        }
                    </div>
                </div>}
            </React.Fragment>
        );
    }
}

export default Table;
