import React, { Component } from 'react';
import { Elements, StripeProvider } from 'react-stripe-elements';
import  StripeForm from './StripeForm/StripeForm'

const STRIPE_PUBLIC_KEY = 'pk_test_280BBCuiGnV8LOJilywnLtRo00It0ieAyY'

class Stripe extends Component {
    render() {
        return (
            <div className="stripeForm">
                <h5>Payment Details</h5>
                <StripeProvider apiKey={STRIPE_PUBLIC_KEY}>
                    <Elements>
                        <StripeForm
                            amount={this.props.amount}
                            stripePay={this.props.stripePay}
                            onLoading={this.props.onLoading}
                            paymentGateways={this.props.paymentGateways}
                        />
                    </Elements>
                </StripeProvider>
            </div>
        );
    }
}

export default Stripe;
