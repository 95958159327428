import React from "react";
import {
  Icon,
  Button,
  Header,
  Modal,
} from "semantic-ui-react";


const DeleteModal = ({ open, toggle, handleDelete, id }) => {
  return (
    <Modal
      size="tiny"
      basic
      open={open}
      onClose={() => toggle()}
      style={{ position: "relative", height: "auto" }}
    >
      <Header icon="archive" content="Delete App" />
      <Modal.Content>
        <span>Are you sure you want to delete?</span>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="green"
          inverted
          onClick={() => {
            handleDelete(id);
            toggle();
          }}
        >
          <Icon name="checkmark" /> Yes
        </Button>
        <Button color="red" inverted onClick={() => toggle()}>
          <Icon name="remove" /> No
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default DeleteModal;
