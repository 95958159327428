import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  onRegistration,
  CheckEmail,
  resetRegistration
} from "../../store/actions/LoginAction";
import "../LoginContainer/LoginContainer.scss";
import RegistrationComponent from "../../components/Auth/RegistrationComponent/RegistrationComponent";

const RegistrationContainer = ({
  dashboardReducer,
  dashboardReducer: {
    optionsData: { displayName }
  },
  checkEmailData,
  registration,
  registration: { redirect },
  checkEmail,
  handleSubmit,
  resetReducer
}) => {
  return (
    <Fragment>
      {redirect ? (
        <Redirect to="/" />
      ) : (
        <Fragment>
          <Helmet>
            <title>Registration | {displayName} Control Panel</title>
          </Helmet>
          <RegistrationComponent
            dashboardReducer={dashboardReducer}
            registration={registration}
            handleSubmit={handleSubmit}
            checkEmail={checkEmail}
            checkEmailData={checkEmailData}
            resetReducer={resetReducer}
          />
        </Fragment>
      )}
    </Fragment>
  );
};

const mapStateFromProps = store => ({
  dashboardReducer: store.DashboardReducer,
  checkEmailData: store.loginReducer.checkEmailData,
  registration: store.RegistrationReducer
});

const mapDispatchFromProps = dispatch => ({
  checkEmail: email => dispatch(CheckEmail(email)),
  handleSubmit: body => dispatch(onRegistration(body)),
  resetReducer: () => dispatch(resetRegistration())
});

export default connect(
  mapStateFromProps,
  mapDispatchFromProps
)(RegistrationContainer);
