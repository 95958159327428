import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import "./NavbarComponent.scss";

class NavbarComponent extends Component {
  state = {
    // aside-navbar-active
  };

  render() {
    return (
      <nav className="main-navigation">
        <div className="inner-panel">
          {(localStorage.customerType === "advertiser" ||
            localStorage.customerType === "both") && (
            <React.Fragment>
              <span className="nav-seperator">Advertiser</span>
              <ul>
                <li>
                  <NavLink
                    exact
                    activeClassName="aside-navbar-active"
                    to="/control/advertiser/dashboard"
                  >
                    <i className="fa fa-home fa-lg" /> Dashboard
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    activeClassName="aside-navbar-active"
                    to="/control/advertiser/campaigns"
                  >
                    <i className="fa fa-building" /> Campaigns
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    activeClassName="aside-navbar-active"
                    to="/control/advertiser/statistics"
                  >
                    <i className="fa fa-line-chart" /> Reports
                  </NavLink>
                </li>
              </ul>
            </React.Fragment>
          )}
          {(localStorage.customerType === "publisher" ||
            localStorage.customerType === "both") && (
            <React.Fragment>
              <span className="nav-seperator">Publisher</span>
              <ul>
                <li>
                  <NavLink
                    exact
                    activeClassName="aside-navbar-active"
                    to="/control/publisher/dashboard"
                  >
                    <i className="fa fa-home fa-lg" /> Dashboard
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    activeClassName="aside-navbar-active"
                    to="/control/publisher/apps"
                  >
                    <i className="fa fa-laptop fa-lg" /> App / Website
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    activeClassName="aside-navbar-active"
                    to="/control/publisher/statistics"
                  >
                    <i className="fa fa-line-chart" /> Reports
                  </NavLink>
                </li>
              </ul>
            </React.Fragment>
          )}
          <span className="nav-seperator">Administrative</span>
          <ul>
            <li>
              <NavLink
                activeClassName="aside-navbar-active"
                to="/control/referrals"
              >
                <i className="fa fa-random" /> Referral
              </NavLink>
            </li>
            <li>
              <NavLink
                activeClassName="aside-navbar-active"
                to="/control/billing"
              >
                <i className="fa fa-credit-card" /> Billing
              </NavLink>
            </li>
            <li>
              <NavLink activeClassName="aside-navbar-active" to="/control/faq">
                <i className="fa fa-question" /> FAQ
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}

const mapStateFromProps = store => ({});

const mapDispatchFromProps = dispatch => ({});

export default connect(
  mapStateFromProps,
  mapDispatchFromProps
)(NavbarComponent);
