import React from "react";
import "numeral/locales/pt-br";
import { Header, Label } from "semantic-ui-react";
import moment from "moment";
import Numeral from "numeral";
import "./index.scss";

const RenderCell = ({ data, value }) => {
  const numberFormatting = number => {
    const num = number
      .toString()
      .split("")
      .filter(it => it !== "-")
      .join("");
    const formatted = Numeral(num).format("$0,0.00");
    return `${formatted}`;
  };

  const formatting = (number, type) => {
    const num = number
      .toString()
      .split("")
      .filter(it => it !== "-")
      .join("");
    if (type === "cpm") {
      const formatted = Numeral(num).format("0,0.00");
      return `${formatted}`;
    }
    if (type === "ctr") {
      const formatted = Numeral(num).format("%0,0.00");
      return `${formatted}`;
    }
    return null;
  };

  if (data[value.key] === "flatVideo") {
    return (
      <Header as="h4" style={{ fontWeight: "normal" }}>
        Flat Video
      </Header>
    );
  }

  if (value.name === "Campaign Title") {
    return (
      <Header as="h4" color="blue" style={{ fontWeight: "normal" }}>
        {data[value.key]}
      </Header>
    );
  }

  if (value.key === "status") {
    return (
      <Label
        color={
          data[value.key] === "pending"
            ? "yellow"
            : data[value.key] === "declined"
            ? "red"
            : data[value.key] === "paused"
            ? "grey"
            : "green"
        }
        size="large"
        className="w-50"
        as="h4"
        style={{ fontWeight: "normal" }}
      >
        {data[value.key].toUpperCase()}
      </Label>
    );
  }

  if (value.key === "date") {
    return (
      <Header as="h4" style={{ fontWeight: "normal" }}>
        {moment(data[value.key]).format("ll")}
      </Header>
    );
  }

  if (value.key === "title") {
    return (
      <Header as="h4" style={{ fontWeight: "normal" }}>
        {data[value.key]}
      </Header>
    );
  }

  if (value.key === "created_at") {
    return (
      <Header as="h4" style={{ fontWeight: "normal" }}>
        {moment(data[value.key]).format("ll")}
      </Header>
    );
  }

  if (value.key === "amount") {
    return (
      <Header as="h4" style={{ fontWeight: "normal" }}>{`${
        data[value.key]
      } $`}</Header>
    );
  }

  if (value.key === "cost") {
    return (
      <Header as="h4" style={{ fontWeight: "normal" }}>
        {numberFormatting(data[value.key] / 100)}
      </Header>
    );
  }

  if (value.key === "CPM") {
    return (
      <Header as="h4" style={{ fontWeight: "normal" }}>
        {numberFormatting(data[value.key] / 100)}
      </Header>
    );
  }

  if (value.key === "eCPM") {
    return (
      <Header as="h4" style={{ fontWeight: "normal" }}>
        {`${formatting(data[value.key] / 2, "cpm")}%`}
      </Header>
    );
  }

  if (value.key === "CTR") {
    return (
      <Header as="h4" style={{ fontWeight: "normal" }}>
        {data[value.key] || "0%"}
      </Header>
    );
  }

  return (
    <Header as="h4" style={{ fontWeight: "normal" }}>
      {data[value.key]}
    </Header>
  );
};

export default RenderCell;
