import React, { Component } from 'react';
import Input  from '../../shared/Forms/Inputs/inputs';
import Countries from '../../config/countries.json';
import Loader from 'react-loader-spinner'

class AccountDetailsComponent extends Component {
    state = {
        data: {
            address: this.props.AccountData.data.address,
            address2: this.props.AccountData.data.address2,
            city: this.props.AccountData.data.city,
            country: this.props.AccountData.data.country,
            first_name: this.props.AccountData.data.firstName,
            last_name: this.props.AccountData.data.lastName,
            legal: this.props.AccountData.data.legal,
            phone: this.props.AccountData.data.phone,
            postal_code: this.props.AccountData.data.postalCode,
            company: this.props.AccountData.data.company,
            email: this.props.AccountData.data.email
        },
    };

    requestData = {};

    onChangeFields = (name, value, valid) => {
        const self = this, state = { ...self.state };
        state.data[name] = value;
        this.requestData[name] = value
        self.setState(state);
    }

    onSumbit = () => {
        this.props.onProfileInfo({ ...this.state.data, provider: this.props.provider});
    }

    render() {
        return (
            <div className="shadow-widget" style={{ borderRadius: "5px", maxWidth: "800px" }}>
                <form class="ng-valid ng-dirty ng-touched" style={{ paddingBottom: "20px", paddingTop: "20px" }}>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="form-group">
                                <small>First Name *</small>
                                <Input
                                    type='name'
                                    value={this.state.data.first_name}
                                    name='first_name'
                                    inputClass='input-with-icon log-reg-input'
                                    icon=''
                                    dropdownIcon={false}
                                    onChange={(name, value, valid) => this.onChangeFields(name, value, valid)}
                                    disabled={false}
                                />
                            </div>
                            <div className="form-group">
                                <small>Email *</small>
                                <Input
                                    type='email'
                                    value={this.state.data.email}
                                    placeholder='example@gmail.com'
                                    name='email'
                                    inputClass='input-with-icon log-reg-input'
                                    icon=''
                                    dropdownIcon={false}
                                    onChange={(name, value, valid) => this.onChangeFields(name, value, valid)}
                                    disabled={false}
                                />
                            </div>
                            <div className="form-group">
                                <small>Address *</small>
                                <Input
                                    type='address'
                                    value={this.state.data.address}
                                    name='address'
                                    inputClass='input-with-icon log-reg-input'
                                    icon=''
                                    dropdownIcon={false}
                                    onChange={(name, value, valid) => this.onChangeFields(name, value, valid)}
                                    disabled={false}
                                />

                            </div>
                            <div className="form-group">
                                <small>Country *</small>
                                <small>Country*</small>
                                <select className="form-control" value={this.state.data.country} onChange={(e) => this.onChangeFields("country", e.target.value, true)}>
                                    <option value="">Country</option>
                                    {Countries.countries.map((item, key) => {
                                        return <option value={item.code} key={key}>{item.name}</option>
                                    })}
                                </select>

                            </div>
                            <div className="form-group">
                                <small>Phone *</small>
                                <Input
                                    type='phone'
                                    value={this.state.data.phone}
                                    name='phone'
                                    inputClass='input-with-icon log-reg-input'
                                    icon=''
                                    dropdownIcon={false}
                                    onChange={(name, value, valid) => this.onChangeFields(name, value, valid)}
                                    disabled={false}
                                />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <small>Last Name *</small>
                                <Input
                                    type='name'
                                    value={this.state.data.last_name}
                                    name='last_name'
                                    inputClass='input-with-icon log-reg-input'
                                    icon=''
                                    dropdownIcon={false}
                                    onChange={(name, value, valid) => this.onChangeFields(name, value, valid)}
                                    disabled={false}
                                />
                            </div>
                            <div className="form-group">
                                <small>Account Type *</small>
                                <select value={this.state.data.legal} onChange={(e) => { this.onChangeFields("legal", e.target.value, true) }} className="form-control">
                                    <option value="individual">Individual - Natural Person</option>
                                    <option value="company">Company - Legal Person</option>
                                </select>
                            </div>
                            {
                                this.state.data.legal === "company" &&
                                <div className="form-group">
                                    <small>Company Name*</small>
                                    <Input
                                        type='name'
                                        value={this.state.data.company}
                                        name='company'
                                        inputClass='input-with-icon log-reg-input'
                                        icon=''
                                        dropdownIcon={false}
                                        onChange={(name, value, valid) => this.onChangeFields(name, value, valid)}
                                        disabled={false}
                                    />
                                </div>
                            }
                            <div className="form-group">
                                <small>Address2</small>
                                <Input
                                    type='address'
                                    value={this.state.data.address2}
                                    name='address2'
                                    inputClass='input-with-icon log-reg-input'
                                    icon=''
                                    dropdownIcon={false}
                                    onChange={(name, value, valid) => this.onChangeFields(name, value, valid)}
                                    disabled={false}
                                />
                            </div>
                            <div className="form-group">
                                <small>Zip Code *</small>
                                <Input
                                    type='zip'
                                    value={this.state.data.postal_code}
                                    name='postal_code'
                                    inputClass='input-with-icon log-reg-input'
                                    icon=''
                                    dropdownIcon={false}
                                    onChange={(name, value, valid) => this.onChangeFields(name, value, valid)}
                                    disabled={false}
                                />
                            </div>
                            <div className="form-group">
                                <small>City *</small>
                                <Input
                                    type='name'
                                    value={this.state.data.city}
                                    name='city'
                                    inputClass='input-with-icon log-reg-input'
                                    icon=''
                                    dropdownIcon={false}
                                    onChange={(name, value, valid) => this.onChangeFields(name, value, valid)}
                                    disabled={false}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="clearfix">
                        {!this.props.ProfileInfoData.pending &&
                            <button type="button" className="btn btn-success" onClick={() => this.onSumbit()}>
                                <span className="button-text" >Submit</span>
                            </button>
                        }
                        {
                            this.props.ProfileInfoData.pending &&
                            <Loader
                                type="Bars"
                                color="#00BFFF"
                                height="40"
                                width="60"
                            />
                        }
                    </div>
                </form>
            </div>
        );
    }
}

export default AccountDetailsComponent;
