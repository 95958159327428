import React, { useState, useEffect, Fragment } from "react";
import { Button, Modal, Form, Select } from "semantic-ui-react";
import _ from "lodash";
import useOptions from "./useOptions";

const AppModal = ({ open, toggle, initialValues, handleSave }) => {
  const [initialState, setState] = useState(null);

  useEffect(() => {
    setState({ ...initialValues });
  }, [initialValues]);

  const handleChange = (key, value) =>
    setState(prevState => {
      const mutable = { ...prevState };
      mutable[key] = value;
      return { ...mutable };
    });

  const firstUpper = string => {
    const formatted = string.replace(/_/g, " ");
    return formatted.charAt(0).toUpperCase() + formatted.slice(1);
  };

  const [mediaType, category, genre] = useOptions();

  const selectOptions = type => {
    if (type === "media_type") {
      return mediaType;
    }
    if (type === "category") {
      return category;
    }
    return genre;
  };

  const handleSubmit = () => {
    handleSave({ ...initialState });
    toggle();
  };
  console.log("Edit Modal =>", initialState);
  return (
    <Modal
      size="tiny"
      open={open}
      onClose={toggle}
      style={{ position: "relative", height: "auto" }}
    >
      <Modal.Header>Edit App / Website</Modal.Header>
      <Modal.Content>
        <Form onSubmit={handleSubmit}>
          {initialState &&
            _.map(initialState, (value, key) => {
              if (
                key === "media_type" ||
                key === "category" ||
                key === "genre"
              ) {
                return (
                  <Form.Dropdown
                    key={key}
                    control={Select}
                    label={firstUpper(key)}
                    options={selectOptions(key)}
                    placeholder={firstUpper(value)}
                    onChange={(e, { value: dropDownVal }) =>
                      handleChange(key, dropDownVal)
                    }
                  />
                );
              }
              if (key !== "app_id" && key !== "provider") {
                return (
                  <Form.Field key={key}>
                    <label>{firstUpper(key)}</label>
                    <input
                      value={value}
                      onChange={({ target: { value: fieldVal } }) =>
                        handleChange(key, fieldVal)
                      }
                    />
                  </Form.Field>
                );
              }
              return <Fragment key={key} />;
            })}
          <Button color="green" type="submit">
            Save Changes
          </Button>
          <Button color="red" onClick={toggle}>
            Close
          </Button>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default AppModal;
