import CommonAction from "../../shared/Http/Http";
import {
  GET_REPORTS,
  GET_REPORTS_LAST,
  RESET_REPORTS_REDUCER
} from "../constants";

export const getReports = data =>
  CommonAction("POST", null, `control-extend/reports/get`, GET_REPORTS, data);
export const getReportsLast = data =>
  CommonAction(
    "POST",
    null,
    `control-extend/reports/get`,
    GET_REPORTS_LAST,
    data
  );

export const resetReportsReducer = () => ({ type: RESET_REPORTS_REDUCER });
