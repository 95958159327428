import React, { Component } from 'react';
import { Link } from 'react-router-dom';

const Step1 = props => {
  return (
    <div className="step1">
      <div className="row">
        <div className="col-md-3">
          <div className="form-group">
            <small>
              Name<span className="required">*</span>
            </small>
            <input
              type="text"
              placeholder="Name"
              className="form-control"
              value={props.requestNew.name}
              onChange={e => props.onChange('name', e.target.value)}
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group-text">
            <p style={{ fontSize: '12px' }}>
              Adspace name is title of your ad placement which is displayed in
              table and reportings.
            </p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3">
          <div className="form-group">
            <small>
              Platform<span className="required">*</span>
            </small>
            <select
              className="form-control"
              value={props.requestNew.platform}
              onChange={e => props.onChange('platform', e.target.value)}
            >
              <option value="AR">AR</option>
              <option value="VR">VR</option>
            </select>
          </div>
        </div>
      </div>
      {props.requestNew.platform === 'AR' && (
        <div className="row">
          <div className="col-md-3">
            <div className="form-group">
              <small>
                AR Mode<span className="required">*</span>
              </small>
              <select
                className="form-control"
                value={props.requestNew.arMode}
                onChange={e => props.onChange('arMode', e.target.value)}
              >
                <option value="markerbased">Marker Based</option>
                <option value="markerless">Marker Less</option>
              </select>
            </div>
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-md-3">
          <div className="form-group">
            <small>
              Ad space type<span className="required">*</span>
            </small>
            <label style={{ display: 'block', marginTop: '10px' }}>
              <input type="checkbox" checked={true} disabled={true} />
              <span style={{ marginLeft: '5px' }}>Unity 3D</span>
            </label>
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group-text">
            <p style={{ fontSize: '12px' }}>
              Select what platform that you want to monetize. Choose if you want
              to monetize your Unity 3D or WebVR(coming soon) app.
            </p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3">
          <div className="form-group">
            <small>
              Select SDK type<span className="required">*</span>
            </small>
            {props.requestNew.platform === 'VR' && (
              <fieldset id="group1">
                <input
                  type="radio"
                  checked={props.requestNew.headset_sdk === 'cardboard'}
                  value="cardboard"
                  name="group1"
                  onChange={e => props.onChange('headset_sdk', e.target.value)}
                />
                <span style={{ marginRight: '10px' }}>Cardboard</span>
                <input
                  type="radio"
                  checked={props.requestNew.headset_sdk === 'oculus_gear_vr'}
                  value="oculus_gear_vr"
                  name="group1"
                  onChange={e => props.onChange('headset_sdk', e.target.value)}
                />
                <span>Oculus, Gear VR</span>
              </fieldset>
            )}
            {props.requestNew.platform === 'AR' && (
              <label style={{ display: 'block', marginTop: '10px' }}>
                <input type="checkbox" checked={true} disabled={true} />
                <span style={{ marginLeft: '5px' }}>Oculus, Gear VR</span>
              </label>
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3">
          <div className="form-group">
            <small>
              Ad Type<span className="required">*</span>
            </small>
            {props.requestNew.platform === 'AR' && (
              <select
                className="form-control"
                value={props.requestNew.adTypes}
                onChange={e => props.onChange('adTypes', e.target.value)}
              >
                <option value="fbxAd">3D Object</option>
                <option value="flatVideo">Flat Video</option>
              </select>
            )}
            {props.requestNew.platform === 'VR' && (
              <select
                className="form-control"
                value={props.requestNew.adTypes}
                onChange={e => props.onChange('adTypes', e.target.value)}
              >
                <option value="videoAd360">360 Video Ad</option>
                <option value="sceneAd">Scene Ad</option>
              </select>
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <input
            type="checkbox"
            checked={props.requestNew.gambling}
            className="gamblingadult"
            onChange={() => props.onChekBoxChanges('gambling')}
          />
          <span style={{ color: '#717171', marginLeft: '10px' }}>
            I approve to receive Gambling Ads to my ad space.
          </span>
        </div>
        <div className="col-md-6">
          <input
            type="checkbox"
            checked={props.requestNew.adult}
            className="gamblingadult"
            onChange={() => props.onChekBoxChanges('adult')}
          />
          <span style={{ color: '#717171', marginLeft: '10px' }}>
            I approve to receive Adult Ads to my ad space.
          </span>
        </div>
      </div>
      <div className="footer-buttons">
        <Link
          className="btn btn-default"
          to={`/control/publisher/apps/adspaces/${props.id}`}
        >
          Cancel
        </Link>
        <button
          className="btn btn-primary pull-right"
          onClick={() => props.onSave()}
          disabled={props.requestNew.name === ''}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default Step1;
