import { handleActions } from "redux-actions";
import { RECOVER_PASSWORD } from "../constants";

const initialState = {
  pending: false,
  success: false,
  data: null
};

const RegistrationReducer = handleActions(
  {
    [RECOVER_PASSWORD.PENDING]: (state = initialState) => ({
      ...state,
      pending: true
    }),

    [RECOVER_PASSWORD.SUCCESS]: (state = initialState, { payload }) => ({
      ...state,
      pending: false,
      success: true,
      data: payload
    }),

    [RECOVER_PASSWORD.ERROR]: (state = initialState, { error }) => ({
      ...state,
      pending: false,
      success: false,
      error
    })
  },
  initialState
);

export default RegistrationReducer;
