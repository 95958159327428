import React, { Component } from "react";
import { Link } from "react-router-dom";
import Loader from "../../shared/Loader/index";
import Step1 from "./Step1/Step1";

const queryString = require("query-string");

class CreateAdSpacesComponent extends Component {
  state = {
    SdkModal: false,
    deletedModalId: "",
    step: "1",
    requestNew: {
      name: "",
      platform: "AR",
      arMode: "markerbased",
      headset_sdk: "oculus_gear_vr",
      adTypes: "fbxAd",
      appId: this.props.id,
      type: "unity",
      gambling: false,
      adult: false
    }
  };

  componentWillMount() {
    const parsed = queryString.parse(window.location.search);
    if (parsed.id) {
      this.setState({ id: parsed.id });
      this.props.adSpacesData.data.map(item => {
        if (item.adspace_id === parsed.id) {
          const requestNew = {
            name: item.name,
            platform: item.platform,
            arMode: item.arMode,
            headset_sdk: item.headset_sdk,
            adTypes: item.adTypes,
            appId: item.app_id,
            type: "unity",
            gambling: item.gambling ? item.gambling : false,
            adult: item.adult ? item.adult : false
          };
          this.setState({ requestNew });
        }
      });
    }
  }

  onChekBoxChanges = key => {
    const {requestNew} = this.state;
    if (requestNew[key]) {
      requestNew[key] = false;
    } else if (!requestNew[key]) {
      requestNew[key] = true;
    }
    this.setState({ requestNew });
  };

  onChangeNewRequest = (name, value) => {
    const {requestNew} = this.state;
    requestNew[name] = value;
    if (name === "platform" && value === "AR") {
      requestNew.adTypes = "fbxAd";
    }
    if (name === "platform" && value === "VR") {
      requestNew.adTypes = "videoAd360";
    }
    this.setState({ requestNew });
  };

  onCreate = () => {
    const body = this.state.requestNew;
    if (body.platform === "VR") {
      delete body.arMode;
    }
    body.provider = this.props.optionsData.provider;
    this.props.addAdSpaces(body);
  };

  onEdit = () => {
    const body = this.state.requestNew;
    if (body.platform === "VR") {
      delete body.arMode;
    }
    body.provider = this.props.optionsData.provider;
    this.props.editAdSpaces(this.state.id, body);
  };

  render() {
    return (
      <React.Fragment>
        {this.props.deleteAddSpacesData.pending && <Loader />}
        <div
          className="content-header clearfix"
          style={{ borderBottom: "1px solid lightgray", marginBottom: "20px" }}
        >
          <h1>
            <Link to="/control/publisher/apps">App / Website</Link> |{" "}
            <Link to={`/control/publisher/apps/adspaces/${this.props.id}`}>
              AD SPACES
            </Link>{" "}
            | NEW AD SPACE
          </h1>
        </div>
        <div className="container-non-responsive new-campaigns">
          <div className="row">
            <div className="col-sm-9 new-campaigns-body">
              <div className="shadow-widget colored-blue">
                <ul className="nav nav-tabs">
                  <li className={this.state.step === "1" ? "active" : ""}>
                    <a>Step 1 | General</a>
                  </li>
                </ul>
                {this.state.step === "1" && (
                  <Step1
                    id={this.props.id}
                    requestNew={this.state.requestNew}
                    onChange={this.onChangeNewRequest}
                    onSave={this.state.id ? this.onEdit : this.onCreate}
                    onChekBoxChanges={this.onChekBoxChanges}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default CreateAdSpacesComponent;
