import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Checkbox } from 'semantic-ui-react';

class Step2 extends Component {
  state = {
    flatVideoName: '',
    flatVideo: '',
    sceneAdName: '',
    sceneAdVideo: '',
    video480Name: '',
    video480: '',
    video1080Name: '',
    video1080: '',
    video2KName: '',
    video2K: '',
    video4KName: '',
    video4K: '',
    video480Valid: false,
    video1080Valid: false,
    video2kValid: false,
    video4kValid: false,
  };

  onFlatVideoAdd = e => {
    let self = this;
    let name = e.target.files[0].name;
    this.setState({ flatVideoName: name });
    let file = e.target.files[0];
    this.convertBase64(e.target.files[0]).then(res => {
      this.setState({ flatVideo: res }, () => {
        let video = document.getElementById('flatVideo');
        video.addEventListener(
          'loadedmetadata',
          function(e) {
            let duration = video.duration % 60;
            self.props.onVideoAd('flatVideo', file, duration);
          },
          false
        );
      });
    });
  };

  onSceneAdVideoAd = e => {
    let name = e.target.files[0].name;
    let file = e.target.files[0];
    let self = this;
    this.setState({ sceneAdName: name });
    this.convertBase64(e.target.files[0]).then(res => {
      this.setState({ sceneAdVideo: res }, () => {
        let video = document.getElementById('sceneAd');
        video.addEventListener(
          'loadedmetadata',
          function(e) {
            let duration = video.duration % 60;
            self.props.onVideoAd('sceneAd', file, duration);
          },
          false
        );
      });
    });
  };

  on360VideoAd = (e, name) => {
    let self = this;
    if (name === '480') {
      let name = e.target.files[0].name;
      let file = e.target.files[0];
      this.setState({ video480Name: name });
      this.convertBase64(e.target.files[0]).then(res => {
        self.setState({ video480: res }, () => {
          let video = document.getElementById('video480');
          video.addEventListener(
            'loadedmetadata',
            function(e) {
              let height = this.videoHeight;
              let duration = video.duration % 60;
              if (height <= 720) {
                self.setState({
                  video480Valid: true,
                });
                self.props.on360VideoAdd('480', file, duration);
              }
            },
            false
          );
        });
      });
    }
    if (name === '1080') {
      let name = e.target.files[0].name;
      let file = e.target.files[0];
      this.setState({ video1080Name: name });
      this.convertBase64(e.target.files[0]).then(res => {
        this.setState({ video1080: res }, () => {
          let video = document.getElementById('video1080');
          video.addEventListener(
            'loadedmetadata',
            function(e) {
              let height = this.videoHeight;
              let duration = video.duration % 60;
              if (height >= 1080 && height < 1440) {
                self.setState({
                  video1080Valid: true,
                });
                self.props.on360VideoAdd('1080', file, duration);
              }
            },
            false
          );
        });
      });
    }
    if (name === '2K') {
      let name = e.target.files[0].name;
      let file = e.target.files[0];
      this.setState({ video2KName: name });
      this.convertBase64(e.target.files[0]).then(res => {
        this.setState({ video2K: res }, () => {
          let video = document.getElementById('video2k');
          video.addEventListener(
            'loadedmetadata',
            function(e) {
              let height = this.videoHeight;
              let duration = video.duration % 60;
              if (height >= 1440 && height < 2160) {
                self.setState({
                  video2kValid: true,
                });
                self.props.on360VideoAdd('2k', file, duration);
              }
            },
            false
          );
        });
      });
    }
    if (name === '4K') {
      let name = e.target.files[0].name;
      let file = e.target.files[0];
      this.setState({ video4KName: name });
      this.convertBase64(e.target.files[0]).then(res => {
        this.setState({ video4K: res }, () => {
          let video = document.getElementById('video4k');
          video.addEventListener(
            'loadedmetadata',
            function(e) {
              let height = this.videoHeight;
              let duration = video.duration % 60;
              if (height >= 2160) {
                self.setState({
                  video4kValid: true,
                });
                self.props.on360VideoAdd('4k', file, duration);
              }
            },
            false
          );
        });
      });
    }
  };

  convertBase64 = value => {
    return new Promise(resolve => {
      var reader = new FileReader();
      reader.readAsDataURL(value);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = error => {
        console.log('Error: ', error);
      };
    });
  };

  add3dObject = value => {
    const {on3dObjectAdd} = this.props;
    on3dObjectAdd(value)
  }

  render() {
    return (
      <div className="step2">
        <div className="row">
          <div className="col-md-3">
            <div className="form-group">
              <small>
                Callback Type <span className="required">*</span>
              </small>
              <select
                className="form-control"
                onChange={e => {
                  this.props.onUrlTypeChange(e.target.value);
                }}
                value={this.props.urlType}
              >
                <option value="app">App</option>
                <option value="webside">Website</option>
              </select>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group-text">
              <p style={{ fontSize: '12px' }}>
                Where to perform is place where your campaign will be displayed
                wheater in mobile browser or in applications.
              </p>
            </div>
          </div>
        </div>
        {
          <div className="row">
            {this.props.urlType === 'webside' && (
              <div className="col-md-3">
                <div className="form-group">
                  <small>
                    Url<span className="required">*</span>
                  </small>
                  <input
                    type="text"
                    className="form-control urlInput"
                    placeholder="https://"
                    onChange={e => {
                      this.props.onChangeUrels(e.target.value, '');
                    }}
                  />
                </div>
              </div>
            )}
            {this.props.urlType === 'app' && (
              <React.Fragment>
                <div className="col-md-3">
                  <div className="form-group">
                    <small>
                      Google Play <span className="required">*</span>
                    </small>
                    <input
                      type="text"
                      className="form-control urlInput"
                      placeholder="https://"
                      onChange={e => {
                        this.props.onChangeUrels(e.target.value, 'google');
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-1" style={{ textAlign: 'center' }}>
                  OR
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <small>
                      App Store <span className="required">*</span>
                    </small>
                    <input
                      type="text"
                      className="form-control urlInput"
                      placeholder="https://"
                      onChange={e => {
                        this.props.onChangeUrels(e.target.value, 'appStore');
                      }}
                    />
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
        }
        <div className="row">
          <div className="col-md-3">
            <div className="form-group">
              <small>
                Skippable <span className="required">*</span>
              </small>
              <select
                className="form-control"
                value={this.props.skippable}
                onChange={e => this.props.onSkipAbleChange(e.target.value)}
              >
                <option value={false}>No Skippable</option>
                <option value={true}>Skippable</option>
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <div className="form-group">
              <small>
                Ad type <span className="required">*</span>
              </small>
              {this.props.platform === 'AR' && (
                <select
                  className="form-control"
                  value={this.props.adType}
                  onChange={e => this.props.onChangeAdType(e.target.value)}
                >
                  <option value="3dObject">3D Object</option>
                  <option value="flatVideo">Flat Video</option>
                </select>
              )}
              {this.props.platform === 'VR' && (
                <select
                  className="form-control"
                  value={this.props.adType}
                  onChange={e => this.props.onChangeAdType(e.target.value)}
                >
                  <option value="360video">360 Video Mode</option>
                  <option value="sceneAd">360 Scene Ad</option>
                </select>
              )}
            </div>
          </div>
        </div>
        {this.props.adType === 'flatVideo' && (
          <div className="row">
            <div className="col-md-3">
              {this.state.flatVideo && (
                <video width="250" controls id="flatVideo">
                  <source src={this.state.flatVideo} />
                </video>
              )}
              <div className="form-group">
                <small>
                  Flat Video <span className="required">*</span>
                </small>
                <input
                  type="file"
                  accept=".mp4"
                  name="file"
                  id="file"
                  className="inputfile"
                  onChange={e => this.onFlatVideoAdd(e)}
                />
                <label htmlFor="file">
                  Choose a file{' '}
                  <input
                    type="text"
                    disabled={true}
                    value={this.state.flatVideoName}
                  />
                </label>
                <div>
              <Checkbox label='Adult' className="mr-3" checked={this.props.checkMarks.adult} onChange={this.props.handleAdultChange} />
              <Checkbox label='Gambling' checked={this.props.checkMarks.gambling} onChange={this.props.handleGambleChange} />
              <div>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ratione dolores ab inventore qui molestiae earum autem dolorum, atque aliquid dolor animi iure! Eos, labore ullam officia fugit incidunt dolores blanditiis.</div>
              </div>
              </div>
            </div>
          </div>
        )}
        {this.props.adType === '3dObject' && (
          <div className="row">
            <div className="col-md-3">
              <small>
                3D Object <span className="required">*</span>
              </small>
              <div className="form-group">
                <small>IOS</small>
                <input
                  type="file"
                  // accept=".bundle"
                  name="file"
                  id="file"
                  className="inputfile"
                  onChange={(e) => this.add3dObject(e.target.files[0])}
                />
                <label htmlFor="file">
                  Choose a file <input type="text"/>
                </label>
              </div>
            </div>
            <div className="col-md-9">
              <div className="form-group-text">
                <p style={{ fontSize: '12px' }}>
                  3D object must be in *.bundle format
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <small>Android</small>
                <input
                  type="file"
                  // accept=".bundle"
                  name="android"
                  id="android"
                  className="inputfile"
                />
                <label htmlFor="android">
                  Choose a file <input type="text" disabled={true} />
                </label>
              </div>
              <div>
              <Checkbox label='Adult' className="mr-3" checked={this.props.checkMarks.adult} onChange={this.props.handleAdultChange} />
              <Checkbox label='Gambling' checked={this.props.checkMarks.gambling} onChange={this.props.handleGambleChange} />
              <div>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ratione dolores ab inventore qui molestiae earum autem dolorum, atque aliquid dolor animi iure! Eos, labore ullam officia fugit incidunt dolores blanditiis.</div>
              </div>
            </div>
          </div>
        )}
        {this.props.adType === 'sceneAd' && (
          <div className="row">
            <div className="col-md-3">
              <small>
                Video File <span className="required">*</span>
              </small>
              {this.state.sceneAdVideo && (
                <video width="250" controls id="sceneAd">
                  <source src={this.state.sceneAdVideo} />
                </video>
              )}
              <div className="form-group">
                <input
                  type="file"
                  accept=".mp4"
                  name="file"
                  id="file"
                  className="inputfile"
                  onChange={e => this.onSceneAdVideoAd(e)}
                />
                <label htmlFor="file">
                  Choose a file{' '}
                  <input
                    type="text"
                    disabled={true}
                    value={this.state.sceneAdName}
                  />
                </label>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group-text">
                <p style={{ fontSize: '12px' }}>
                  You can upload your ad in MP4 formats.
                </p>
              </div>
            </div>
          </div>
        )}
        {this.props.adType === '360video' && (
          <React.Fragment>
            <div className="row">
              <div className="col-md-3">
                <small>
                  480 Video<span className="required">*</span>
                </small>
                {this.state.video480 && (
                  <video width="250" controls id="video480">
                    <source src={this.state.video480} />
                  </video>
                )}
                <div className="form-group">
                  <input
                    type="file"
                    accept=".mp4"
                    name="file480"
                    id="file480"
                    className="inputfile"
                    onChange={e => this.on360VideoAd(e, '480')}
                  />
                  <label htmlFor="file480">
                    Choose a file{' '}
                    <input
                      type="text"
                      disabled={true}
                      value={this.state.video480Name}
                    />
                  </label>
                  {this.state.video480 && !this.state.video480Valid && (
                    <div className="errorMessage">
                      It Must be Video and height 480
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group-text">
                  <p style={{ fontSize: '12px' }}>
                    You can upload your ad in MP4 formats.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <small>
                  1080 Video<span className="required">*</span>
                </small>
                {this.state.video1080 && (
                  <video width="250" controls id="video1080">
                    <source src={this.state.video1080} />
                  </video>
                )}
                <div className="form-group">
                  <input
                    type="file"
                    accept=".mp4"
                    name="file1080"
                    id="file1080"
                    className="inputfile"
                    onChange={e => this.on360VideoAd(e, '1080')}
                  />
                  <label htmlFor="file1080">
                    Choose a file{' '}
                    <input
                      type="text"
                      disabled={true}
                      value={this.state.video1080Name}
                    />
                  </label>
                  {this.state.video1080 && !this.state.video1080Valid && (
                    <div className="errorMessage">
                      It Must be Video and height 1080
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <small>
                  2K Video<span className="required">*</span>
                </small>
                {this.state.video2K && (
                  <video width="250" controls id="video2k">
                    <source src={this.state.video2K} />
                  </video>
                )}
                <div className="form-group">
                  <input
                    type="file"
                    accept=".mp4"
                    name="file2K"
                    id="file2K"
                    className="inputfile"
                    onChange={e => this.on360VideoAd(e, '2K')}
                  />
                  <label htmlFor="file2K">
                    Choose a file{' '}
                    <input
                      type="text"
                      disabled={true}
                      value={this.state.video2KName}
                    />
                  </label>
                  {this.state.video2K && !this.state.video2kValid && (
                    <div className="errorMessage">
                      It Must be Video and height 1440
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <small>
                  4K Video<span className="required">*</span>
                </small>
                {this.state.video4K && (
                  <video width="250" controls id="video4k">
                    <source src={this.state.video4K} />
                  </video>
                )}
                <div className="form-group">
                  <input
                    type="file"
                    accept=".mp4"
                    name="file4K"
                    id="file4K"
                    className="inputfile"
                    onChange={e => this.on360VideoAd(e, '4K')}
                  />
                  <label htmlFor="file4K">
                    Choose a file{' '}
                    <input
                      type="text"
                      disabled={true}
                      value={this.state.video4KName}
                    />
                  </label>
                  {this.state.video4K && !this.state.video4kValid && (
                    <div className="errorMessage">
                      It Must be Video and height 2160
                    </div>
                  )}
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
        <div className="footer-buttons">
          <Link className="btn btn-default" to="/control/advertiser/campaigns">
            Cancel
          </Link>
          <button
            type="button"
            className="btn btn-primary pull-right"
            onClick={() => this.props.onStepChange('3')}
            disabled={
              !this.props.urlValid ||
              (this.props.adType === 'flatVideo' && !this.state.flatVideo) ||
              (this.props.adType === 'sceneAd' && !this.state.sceneAdVideo) ||
              (this.props.adType === '360video' &&
                Object.keys(this.props.videos360).length < 2)
            }
          >
            Next
          </button>
          <button
            type="button"
            className="btn btn-default pull-right"
            onClick={() => this.props.onStepChange('1')}
            style={{ marginRight: '5px' }}
          >
            Back
          </button>
        </div>
      </div>
    );
  }
}

export default Step2;
