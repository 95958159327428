import React, { Fragment } from "react";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import InnerPagesComponent from "../../components/InnerPagesComponent/InnerPagesComponent";
import Loader from "../../shared/Loader";
import { resetReducer } from "../../store/actions/resetReducerAction";
import WithdrawComponent from "../../components/WithdrawComponent/WithdrawComponent";
import { getRates, coinWithDraw } from "../../store/actions/BillingActions";

const WithdrawContainer = ({
  CoinWithdrawData: { fetched },
  optionsData: {
    options: { logo },
    withdrawalGateways,
    displayName,
    provider
  },
  CoinWithdrawData,
  getRates,
  RatesData,
  coinWithDraw
}) => {
  if (fetched) {
    return <Redirect to="/control/billing" />;
  }
  return (
    <Fragment>
      <Helmet>
        <title>Registration | {displayName} Control Panel</title>
      </Helmet>
      <InnerPagesComponent pageName="Withdraw" logo={logo}>
        {CoinWithdrawData.pending && <Loader />}
        <WithdrawComponent
          withdrawalGateways={withdrawalGateways}
          getRates={getRates}
          RatesData={RatesData}
          coinWithDraw={coinWithDraw}
          provider={provider}
        />
      </InnerPagesComponent>
    </Fragment>
  );
};

const mapStateFromProps = store => ({
  optionsData: store.DashboardReducer.optionsData,
  RatesData: store.BillingInfoReducer.RatesData,
  CoinWithdrawData: store.BillingInfoReducer.CoinWithdrawData
});

const mapDispatchFromProps = dispatch => ({
  resetReducer: name => dispatch(resetReducer(name)),
  getRates: (data) => dispatch(getRates(data)),
  coinWithDraw: data => dispatch(coinWithDraw(data))
});

export default withRouter(
  connect(
    mapStateFromProps,
    mapDispatchFromProps
  )(WithdrawContainer)
);
