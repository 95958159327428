import Auth from '@aws-amplify/auth';
import { ENV } from '../../environment';

Auth.configure({
  'aws_cognito_region': ENV.REGION,
  'aws_sign_in_enabled': 'enable',
  'aws_user_pools': 'enable',
});

export default Auth;
