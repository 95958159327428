import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "reactstrap";
import { Button, Form, Icon, Message, Header } from "semantic-ui-react";
import SelectCountries from "./SelectCountries";
import {
  email as emailPattern,
  password as passwordPattern
} from "../../../config/regex";
import "./RegistrationComponent.scss";

const RegistrationComponent = React.memo(
  ({
    dashboardReducer: {
      optionsData: {
        options: { logo },
        provider
      }
    },
    registration: { pending },
    checkEmail,
    handleSubmit,
    checkEmailData,
    resetReducer
  }) => {
    const [options] = useState([
      { value: "advertiser", text: "Advertiser", key: "advertiser" },
      { value: "publisher", text: "Publisher", key: "publisher" }
    ]);

    const [formFields, setFormFields] = useState({
      type: "",
      email: "",
      phoneNumber: "",
      locale: "",
      password: "",
      confirmPassword: "",
      agreement: false
    });

    const [valid, setValid] = useState(false);
    const [passwordMatch, setPasswordMatch] = useState(true);
    const [onFieldBlur, setBlur] = useState(false);
    const [onPasswordBlur, setPasswordBlur] = useState(false);

    useEffect(() => {
      if (
        formFields.confirmPassword &&
        formFields.password &&
        formFields.password !== formFields.confirmPassword &&
        onFieldBlur
      ) {
        setPasswordMatch(false);
      } else {
        setPasswordMatch(true);
      }
    }, [formFields.confirmPassword, formFields.password, onFieldBlur]);

    useEffect(() => {
      if (
        formFields.password === formFields.confirmPassword &&
        emailPattern.test(formFields.email) &&
        checkEmailData.success &&
        passwordPattern.test(formFields.password) &&
        formFields.type &&
        formFields.locale &&
        formFields.phoneNumber &&
        formFields.agreement
      ) {
        setValid(true);
      } else {
        setValid(false);
      }
    }, [checkEmailData.success, formFields]);

    useEffect(() => {
      if (emailPattern.test(formFields.email)) {
        checkEmail({email: formFields.email, provider});
      }
    }, [checkEmail, formFields.email]);

    const handleChange = (type, value) =>
      setFormFields(prevState => {
        const state = { ...prevState };
        state[type] = value;
        return { ...state };
      });

    return (
      <Container fluid className="registration-container">
        <Row className="w-100 m-0">
          <Row className="justify-content-center align-items-center w-50 m-0">
            <Col
              sm="12"
              className="d-flex justify-content-center p-0 align-self-end"
            >
              <Col
                sm="6"
                className="d-flex justify-content-center align-items-center"
                style={{ flexDirection: "column" }}
              >
                {onPasswordBlur &&
                  formFields.password &&
                  !passwordPattern.test(formFields.password) && (
                    <Message
                      warning
                      header="Warning"
                      content="Password field should contain [A-z] , [1-9] , [!-*]"
                    />
                  )}
                <span style={{ fontSize: "1.7rem" }}>Registration</span>
                <img src={logo} style={{ height: 70 }} alt="" className="mt-3"/>
              </Col>
            </Col>
            <Form
              className="col-sm-6 registration-form align-self-start"
              warning={
                onPasswordBlur &&
                formFields.password &&
                !passwordPattern.test(formFields.password)
              }
            >
              <Form.Select
                label="Select Account Type"
                options={options}
                placeholder="Account Type"
                onChange={(e, { value }) => handleChange("type", value)}
                className="reg-select"
              />
              {/* {checkEmailData.error && <Message error header="Check Email Error" content="This email alraedy exist" />} */}
              <Form.Input
                error={checkEmailData.error && true}
                icon={
                  (!checkEmailData.error && !checkEmailData.success && (
                    <Icon name="mail" />
                  )) ||
                  (checkEmailData.success && (
                    <Icon name="check" color="green" />
                  )) ||
                  (checkEmailData.error && <Icon name="times" color="red" />)
                }
                iconPosition="left"
                fluid
                label={checkEmailData.error ? "Email already exist" : "Email"}
                type="email"
                placeholder="example@gmail.com"
                onChange={(e, { value }) => handleChange("email", value)}
                loading={checkEmailData.pending}
              />
              <Form.Input
                fluid
                iconPosition="left"
                label="Phone"
                placeholder="Ex. +374 55 266 593"
                icon="phone"
                type="number"
                onChange={(e, { value }) => handleChange("phoneNumber", value)}
              />
              <SelectCountries handleChange={handleChange} />

              <Form.Input
                icon="lock"
                fluid
                label={passwordMatch ? "Password" : `Password Doesn't match`}
                type="password"
                iconPosition="left"
                placeholder="Password"
                onChange={(e, { value }) => handleChange("password", value)}
                error={!passwordMatch}
                onBlur={() => setPasswordBlur(true)}
              />
              <Form.Input
                icon="lock"
                fluid
                label="Confirm Password"
                iconPosition="left"
                type="password"
                placeholder="Confirm Password"
                onChange={(e, { value }) =>
                  handleChange("confirmPassword", value)
                }
                onBlur={() => setBlur(true)}
              />

              <Form.Checkbox
                label={
                  <label>
                    I accept the{" "}
                    <a
                      href="https://adlooker.com/legal.html"
                      target="_blank"
                      style={{ color: "#1f88be" }}
                    >
                      User Agreement
                    </a>{" "}
                    and{" "}
                    <a
                      href="https://adlooker.com/tos.html"
                      target="_blank"
                      style={{ color: "#1f88be" }}
                    >
                      Terms and Conditions
                    </a>
                  </label>
                }
                onChange={(e, { checked }) =>
                  handleChange("agreement", checked)
                }
              />
              <div className="d-flex">
                <Button
                  size="large"
                  className="col-sm-6 mt-2 back-btn"
                  color="red"
                  onClick={() => window.history.back()}
                >
                  Back
                </Button>
                <Button
                  color="linkedin"
                  size="large"
                  className="col-sm-6 mt-2 reg-btn"
                  loading={pending}
                  onClick={() => {
                    handleSubmit({ ...formFields, provider });
                    resetReducer();
                  }}
                  disabled={!valid}
                >
                  Registration
                </Button>
              </div>
            </Form>
          </Row>
          <Col
            sm="6"
            className="login-back"
            style={{ position: "absolute", right: "0" }}
          />
          <Col
            sm="6"
            className="d-flex justify-content-center"
            style={{ position: "absolute", right: "0", top: "30%" }}
          >
            <Header
              textAlign="center"
              size="huge"
              style={{
                color: "white",
                fontSize: "1.7rem",
                fontStyle: "italic"
              }}
              className="col-sm-6"
            >
              &ldquo;{" "}
              <span>
                The best advertising should make you{" "}
                <span className="marked-word">nervous</span> about what
                you&apos;re buying ... There is too much smart-ass advertising
                today and not enough that{" "}
                <span className="marked-word">emotionally moves</span> consumers
                to go out and buy something
              </span>{" "}
              &ldquo;
              <br />
              <span className="d-block text-right mt-3">
                Marry Wells Lawrence
              </span>
            </Header>
          </Col>
        </Row>
      </Container>
    );
  }
);

export default RegistrationComponent;
