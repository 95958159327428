import {
  OPTIONS_GET,
  PERMISSIONS_GET,
  GET_DASHBOARD_DATA,
  GET_FINANCE_DATA
} from "../constants";

const defaultState = {
  optionsPending: false,
  optionsFetched: false,
  optionsError: null,
  optionsData: {},
  permissionsPending: false,
  permissionsFetched: false,
  permissionsError: null,
  permissionsData: {},
  dashboardData: {
    pending: false,
    fetched: false,
    error: null,
    data: null
  },
  financeData: {
    pending: false,
    fetched: false,
    error: null,
    data: null
  }
};

const DashboardReducer = (state = defaultState, action) => {
  const { type, payload, error } = action;
  switch (type) {
    case OPTIONS_GET.PENDING: {
      return {
        ...state,
        optionsPending: true,
        optionsFetched: false
      };
    }
    case OPTIONS_GET.SUCCESS: {
      return {
        ...state,
        optionsPending: false,
        optionsFetched: true,
        optionsData: payload
      };
    }
    case OPTIONS_GET.ERROR: {
      return {
        ...state,
        optionsPending: false,
        optionsFetched: false,
        optionsError: error,
        optionsData: {}
      };
    }

    case PERMISSIONS_GET.PENDING: {
      return {
        ...state,
        permissionsPending: true,
        permissionsFetched: false
      };
    }
    case PERMISSIONS_GET.SUCCESS: {
      return {
        ...state,
        permissionsPending: false,
        permissionsFetched: true,
        permissionsData: payload
      };
    }
    case PERMISSIONS_GET.ERROR: {
      return {
        ...state,
        permissionsPending: false,
        permissionsFetched: false,
        permissionsError: error,
        permissionsData: {}
      };
    }
    case GET_DASHBOARD_DATA.PENDING: {
      return {
        ...state,
        dashboardData: {
          pending: true,
          fetched: false,
          error: null,
          data: null
        }
      };
    }
    case GET_DASHBOARD_DATA.SUCCESS: {
      return {
        ...state,
        dashboardData: {
          pending: false,
          fetched: true,
          error: null,
          data: payload
        }
      };
    }
    case GET_DASHBOARD_DATA.ERROR: {
      return {
        ...state,
        dashboardData: {
          pending: false,
          fetched: false,
          error,
          data: null
        }
      };
    }
    case GET_FINANCE_DATA.PENDING: {
      return {
        ...state,
        financeData: {
          pending: true,
          fetched: false,
          error: null,
          data: null
        }
      };
    }
    case GET_FINANCE_DATA.SUCCESS: {
      return {
        ...state,
        financeData: {
          pending: false,
          fetched: true,
          error: null,
          data: payload
        }
      };
    }
    case GET_FINANCE_DATA.ERROR: {
      return {
        ...state,
        financeData: {
          pending: false,
          fetched: false,
          error,
          data: null
        }
      };
    }
    default:
      return state;
  }
};

export default DashboardReducer;
