import CommonAction from "../../shared/Http/Http";
import {
  GET_COMPAIGNS,
  ADD_COMPAIGNS,
  DELETE_COMPAIGNS,
  CHANGE_STATUS_COMPAIGNS,
  RESET_CAMPAIGNS_DATA_REDUCER,
  EDIT_CAMPAIGN_BY_ID
} from "../constants";

export const getCompaigns = data =>
  CommonAction("POST", null, `control/campaigns`, GET_COMPAIGNS, {
    ...data,
    limit: 20
  });

export const addCompaings = data =>
  CommonAction("POST", null, `control/campaign/`, ADD_COMPAIGNS, data);

export const editCompaings = (id, data) =>
  CommonAction("PUT", null, `control/campaign/${id}`, ADD_COMPAIGNS, data);

export const getCompaignById = ({ id, provider }, data) =>
  CommonAction(
    "GET",
    null,
    `control/campaign/${id}?provider=${provider}`,
    EDIT_CAMPAIGN_BY_ID,
    data
  );

export const deleteCompaings = ({ id, provider }) =>
  CommonAction(
    "DELETE",
    null,
    `control/campaign/${id}?provider=${provider}`,
    DELETE_COMPAIGNS,
    null
  );

export const ChangeCompaingsStatus = (data, id) =>
  CommonAction(
    "PUT",
    null,
    `control/campaign/change-status/${id}`,
    CHANGE_STATUS_COMPAIGNS,
    data
  );

export const resetCampaignsDataReducer = () => ({
  type: RESET_CAMPAIGNS_DATA_REDUCER
});
