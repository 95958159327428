import React, { useState, useEffect } from "react";
import { Chart } from "react-google-charts";
import { Row, Col } from "reactstrap";
import { Icon } from "semantic-ui-react";
import Numeral from "numeral";
import moment from "moment";
import _ from "lodash";
import "./LineChart.scss";

const LineChart = ({
  dashboardData: {
    data: { traffic, totalweek }
  }
}) => {
  const [trafficData, setTrafficData] = useState([]);
  const [trafficState, setTrafficState] = useState([]);
  const [activeItem, setActiveItem] = useState("traffic");

  const setDay = count =>
    moment()
      .subtract(count, "days")
      .format("MMM DD");
  // .format("MM/D/YYYY");

  const [initialState] = useState([
    ["lastWeek", "Traffic"],
    [setDay(6), 0],
    [setDay(5), 0],
    [setDay(4), 0],
    [setDay(3), 0],
    [setDay(2), 0],
    [setDay(1), 0],
    [setDay(0), 0]
  ]);

  useEffect(() => {
    if (traffic.length > 0) {
      const state = traffic.map(it => ({
        // date: moment(it.date).format("MM/D/YYYY"),
        date: moment(it.date).format("MMM DD"),
        traffic: it.traffic,
        cost: it.cost
      }));

      return setTrafficState([...state]);
    }
    return setTrafficState([...initialState]);
  }, [initialState, traffic]);

  useEffect(() => {
    const mutable = initialState.map(item => {
      const match = _.find(trafficState, i => i.date === item[0]);
      const matchCost = _.find(trafficState, i => i.date === item[0]);

      if (match && activeItem === "traffic") {
        return [match.date, match.traffic];
      }

      if (matchCost && activeItem === "cost") {
        return [matchCost.date, matchCost.cost];
      }

      return item;
    });

    setTrafficData([...mutable]);
  }, [activeItem, initialState, trafficState]);

  const formatting = (number, bool) => {
    const formatted = Math.abs(number / 100);
    if (bool) {
      return Numeral(number).format("0,0.00");
    }
    return Numeral(formatted).format("$0,0.00");
  };

  return (
    <div className="col-md-7">
      <Row
        className="shadow-widget m-0 justify-content-center"
        style={{ borderRadius: "5px" }}
      >
        <Col sm="12" className="todays-container p-0">
          <button
            className={`todays-boxes ${
              activeItem === "traffic" ? "active" : ""
            } col-sm-2 d-flex flex-column`}
            onClick={() => setActiveItem("traffic")}
            type="button"
          >
            <p className="titile">Traffic</p>
            <p className="number">{totalweek[0].totalCount}</p>
            <Icon
              name={`${
                totalweek[0].totalCount === 0 ? "" : "long arrow alternate"
                } ${totalweek[0].totalCount < 0 || totalweek[0].totalCount > 0  ? "down" : "up"}`}
              color={`${totalweek[0].totalCount < 0 ? "red" : "green"}`}
            >
              <span
                className="second-value"
                style={{ color: totalweek[0].totalCount === 0 ? "black" : "" }}
              >
                {formatting(totalweek[0].differenceCount, true)}
              </span>
              {totalweek[0].totalCount !== 0 && (
                <span className="second-value">%</span>
              )}
            </Icon>
          </button>
          <button
            className={`todays-boxes col-sm-2 d-flex flex-column ${
              activeItem === "cost" ? "active" : ""
            }`}
            type="button"
            onClick={() => setActiveItem("cost")}
          >
            <p className="titile">Cost</p>
            <p className="number">{formatting(totalweek[0].totalCost)}</p>
            <Icon
              name={`${
                totalweek[0].totalCost === 0 ? "" : "long arrow alternate"
              } ${totalweek[0].totalCost < 0 ? "down" : "up"}`}
              color={`${totalweek[0].totalCost < 0 ? "red" : "green"}`}
            >
              <span
                className="second-value"
                style={{ color: totalweek[0].totalCount === 0 ? "black" : "" }}
              >
                {formatting(totalweek[0].differenceCost, true)}
              </span>
              {totalweek[0].differenceCost !== 0 && (
                <span className="second-value">%</span>
              )}
            </Icon>
          </button>
        </Col>
        {activeItem === "traffic" ? (
          <Chart
            width="100%"
            height="500px"
            chartType="LineChart"
            data={trafficData}
            rows={[[0, 0, 0, 0, 0, 0]]}
            columns={[[0, 0, 0, 0, 0, 0]]}
            options={{
              series: {
                0: { curveType: "function" }
              },
              legend: "none",
              fontName:
                "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji",
              lineWidth: 3,
              chartArea: { width: "90%", height: "90%" },
              hAxis: {
                textStyle: {
                  color: "#333",
                  fontSize: "17",
                  fontWeight: "200"
                }
              }
            }}
            rootProps={{ "data-testid": "2" }}
          />
        ) : (
          <Chart
            width="100%"
            height="500px"
            chartType="LineChart"
            data={trafficData}
            rows={[[0, 0, 0, 0, 0, 0]]}
            columns={[[0, 0, 0, 0, 0, 0]]}
            options={{
              series: {
                0: { curveType: "function" }
              },
              legend: "none",
              fontName:
                "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji",
              lineWidth: 3,
              chartArea: { width: "90%", height: "90%" },
              hAxis: {
                textStyle: {
                  color: "#333",
                  fontSize: "17",
                  fontWeight: "200"
                }
              }
            }}
            rootProps={{ "data-testid": "2" }}
          />
        )}
        <div className="chart-footer text-center pb-2">
          <p>Your Traffic for Last 7 Days</p>
        </div>
      </Row>
    </div>
  );
};

export default LineChart;
