import React, { useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import InnerPagesComponent from "../../components/InnerPagesComponent/InnerPagesComponent";
import { getAccountData } from "../../store/actions/AccountDetailsActions";
import AccountDetailsComponent from "../../components/AccountDetailsComponent/AccountDetailsComponent";
import Loader from "../../shared/Loader";
import { onProfileInfo } from "../../store/actions/LoginAction";

const AccountDetailsContainer = ({
  getAccount,
  accountData,
  optionsData: {
    options: { logo },
    provider
  },
  dashboardReducer: {
    optionsData: { displayName }
  },
  getProfileInfo,
  profileInfoData
}) => {
  useEffect(() => {
    return () => {
      getAccount({ provider });
    };
  }, [getAccount]);

  return (
    <Fragment>
      <InnerPagesComponent pageName="profile details" logo={logo}>
        {accountData.pending && <Loader />}
        {accountData.fetched && (
          <Fragment>
            <Helmet>
              <title>Account Details | {displayName} Control Panel</title>
            </Helmet>
            <AccountDetailsComponent
              provider={provider}
              AccountData={accountData}
              onProfileInfo={getProfileInfo}
              ProfileInfoData={profileInfoData}
            />
          </Fragment>
        )}
      </InnerPagesComponent>
    </Fragment>
  );
};

const mapStateFromProps = store => ({
  optionsData: store.DashboardReducer.optionsData,
  accountData: store.AccountReducer.AccountData,
  profileInfoData: store.loginReducer.ProfileInfoData,
  dashboardReducer: store.DashboardReducer
});

const mapDispatchFromProps = dispatch => ({
  getAccount: () => dispatch(getAccountData()),
  getProfileInfo: body => dispatch(onProfileInfo(body))
});

export default withRouter(
  connect(
    mapStateFromProps,
    mapDispatchFromProps
  )(AccountDetailsContainer)
);
