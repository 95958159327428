import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import { includes } from "lodash";
import InnerPagesComponent from "../../components/InnerPagesComponent/InnerPagesComponent";
import { resetReducer } from "../../store/actions/resetReducerAction";
import AppsComponent from "../../components/AppsComponent/AppsComponent";
import AdSpacesComponent from "../../components/AdSpacesComponent/AdSpacesComponent";
import CreateAdSpacesComponent from "../../components/CreateAdSpacesComponent/CreateAdSpacesComponent";
import {
  getApps,
  addApps,
  deleteApps,
  editApps,
  getAdSpaces,
  deleteAdSpaces,
  addAdSpaces,
  editAdSpaces
} from "../../store/actions/AppsActions";

const queryString = require("query-string");

class AppsContainer extends Component {
  state = {
    id: null
  };

  componentDidMount() {
    const parsed = queryString.parse(window.location.search);
    const appId = window.location.pathname.split("/")[6];
    if (parsed.id) {
      this.setState({ id: parsed.id, appId });
    }
  }

  render() {
    const {
      deleteAddSpacesData,
      deleteAdSpaces,
      getAdSpaces,
      adSpacesData,
      addAdSpaces,
      editAdSpaces,
      getApps,
      addApps,
      deleteApps,
      appsData,
      addAppData,
      editApps,
      optionsData: {
        displayName,
        options: { logo },
        provider
      },
      optionsData
    } = this.props;
    const { id } = this.state;
    if (
      deleteAddSpacesData.fetched &&
      (includes(window.location.pathname, "create") ||
        includes(window.location.pathname, "edit"))
    ) {
      return (
        <Redirect
          to={`/control/publisher/apps/adspaces/${
            window.location.pathname.split("/")[6]
          }`}
        />
      );
    }
    if (id && !adSpacesData.fetched) {
      document.location.reload(true);
      return <Redirect to="/control/publisher/apps" />;
    }
    return (
      <div>
        <Helmet>
          <title>App / Website | {displayName} Control Panel</title>
        </Helmet>
        {includes(window.location.pathname, "adspaces") &&
        !includes(window.location.pathname, "create") &&
        !includes(window.location.pathname, "edit") ? (
          <InnerPagesComponent pageName="" logo={logo}>
            <AdSpacesComponent
              getAdSpaces={getAdSpaces}
              optionsData={optionsData}
              id={window.location.pathname.split("/")[5]}
              adSpacesData={adSpacesData}
              deleteAdSpaces={deleteAdSpaces}
              deleteAddSpacesData={deleteAddSpacesData}
            />
          </InnerPagesComponent>
        ) : includes(window.location.pathname, "create") ||
          includes(window.location.pathname, "edit") ? (
          <InnerPagesComponent pageName="" logo={logo}>
            <CreateAdSpacesComponent
              id={window.location.pathname.split("/")[6]}
              addAdSpaces={addAdSpaces}
              deleteAddSpacesData={deleteAddSpacesData}
              adSpacesData={adSpacesData}
              editAdSpaces={editAdSpaces}
              optionsData={optionsData}
            />
          </InnerPagesComponent>
        ) : (
          <InnerPagesComponent pageName="" logo={logo}>
            <AppsComponent
              getApps={getApps}
              optionsData={optionsData}
              appsData={appsData}
              addApps={addApps}
              addAppData={addAppData}
              deleteApps={deleteApps}
              editApps={editApps}
              provider={provider}
            />
          </InnerPagesComponent>
        )}
      </div>
    );
  }
}

const mapStateFromProps = store => ({
  optionsData: store.DashboardReducer.optionsData,
  appsData: store.AppsReducer.appsData,
  addAppData: store.AppsReducer.addAppData,
  adSpacesData: store.AppsReducer.adSpacesData,
  deleteAddSpacesData: store.AppsReducer.deleteAddSpacesData
});

const mapDispatchFromProps = dispatch => ({
  resetReducer: name => dispatch(resetReducer(name)),
  getApps: data => dispatch(getApps(data)),
  addApps: data => dispatch(addApps(data)),
  deleteApps: id => dispatch(deleteApps(id)),
  editApps: data => dispatch(editApps(data)),
  getAdSpaces: data => dispatch(getAdSpaces(data)),
  deleteAdSpaces: id => dispatch(deleteAdSpaces(id)),
  addAdSpaces: data => dispatch(addAdSpaces(data)),
  editAdSpaces: (id, data) => dispatch(editAdSpaces(id, data))
});

export default withRouter(
  connect(
    mapStateFromProps,
    mapDispatchFromProps
  )(AppsContainer)
);
