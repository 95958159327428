import { GET_REFERRALS,GET_REFERRALS_LAST} from '../constants';

const defaultState = {
    referralsData: {
        pending: false,
        fetched: false,
        error: null,
        data: null,
        LastEvaluatedKey: null
    }
}
const ReferralsReducer = (state = defaultState, action) => {
    const { type, payload, error } = action;
    switch (type) {
        case GET_REFERRALS.PENDING: {
            return {
                ...state,
                referralsData: {
                    pending: true,
                    fetched: false,
                    error: null,
                    data: null
                }
            }
        }
        case GET_REFERRALS.SUCCESS: {
            return {
                ...state,
                referralsData: {
                    pending: false,
                    fetched: true,
                    error: null,
                    data: payload.Items,
                    LastEvaluatedKey: payload.LastEvaluatedKey
                }
            }
        }
        case GET_REFERRALS_LAST.SUCCESS: {
            return {
                ...state,
                referralsData: {
                    pending: false,
                    fetched: true,
                    error: null,
                    data: [...state.referralsData.data,...payload.Items],
                    LastEvaluatedKey: payload.LastEvaluatedKey
                }
            }
        }
        case GET_REFERRALS.ERROR: {
            return {
                ...state,
                referralsData: {
                    pending: false,
                    fetched: false,
                    error: error,
                    data: null
                }
            }
        }
        default: return state;
    }
}

export default ReferralsReducer;