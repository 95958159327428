import {
  GET_APPS,
  ADD_APPS,
  GET_ADSPACES,
  DELETE_ADSPACES,
  EDIT_APPS,
  DELETE_APPS
} from "../constants";

const defaultState = {
  appsData: {
    pending: false,
    fetched: false,
    error: null,
    data: null,
    LastEvaluatedKey: null,
    edited: false
  },
  addAppData: {
    pending: false,
    fetched: false,
    error: null
  },
  adSpacesData: {
    pending: false,
    fetched: false,
    error: null,
    data: null,
    LastEvaluatedKey: null
  },
  deleteAddSpacesData: {
    pending: false,
    fetched: false,
    error: null
  }
};
const AppsReducer = (state = defaultState, action) => {
  const { type, payload, error } = action;
  switch (type) {
    case GET_APPS.PENDING: {
      return {
        ...state,
        appsData: {
          pending: true,
          fetched: false,
          error: null,
          data: null
        },
        addAppData: {
          ...state.addAppData,
          fetched: false
        }
      };
    }
    case GET_APPS.SUCCESS: {
      return {
        ...state,
        appsData: {
          pending: false,
          fetched: true,
          error: null,
          data: payload.data
        }
      };
    }
    case GET_APPS.ERROR: {
      return {
        ...state,
        appsData: {
          pending: false,
          fetched: false,
          error,
          data: null
        }
      };
    }
    case ADD_APPS.PENDING: {
      return {
        ...state,
        addAppData: {
          pending: true,
          fetched: false,
          error: null
        }
      };
    }
    case ADD_APPS.SUCCESS: {
      return {
        ...state,
        appsData: {
          ...state.appsData,
          // data: [...state.appsData.data, payload],
          pending: false,
          fetched: true
        },
        addAppData: {
          pending: false,
          fetched: true,
          error: null
        }
      };
    }
    case DELETE_APPS.PENDING:
      return {
        ...state,
        appsData: {
          ...state.appsData,
          pending: true
        }
      };
    case DELETE_APPS.SUCCESS:
      return {
        ...state,
        appsData: {
          ...state.appsData,
          pending: false,
          fetched: true,
          data: state.appsData.data.filter(it => it.app_id !== payload)
        }
      };
    case EDIT_APPS.SUCCESS:
      return {
        ...state,
        appsData: {
          ...state.appsData,
          pending: false,
          fetched: true,
          data: state.appsData.data.map(it =>
            it.app_id === payload.Attributes.app_id ? payload.Attributes : it
          ),
          edited: true
        }
      };
    case EDIT_APPS.PENDING:
      return {
        ...state,
        appsData: {
          ...state.appsData,
          pending: true,
          edited: false
        }
      };
    case ADD_APPS.ERROR: {
      return {
        ...state,
        addAppData: {
          pending: false,
          fetched: false,
          error
        }
      };
    }
    case GET_ADSPACES.PENDING: {
      return {
        ...state,
        adSpacesData: {
          pending: true,
          fetched: false,
          error: null,
          data: null
        }
      };
    }
    case GET_ADSPACES.SUCCESS: {
      return {
        ...state,
        adSpacesData: {
          pending: false,
          fetched: true,
          error: null,
          data: payload.Items,
          LastEvaluatedKey: payload.LastEvaluatedKey
        }
      };
    }
    case GET_ADSPACES.ERROR: {
      return {
        ...state,
        adSpacesData: {
          pending: false,
          fetched: false,
          error,
          data: null
        }
      };
    }
    case DELETE_ADSPACES.PENDING: {
      return {
        ...state,
        deleteAddSpacesData: {
          pending: true,
          fetched: false,
          error: null
        }
      };
    }
    case DELETE_ADSPACES.SUCCESS: {
      return {
        ...state,
        adSpacesData: {
          ...state.appsData,
          fetched: false
        },
        deleteAddSpacesData: {
          pending: false,
          fetched: true,
          error: null
        }
      };
    }
    case DELETE_ADSPACES.ERROR: {
      return {
        ...state,
        deleteAddSpacesData: {
          pending: false,
          fetched: false,
          error
        }
      };
    }
    case "RESET_APPS_REDUCER": {
      return {
        ...state,
        adSpacesData: {
          pending: false,
          fetched: false,
          error: null,
          data: null,
          LastEvaluatedKey: null
        }
      };
    }
    default:
      return state;
  }
};

export default AppsReducer;
