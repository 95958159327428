import {
  GET_COMPAIGNS,
  CHANGE_STATUS_COMPAIGNS,
  ADD_COMPAIGNS,
  DELETE_COMPAIGNS,
  RESET_CAMPAIGNS_DATA_REDUCER,
  EDIT_CAMPAIGN_BY_ID
} from "../constants";

const defaultState = {
  compaignsData: {
    pending: false,
    fetched: false,
    error: null,
    data: [],
    LastEvaluatedKey: null
  },
  compaignsStatus: {
    pending: false,
    fetched: false,
    error: null
  },
  editCompaign: { pending: false, sucess: false },
  addCompaigns: {
    pending: false,
    fetched: false,
    error: null
  },
  deleteCompaigns: {
    pending: false,
    fetched: false,
    error: null
  }
};

// const normalizeCampaignData = data => {
//   return data.map(it => ({
//     title: it.title,
//     type: it.platform,
//     adType: it.adType,
//     maxBid: it.maxBid,
//     dailyBudget: it.dailyBudget ? `${Number(it.dailyBudget)}$` : '0.00$',
//     totalBudget: it.budget,
//     status: it.status,
//     id: it.id,
//   }));
// };

const CompaignsReducer = (state = defaultState, action) => {
  const { type, payload, error } = action;
  switch (type) {
    case EDIT_CAMPAIGN_BY_ID.PENDING: {
      return {
        ...state,
        editCompaign: { pending: true, sucess: false }
      };
    }
    case EDIT_CAMPAIGN_BY_ID.SUCCESS: {
      return {
        ...state,
        editCompaign: { success: true, pending: false, ...payload }
      };
    }

    case GET_COMPAIGNS.PENDING: {
      return {
        ...state,
        compaignsData: {
          pending: true,
          fetched: false,
          error: null,
          data: null
        }
      };
    }
    case GET_COMPAIGNS.SUCCESS: {
      return {
        ...state,
        compaignsData: {
          pending: false,
          fetched: true,
          error: null,
          data: payload.Items,
          LastEvaluatedKey: payload.LastEvaluatedKey
        }
      };
    }
    // case GET_COMPAIGNS_LAST.SUCCESS:{
    //     return {
    //         ...state,
    //         compaignsData: {
    //             pending: false,
    //             fetched: true,
    //             error: null,
    //             data: [...state.reportsData.data,...payload.Items],
    //             LastEvaluatedKey:payload.LastEvaluatedKey
    //         }
    //     }
    // }
    case GET_COMPAIGNS.ERROR: {
      return {
        ...state,
        compaignsData: {
          pending: false,
          fetched: false,
          error,
          data: null
        }
      };
    }
    case CHANGE_STATUS_COMPAIGNS.PENDING: {
      return {
        ...state,
        compaignsStatus: {
          pending: true,
          fetched: false,
          error: null
        }
      };
    }
    case CHANGE_STATUS_COMPAIGNS.SUCCESS: {
      return {
        ...state,
        // compaignsData: {
        //   ...state.compaignsData
        // },
        compaignsStatus: {
          pending: false,
          fetched: true,
          error: null,
          fetchedItem: { ...payload.Attributes }
        }
      };
    }
    case CHANGE_STATUS_COMPAIGNS.ERROR: {
      return {
        ...state,
        compaignsStatus: {
          pending: false,
          fetched: false,
          error
        }
      };
    }
    case ADD_COMPAIGNS.PENDING: {
      return {
        ...state,
        addCompaigns: {
          pending: true,
          fetched: false,
          error: null
        }
      };
    }
    case ADD_COMPAIGNS.SUCCESS: {
      return {
        ...state,
        addCompaigns: {
          pending: false,
          fetched: true,
          error: null
        }
      };
    }
    case ADD_COMPAIGNS.ERROR: {
      return {
        ...state,
        addCompaigns: {
          pending: false,
          fetched: false,
          error
        }
      };
    }
    case DELETE_COMPAIGNS.PENDING: {
      return {
        ...state,
        deleteCompaigns: {
          pending: true,
          fetched: false,
          error: null,
          id: null
        }
      };
    }
    case DELETE_COMPAIGNS.SUCCESS: {
      return {
        ...state,
        compaignsData: {
          ...state.compaignsData,
          fetched: false
        },
        deleteCompaigns: {
          pending: false,
          fetched: true,
          error: null,
          id: payload.campaign_id
        }
      };
    }
    case DELETE_COMPAIGNS.ERROR: {
      return {
        ...state,
        deleteCompaigns: {
          pending: false,
          fetched: false,
          error
        }
      };
    }
    case "RESET_COMPAIGNS_REDUCER": {
      return {
        ...state,
        compaignsData: {
          pending: false,
          fetched: false,
          error: null,
          data: null
        },
        compaignsStatus: {
          pending: false,
          fetched: false,
          error: null
        },
        addCompaigns: {
          pending: false,
          fetched: false,
          error: null
        },
        deleteCompaigns: {
          pending: false,
          fetched: false,
          error: null
        }
      };
    }
    case RESET_CAMPAIGNS_DATA_REDUCER:
      return {
        ...defaultState
      };
    default:
      return state;
  }
};

export default CompaignsReducer;
