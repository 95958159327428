import React, { Component } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import Loader from "../../../shared/Loader/index";
import "./Table.scss";

class Table extends Component {
  renderTableBody = () => {
    const body = [];
    this.props.referralsData.data.map((item, key) => {
      return body.push(
        <div className="my-table-row" key={key}>
          <div className="my-table-column  border-bottom">
            {moment(item.created_at).format("ll")}
          </div>
          <div className="my-table-column  border-bottom">{item.RefsCount}</div>
          <div className="my-table-column  border-bottom">
            {(item.advertiserCount / 1000).toFixed(2)}$
          </div>
          <div className="my-table-column  border-bottom">
            {(item.publisherCount / 1000).toFixed(2)}$
          </div>
        </div>
      );
    });
    return body;
  };

  onScrollTop = () => {
    const div = document.getElementById("scroledDiv");
    const bottom = div.scrollHeight - div.scrollTop;
    if (bottom === 500 && this.props.referralsData.LastEvaluatedKey) {
      this.props.getReferralsLast({
        filter: {
          userType: ["referral"]
        },
        type: ["date"],
        LastEvaluatedKey: this.props.referralsData.LastEvaluatedKey,
        provider: this.props.provider
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="referrals shadow-widget table-widget">
          <div className="my-table pt-0 pb-0 pl-3 pr-3">
            <div className="my-table-header">
              <div className="my-table-row border-bottom">
                <div className="my-table-column">Date</div>
                <div className="my-table-column">New Registered</div>
                <div className="my-table-column">Advertiser Profit</div>
                <div className="my-table-column">Publisher Profit</div>
              </div>
            </div>
            <div
              className="my-table-body"
              id="scroledDiv"
              onScroll={() => this.onScrollTop()}
            >
              {this.props.referralsData.data && this.renderTableBody()}
            </div>
            {this.props.referralsData.pending && (
              <div style={{ textAlign: "center", margin: "10px" }}>
                <Loader />
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Table;
