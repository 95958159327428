import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Col, Row, Container } from "reactstrap";
import { Header } from "semantic-ui-react";
import { Helmet } from "react-helmet";
import RegistrationSecondComponent from "../../components/Auth/RegistrationSecondComponent/RegistrationSecondComponent";
import { onProfileInfo, getUserInfo } from "../../store/actions/LoginAction";
import "../LoginContainer/LoginContainer.scss";

const RegistrationSecondContainer = ({
  ProfileInfoData,
  dashboardReducer: {
    optionsData: {
      options: { logo },
      displayName
    }
  },
  secondStep,
  loading,
  getUserInfo,
  userInfo
}) => {
  useEffect(() => {
    getUserInfo();
  }, [getUserInfo]);

  const customerType = localStorage.getItem("customerType");

  if (ProfileInfoData.success) {
    if (customerType === "both") {
      return <Redirect to="/control/advertiser/dashboard" />;
    }
    return <Redirect to={`/control/${customerType}/dashboard`} />;
  }

  return (
    <Container fluid className="p-0">
      <Helmet>
        <title>Registration Second | {displayName} Control Panel</title>
      </Helmet>
      <Row className="w-100 m-0">
        <RegistrationSecondComponent
          logo={logo}
          secondStep={secondStep}
          loading={loading}
          getUserInfo={getUserInfo}
          userInfo={userInfo}
        />
        <Col sm="6" className="loginBack" />
        <Col
          className="left-side-container"
          sm="6"
          style={{ position: "absolute", left: "50%", top: "40%" }}
        >
          <Col sm="12" className="d-flex justify-content-center">
            <Header
              textAlign="center"
              size="huge"
              style={{
                color: "white",
                fontSize: "1.7rem",
                fontStyle: "italic"
              }}
              className="col-sm-6"
            >
              &ldquo;{" "}
              <span>
                The best advertising should make you{" "}
                <span className="marked-word">nervous</span> about what
                you&apos;re buying ... There is too much smart-ass advertising
                today and not enough that{" "}
                <span className="marked-word">emotionally moves</span> consumers
                to go out and buy something
              </span>{" "}
              &ldquo;
              <br />
              <span className="d-block text-right mt-3">
                Marry Wells Lawrence
              </span>
            </Header>
          </Col>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateFromProps = store => ({
  user: store.loginReducer.user,
  dashboardReducer: store.DashboardReducer,
  ProfileInfoData: store.loginReducer.ProfileInfoData,
  firstTime: store.loginReducer.firstTime,
  profileInfo: store.loginReducer.ProfileInfoData.data,
  loading: store.loginReducer.ProfileInfoData.pending,
  userInfo: store.loginReducer.userInfo.data
});

const mapDispatchFromProps = dispatch => ({
  secondStep: body => dispatch(onProfileInfo(body)),
  // firstLogin: () => dispatch(secondStepAction()),
  getUserInfo: () => dispatch(getUserInfo())
});

export default connect(
  mapStateFromProps,
  mapDispatchFromProps
)(RegistrationSecondContainer);
