import Countries from "./countries.json";

const getCountries = () => {
  return Countries.countries.map(it => {
    const item = {};
    item.text = it.name;
    item.key = it.name + it.code;
    item.value = it.code;
    return item;
  });
};

export default getCountries;
