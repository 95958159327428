import React from "react";
import { connect } from "react-redux";
import AWS from "aws-sdk";
import { Helmet } from "react-helmet";
import { ENV } from "../../environment";
import EditCompaignsComponent from "../../components/EditCompaignsComponent/EditCompaignsComponent";
import {
  getCompaigns as getData,
  editCompaings as editData,
  resetCampaignsDataReducer as resetData,
  getCompaignById as getDataById
} from "../../store/actions/CompaignsActions";
import InnerPagesComponent from "../../components/InnerPagesComponent/InnerPagesComponent";

const CompaignsEditContainer = ({
  getCompaigns,
  resetCampaignsData,
  getCompaignById,
  editCompaings,
  optionsData,
  optionsData: {
    options: { logo },
    displayName,
    provider
  },
  compaignsData,
  addCompaignsData,
  editCompaign
}) => {
  const onCompainEdit = (file, id, fileName, name) => {
    AWS.config.update({
      accessKeyId: ENV.AWS_accessKey,
      secretAccessKey: ENV.AWS_secretKey,
      region: ENV.REGION
    });
    const s3 = new AWS.S3({ signatureVersion: "v4" });
    const params = {
      Bucket: "adlooker-creative",
      Key: `${fileName}/${id}/${name}`,
      Body: file,
      "x-amz-acl": "public-read",
      ContentType: file.type
    };
    const upload = s3.upload(params, {
      partSize: file.size > 5242880 ? file.size : 5242880,
      "x-amz-acl": "public-read",
      queueSize: 1
    });
    upload.on("httpUploadProgress", progress => {
      console.log(progress);
    });
    return upload.promise();
  };

  return (
    <InnerPagesComponent pageName="CAMPAIGNS | EDIT CAMPAIGN" logo={logo}>
      <Helmet>
        <title>Campaigns | {displayName} Control Panel</title>
      </Helmet>
      <EditCompaignsComponent
        getCompaigns={getCompaigns}
        optionsData={optionsData}
        compaignsData={compaignsData}
        addCompaignsData={addCompaignsData}
        id={window.location.pathname.split("/")[5]}
        editCompaings={editCompaings}
        onCompainCreate={onCompainEdit}
        getCompaignById={getCompaignById}
        editCompaign={editCompaign}
        resetCampaignsData={resetCampaignsData}
        provider={provider}
      />
    </InnerPagesComponent>
  );
};

const mapStateToProps = store => ({
  optionsData: store.DashboardReducer.optionsData,
  compaignsData: store.CompaignsReducer.compaignsData,
  addCompaignsData: store.CompaignsReducer.addCompaigns,
  editCompaign: store.CompaignsReducer.editCompaign
});

const mapDispatchToProps = dispatch => ({
  getCompaigns: data => dispatch(getData(data)),
  editCompaings: (id, data) => dispatch(editData(id, data)),
  resetCampaignsData: () => dispatch(resetData()),
  getCompaignById: data => dispatch(getDataById(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompaignsEditContainer);
