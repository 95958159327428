import React from "react";
import { Form } from "semantic-ui-react";
import { isEqual } from "lodash";
import getCountries from "../../../config/useCountries";
import "./RegistrationComponent.scss";

const SelectCountries = ({ handleChange }) => {
  return (
    <Form.Select
      label="Select Country"
      options={getCountries()}
      placeholder="Country"
      className="reg-select"
      onChange={(e, { value }) => handleChange("locale", value)}
    />
  );
};

const shouldUpdate = (prevProps, nextProps) => {
  return !isEqual(prevProps, nextProps);
};
export default React.memo(SelectCountries, shouldUpdate);
