import React, { Component } from "react";
import { Button } from "semantic-ui-react";
import Loader from "../../shared/Loader/index";
import App from "./App/App";
import CreateApp from "./CreateApp/CreateApp";
import "./AppsComponent.scss";

class AppsComponent extends Component {
  state = { modal: false };

  componentDidMount() {
    const {
      getApps,
      optionsData: { provider }
    } = this.props;
    getApps({ provider });
  }

  componentDidUpdate() {
    const {
      addAppData: { fetched },
      getApps,
      optionsData: { provider }
    } = this.props;
    if (fetched) {
      getApps({ provider });
    }
  }

  onEdit = data => {
    const { editApps } = this.props;
    editApps({ ...data, provider: this.props.provider});
  };

  toggle = () =>
    this.setState(prevState => ({ ...prevState, modal: !prevState.modal }));

  render() {
    const {
      optionsData: { provider },
      appsData,
      addAppData,
      deleteApps,
      addApps
    } = this.props;
    const { modal } = this.state;

    return (
      <React.Fragment>
        <CreateApp
          handleSubmit={addApps}
          open={modal}
          toggle={this.toggle}
          provider={provider}
        />
        {appsData.pending && <Loader />}
        {addAppData.pending && <Loader />}
        <div
          className="content-header clearfix d-flex"
          style={{ borderBottom: "1px solid lightgray", marginBottom: "20px" }}
        >
          <h1>App / Website</h1>
          <Button
            color="green"
            className="ml-auto"
            onClick={this.toggle}
            type="button"
          >
            Add New
          </Button>
        </div>
        <div className="row p-3">
          {appsData.fetched &&
            appsData.data.length > 0 &&
            appsData.data.map(app => {
              return (
                <App
                  key={app.app_id}
                  data={app}
                  onDeleteModal={deleteApps}
                  handleSave={this.onEdit}
                  provider={provider}
                />
              );
            })}
        </div>
      </React.Fragment>
    );
  }
}

export default AppsComponent;
