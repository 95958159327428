import React from "react";
import LineChart from "./LineChart/LineChart";
import GeoChart from "./GeoChart/GeoChart";
import Loader from "../../shared/Loader";
import AccountManager from "./AccountManager/AccountManager";

const DashboardComponent = ({
  dashboardData,
  dashboardData: { pending, fetched }
}) => {
  return (
    <React.Fragment>
      {pending && <Loader />}
      {fetched && (
        <div className="container-fluid">
          <div className="row">
            <LineChart dashboardData={dashboardData} />
            <GeoChart dashboardData={dashboardData} />
            {/* <AccountManager /> */}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default DashboardComponent;
