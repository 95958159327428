import React, { Component } from 'react';
import { connect } from 'react-redux';
import {LoginAction, LogoutAction} from '../../../store/actions/LoginAction';
import './HeaderComponent.scss';
import {Redirect} from "react-router-dom";
import { Link} from "react-router-dom";

class HeaderComponent extends Component {
  state = {

  };

  render() {
    const self = this;
    return (
      <header className="inner-header">
        {self.props.logout && <Redirect to='/'/>}
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 clearfix">
              <a href="" className="logo">
                <img src={this.props.logo} />
              </a>
              <a onClick={this.props.logoutfunction} className="logout">
                <i className="fa fa-power-off"></i>
              </a>
              <Link to='/control/account-details' className="settings">
                <i className="fa fa-cog"></i>
              </Link>
              <div className="balance-box eyeh">
                <div className="balance-box-element">
                  <span className="block">Balance</span>
                  <span className="h6" id="balance">{this.props.financeData!==null ? (this.props.financeData.balance/100).toFixed(2) : ""}$</span>
                </div>
              </div>
          </div>
        </div>
      </div>
    </header>
    );
  }
}

const mapStateFromProps = store => ({
  logout: store.loginReducer.logout
});

const mapDispatchFromProps = dispatch => ({
  logoutfunction: () => dispatch(LogoutAction()),
});

export default connect(mapStateFromProps, mapDispatchFromProps)(HeaderComponent);
