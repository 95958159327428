import { GET_ACCOUNT_DATA } from '../constants';

const defaultState = {
    AccountData: {
        pending: false,
        fetched: false,
        error: null,
        data: null
    }
}
const AccountReducer = (state = defaultState, action) => {
    const { type, payload, error } = action;
    switch (type) {
        case  GET_ACCOUNT_DATA.PENDING: {
            return {
                ...state,
                AccountData: {
                    pending: true,
                    fetched: false,
                    error: null,
                    data: null
                }
            }
        }
        case  GET_ACCOUNT_DATA.SUCCESS: {
            return {
                ...state,
                AccountData: {
                    pending: false,
                    fetched: true,
                    error: null,
                    data: payload
                }
            }
        }
        case  GET_ACCOUNT_DATA.ERROR: {
            return {
                ...state,
                AccountData: {
                    pending: false,
                    fetched: false,
                    error: error,
                    data: null
                }
            }
        }
        default: return state;
    }
}

export default AccountReducer;
