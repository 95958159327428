import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";
import InnerPagesComponent from "../../components/InnerPagesComponent/InnerPagesComponent";
import BillingComponent from "../../components/BillingComponent/BillingComponent";
import { getBillingInfo } from "../../store/actions/BillingActions";

class BillingContainer extends Component {
  componentDidMount() {
    const { getBillingInfo } = this.props;
    getBillingInfo({});
  }

  render() {
    const {
      optionsData: {
        options: { logo },
        displayName
      },
      BillingInfoData
    } = this.props;
    return (
      <Fragment>
        <Helmet>
          <title>Billing | {displayName} Control Panel</title>
        </Helmet>
        <InnerPagesComponent pageName="" logo={logo}>
          <BillingComponent BillingInfoData={BillingInfoData} />
        </InnerPagesComponent>
      </Fragment>
    );
  }
}

const mapStateFromProps = store => ({
  optionsData: store.DashboardReducer.optionsData,
  BillingInfoData: store.BillingInfoReducer.BillingInfoData
});

const mapDispatchFromProps = dispatch => ({
  getBillingInfo: data => dispatch(getBillingInfo(data))
});

export default withRouter(
  connect(
    mapStateFromProps,
    mapDispatchFromProps
  )(BillingContainer)
);
