import Login from "./containers/LoginContainer/LoginContainer";
import RegistrationContainer from "./containers/RegistrationContainer/RegistrationContainer";
import ForgotPasswordContainer from "./containers/ForgotPasswordContainer/ForgotPasswordContainer";
import VerifyEmailContainer from "./containers/VerifyEmailContainer/VerifyEmailContainer";
import RegistrationSecondContainer from "./containers/RegistrationSecondContainer/RegisrationSecondContainer";
import PublisherDashboardContainer from "./containers/PublisherDashboardContainer/PublisherDashboardContainer";
import AdvertiserDashboardContainer from "./containers/AdvertiserDashboardContainer/AdvertiserDashboardContainer";
import AccountDetailsContainer from "./containers/AccountDetailsContainer/AccountDetailsContainer";
import PublisherReportingsContainer from "./containers/PublisherReportingsContainer/PublisherReportingsContainer";
import AdvertiserReportingsContainer from "./containers/AdvertiserReportingsContainer/AdvertiserReportingContainer";
import CompaignsContainer from "./containers/CompaignsContainer/CompaignsContainer";
import AppsContainer from "./containers/AppsContainer/AppsContainer";
import ReferralsContainer from "./containers/ReferralsContainer/ReferralsContainer";
import BillingContainer from "./containers/BillingContainer/BillingContainer";
import FAQContainer from "./containers/FAQContainer/FAQContainer";
import LoadMoneyContainer from "./containers/LoadMoneyContainer/LoadMoneyContainer";
import WithdrawContainer from "./containers/WithdrawConatiner/WithdrawContainer";
import CompaignsCreateContainer from "./containers/CompaignsContainer/CompaignsCreateContainer";
import CompaignsEditContainer from "./containers/CompaignsContainer/CompaignsEditContainer";
import VerifyPasswordComponent from './components/Auth/ForgotPasswordComponent/VerifypasswordComponent';

export const Routes = [
  {
    isNavBar: true,
    isExact: true,
    path: "/",
    name: "login",
    component: Login
  },
  {
    isNavBar: true,
    isExact: true,
    path: "/registration",
    name: "registration",
    component: RegistrationContainer
  },
  {
    isNavBar: true,
    path: "/forgot-password",
    name: "forgot-password",
    component: ForgotPasswordContainer
  },
  {
    isNavBar: true,
    path: "/verify-password",
    name: "verify-password",
    component: VerifyPasswordComponent
  },
  {
    isNavBar: true,
    path: "/verify-email",
    name: "verify-email",
    component: VerifyEmailContainer
  },
  {
    isNavBar: true,
    path: "/registration-second",
    name: "registration-second",
    component: RegistrationSecondContainer,
    isPrivate: true
  },
  {
    isNavBar: true,
    path: `/control/publisher/dashboard`,
    name: "publisher-dashboard",
    component: PublisherDashboardContainer,
    isPrivate: true
  },
  {
    isNavBar: true,
    path: `/control/advertiser/dashboard`,
    name: "advertiser-dashboard",
    component: AdvertiserDashboardContainer,
    isPrivate: true
  },
  {
    isNavBar: true,
    path: `/control/account-details`,
    name: "details",
    component: AccountDetailsContainer,
    isPrivate: true
  },
  {
    isNavBar: true,
    path: `/control/publisher/statistics`,
    name: "publisher-statistics",
    component: PublisherReportingsContainer,
    isPrivate: true
  },
  {
    isNavBar: true,
    path: `/control/advertiser/statistics`,
    name: "advertiser-statistics",
    component: AdvertiserReportingsContainer,
    isPrivate: true
  },
  {
    isNavBar: true,
    path: `/control/advertiser/campaigns/`,
    name: "campaigns",
    component: CompaignsContainer,
    isPrivate: true,
    isExact: true
  },
  {
    isNavBar: true,
    path: `/control/advertiser/campaigns/create`,
    name: "create",
    component: CompaignsCreateContainer,
    isPrivate: true,
  },
  {
    isNavBar: true,
    path: `/control/advertiser/campaigns/edit`,
    name: "create",
    component: CompaignsEditContainer,
    isPrivate: true,
  },
  {
    isNavBar: true,
    path: `/control/publisher/apps`,
    name: "apps",
    component: AppsContainer,
    isPrivate: true
  },
  {
    isNavBar: true,
    path: `/control/referrals`,
    name: "referrals",
    component: ReferralsContainer,
    isPrivate: true
  },
  {
    isNavBar: true,
    path: `/control/billing`,
    name: "billing",
    component: BillingContainer,
    isPrivate: true
  },
  {
    isNavBar: true,
    path: `/control/faq`,
    name: "faq",
    component: FAQContainer,
    isPrivate: true
  },
  {
    isNavBar: true,
    path: `/control/loadmoney`,
    name: "load money",
    component: LoadMoneyContainer,
    isPrivate: true
  },
  {
    isNavBar: true,
    path: `/control/withdraw`,
    name: "withdraw",
    component: WithdrawContainer,
    isPrivate: true
  }
];

export default Routes;