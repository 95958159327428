import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Row, Container } from "reactstrap";
import { Helmet } from "react-helmet";
import queryString from "query-string";
import Loader from "../../shared/Loader/index";
import "./VerifyEmailContainer.scss";
import { onVerifyEmail } from "../../store/actions/LoginAction";
import "../LoginContainer/LoginContainer.scss";

const VerifyEmailContainer = ({
  emailVerify,
  DashboardReducer: {
    optionsData: {
      options: { logo },
      provider,
      displayName
    }
  },
  verifyEmailData
}) => {
  useEffect(() => {
    const values = queryString.parse(window.location.search);
    emailVerify({ ...values, provider });
  }, [emailVerify]);

  return (
    <Container fluid className="p-0 verify-email-container">
      <Helmet>
        <title>Verify Email | {displayName} Control Panel</title>
      </Helmet>
      <Row className="w-100 m-0 verify-email-row">
        <Col
          sm="6"
          className="login d-flex flex-column verify-email-col justify-content-center align-items-center"
        >
          {verifyEmailData.pending && (
            <div style={{ textAlign: "center" }}>
              <Loader inline />
            </div>
          )}
          {verifyEmailData.success && (
            <div className="verify-alert">
              <i className="fa fa-check-circle success-icon m-3" />
              <h3 className="sccessH3">
                {` You have successfully registered at platform. Our support team
                will check your profile and verify it within next 48 hours.
                Please don’t hesitate to contact us for further instruction
                about activating your account. Thanks again for joining
                ${displayName} family.`}
              </h3>
              <span>
                Go back to <Link to="/">Login</Link>
              </span>
            </div>
          )}
          {verifyEmailData.error && (
            <div className="verify-alert">
              <i className="fa fa-times-circle error-icon" />
              <h3 className="errorH3">
                You are not authorized to view this page!
              </h3>
              <span>
                Go back to <Link to="/">Login</Link>
              </span>
            </div>
          )}
        </Col>
        <Col sm="6" className="loginBack" />
      </Row>
    </Container>
  );
};

const mapStateFromProps = store => ({
  user: store.loginReducer.user,
  DashboardReducer: store.DashboardReducer,
  verifyEmailData: store.loginReducer.verifyEmailData
});

const mapDispatchFromProps = dispatch => ({
  emailVerify: body => dispatch(onVerifyEmail(body))
});

export default connect(
  mapStateFromProps,
  mapDispatchFromProps
)(VerifyEmailContainer);
