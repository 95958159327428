import React from "react";
import { isEqual } from "lodash";
import { Form } from "semantic-ui-react";
import getCountries from "../../../config/useCountries";

const SelectCountries = ({ field, onChange, id }) => {
  if (field.key === "country") {
    return (
      <Form.Select
        className="col-sm-6 reg-select"
        key={field.key}
        label={field.label}
        placeholder={field.label}
        options={getCountries()}
        onChange={(e, { value }) => onChange(value, id)}
      />
    );
  }
  return (
    <Form.Select
      className="col-sm-6 reg-select"
      key={field.key}
      label={field.label}
      placeholder={field.label}
      options={field.options}
      onChange={(e, { value }) => onChange(value, id)}
    />
  );
};

const deepCompare = (prevProps, nextProps) => !!isEqual(prevProps, nextProps);

export default React.memo(SelectCountries, deepCompare);
