import React, { Component } from 'react';
import { Link } from "react-router-dom";

class CoinSucces extends Component {
    render() {
        return (
            <div className="SuccesPaymentcontainer">
                <div className="paymentInfocontentSucces">
                    <div className="success-text">
                        <h4 style={{ textAlign: "center" }}>Proccess Payment</h4>
                    </div>
                    <div className="coinqrcodediv" style={{backgroundImage:`url(${this.props.CoinData.data.response.transactionId.qrcode_url})`}}>
                    </div>
                    <div className="amountdiv">
                        <p>Amount: ${this.props.amount}</p>
                        <p>Amount: {this.props.CoinData.data.response.transactionId.amount} {this.props.coin}</p>
                    </div>
                    <div className="backbuttondiv">
                        <Link className="btn form-control backbutton" to="/control/billing">&larr; Back To</Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default CoinSucces;
