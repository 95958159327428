import CommonAction from "../../shared/Http/Http";
import {
  GET_APPS,
  ADD_APPS,
  GET_ADSPACES,
  DELETE_ADSPACES,
  EDIT_APPS,
  DELETE_APPS
} from "../constants";

export const getApps = data =>
  CommonAction("POST", null, `control/apps`, GET_APPS, data);
export const addApps = data =>
  CommonAction("POST", null, `control/app`, ADD_APPS, data);
export const deleteApps = id =>
  CommonAction("DELETE", null, `control/app/${id}`, DELETE_APPS, null);
export const editApps = data =>
  CommonAction("PUT", null, `control/app`, EDIT_APPS, data);
export const getAdSpaces = data =>
  CommonAction("POST", null, `control/adspaces`, GET_ADSPACES, data);
export const deleteAdSpaces = id =>
  CommonAction("DELETE", null, `control/adspace/${id}`, DELETE_ADSPACES, null);
export const addAdSpaces = data =>
  CommonAction("POST", null, `control/adspace/create`, DELETE_ADSPACES, data);
export const editAdSpaces = (id, data) =>
  CommonAction("PUT", null, `control/adspace/${id}`, DELETE_ADSPACES, data);
