import React, { Component } from 'react';
import { Link } from "react-router-dom";
import './WithdrawComponent.scss';
import CoinPayments from './CoinPayment/CoinPayment';
import WireTransfer from './WireTransfer/WireTransfer'

class WithdrawComponent extends Component {
    state = {
        step: this.props.withdrawalGateways[0].type,
        amount: 0
    }
    onChangeAmount = (amount) => {
        let money = amount.replace(/\D/g, '')
        this.setState({
            amount: Number(money)
        })
    }
    onStepChange = (step) => {
        this.setState({ step: step })
    }
    renderTabs = () => {
        let tabs = []
        this.props.withdrawalGateways.map((item, key) => {
            if (item.type === "coinPayments" && item.status === "active") {
                tabs.push(
                    <div className="coinPayments" key={key} onClick={() => this.onStepChange("coinPayments")}>
                        <div className="coinPaymentsHeader">
                            <div className="Bitcoin"></div>
                            <div className="BlackCoin"></div>
                            <div className="Dash"></div>
                            <div className="Ether"></div>
                            <div className="Namecoin"></div>
                            <div className="LitecoinTestnet"></div>
                        </div>
                        <div style={{ textAlign: "center", fontWeight: "bold" }}>Crypto Payment</div>
                    </div>
                )
            }
            if (item.type === "wireTransfer" && item.status === "active") {
                tabs.push(
                    <div className="wireTransfer" key={key} onClick={() => this.onStepChange("wireTransfer")}>
                        <div className="wireTransferHeader">
                        </div>
                    </div>
                )
            }
        })
        return tabs
    }
    render() {
      const { provider } = this.props;
        return (
            <div className="row">
                <div className="col-md-8">
                    <div className="shadow-widget">
                        <div className="row">
                            <div className="col-md-7" style={{ borderRight: "1px solid #eaedef" }}>
                                <h5 style={{ paddingTop: "20px" }}>Amount</h5>
                                <div className="amountPart">
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={this.state.amount}
                                            onChange={(e) => this.onChangeAmount(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <h5 style={{ marginTop: "20px" }}>Payment Type</h5>
                                <div className="paymentPart">
                                    {this.renderTabs()}
                                </div>
                                {
                                    this.state.step === "coinPayments" &&
                                    <CoinPayments
                                        getRates={this.props.getRates}
                                        RatesData={this.props.RatesData}
                                        amount={this.state.amount}
                                        withdrawalGateways={this.props.withdrawalGateways}
                                        coinWithDraw={this.props.coinWithDraw}
                                        provider={provider}
                                    />
                                }
                                {
                                    this.state.step === "wireTransfer" &&
                                    <WireTransfer
                                        amount={this.state.amount}
                                        coinWithDraw={this.props.coinWithDraw}
                                        withdrawalGateways={this.props.withdrawalGateways}
                                        provider={provider}
                                    />
                                }
                                <div className="footer-buttons">
                                    <Link className="btn btn-default" to="/control/billing">Cancel</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default WithdrawComponent;
