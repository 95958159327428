import React from "react";
import { PropTypes } from "prop-types";
import "./index.scss";

const Loader = React.memo(({ className = "", inline = false }) => (
  <article className={`${inline ? "inline" : "spiner-box"} ${className}`}>
    <div className={`${inline ? "cssload-loader inline" : "cssload-loader"}`}>
      <div className="cssload-side" />
      <div className="cssload-side" />
      <div className="cssload-side" />
      <div className="cssload-side" />
      <div className="cssload-side" />
      <div className="cssload-side" />
      <div className="cssload-side" />
      <div className="cssload-side" />
    </div>
  </article>
));

export default Loader;

Loader.propTypes = {
  className: PropTypes.string.isRequired
};
