import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import Auth from '../../shared/Auth/Auth';
import * as _ from 'lodash';

const Authorization = (WrappedComponent, isPrivate) => {
  class WithAuthorization extends React.Component {
    static propTypes = {
      isAuthorized: PropTypes.bool
    };

    componentWillMount() {
      this.isAuthorized()
    }

    isAuthorized = () => {
      Auth.currentAuthenticatedUser().then(user => {
      }).catch((err) => {
        localStorage.removeItem('user');
        localStorage.removeItem('userToken');
        return <Redirect to='/' />;
      })
    }

    render() {
      let first_time = false
      let customType
      for (let key in localStorage) {
        if (_.includes(key, "userData")) {
          let userData = JSON.parse(localStorage[key])
          userData.UserAttributes.map(item => {
            if (item.Name==="custom:type") {
              customType = item.Value
            }
            if (_.includes(item.Name, "custom:first_time")) {
              return first_time = true
            }
          })
        }
      }
      let isAuthorized = localStorage.getItem('user');
      if (!isAuthorized && isPrivate) {
        return <Redirect to='/' />;
      }
      if (isAuthorized && !isPrivate && first_time) {
        if (customType === "both"){
          return <Redirect to={`/control/advertiser/dashboard`} />
        }else{
          return <Redirect to={`/control/${customType}/dashboard`} />;
        }
      }

      return <WrappedComponent {...this.props} />;
    }
  }

  const mapStateToProps = (state) => (
    {
      isAuthorized: Boolean(state.username)
    }
  );

  return connect(mapStateToProps)(WithAuthorization);
};

export default Authorization;
