import { combineReducers } from "redux";
import loginReducer from "./LoginReducer";
import DashboardReducer from "./DashboardReducer";
import AccountReducer from "./AccountDataReducer";
import reportsReducer from "./reportsReducer";
import CompaignsReducer from "./CompaignsReducer";
import AppsReducer from "./AppsReducer";
import ReferralsReducer from "./ReferralsReducer";
import BillingInfoReducer from "./BillingInfoReducer";
import RegistrationReducer from "./RegistrationReducer";
import recoverPasswordReducer from "./recoverPasswordReducer";

export default combineReducers({
  loginReducer,
  RegistrationReducer,
  DashboardReducer,
  AccountReducer,
  reportsReducer,
  CompaignsReducer,
  AppsReducer,
  ReferralsReducer,
  BillingInfoReducer,
  recoverPasswordReducer
});
