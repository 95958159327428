import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { includes } from "lodash";
import { Helmet } from "react-helmet";
import InnerPagesComponent from "../../components/InnerPagesComponent/InnerPagesComponent";
import { resetReducer } from "../../store/actions/resetReducerAction";
import CompaignsComponent from "../../components/CompaignsComponent/CompaignsComponent";
import {
  getCompaigns as getData,
  addCompaings as addData,
  deleteCompaings as deleteData,
  ChangeCompaingsStatus as changeData,
  editCompaings as editData,
  resetCampaignsDataReducer as resetData,
  getCompaignById as getDataById
} from "../../store/actions/CompaignsActions";

class CompaignsContainer extends Component {
  componentDidMount() {
    const { getCompaigns, optionsData } = this.props;
    return (
      includes(window.location.pathname, "edit") &&
      getCompaigns({ provider: optionsData.provider })
    );
  }

  componentWillUnmount() {
    const { resetCampaignsData } = this.props;
    resetCampaignsData();
  }

  handleChangeStatus = (id, data) => {
    const { changeStatus, resetCampaignsData } = this.props;
    changeStatus(id, data);
    // resetCampaignsData();
  };

  render() {
    const {
      optionsData,
      optionsData: {
        options: { logo },
        displayName,
        provider
      },
      compaignsData,
      getCompaigns,
      deleteCompaings,
      ChangeCompaingsStatus,
      compaignsStatus,
      deleteCompaignsData,
      resetCampaignsData,
      editCompaign
    } = this.props;

    return (
      <InnerPagesComponent pageName="" logo={logo}>
        <Helmet>
          <title>Campaigns | {displayName} Control Panel</title>
        </Helmet>
        <CompaignsComponent
          getCompaigns={getCompaigns}
          optionsData={optionsData}
          compaignsData={compaignsData}
          deleteCompaings={deleteCompaings}
          ChangeCompaingsStatus={ChangeCompaingsStatus}
          compaignsStatus={compaignsStatus}
          deleteCompaignsData={deleteCompaignsData}
          resetCampaignsData={resetCampaignsData}
          editCompaign={editCompaign}
          changeStatus={this.handleChangeStatus}
          provider={provider}
        />
      </InnerPagesComponent>
    );
  }
}

const mapStateFromProps = store => ({
  optionsData: store.DashboardReducer.optionsData,
  compaignsData: store.CompaignsReducer.compaignsData,
  compaignsStatus: store.CompaignsReducer.compaignsStatus,
  addCompaignsData: store.CompaignsReducer.addCompaigns,
  deleteCompaignsData: store.CompaignsReducer.deleteCompaigns,
  editCompaign: store.CompaignsReducer.editCompaign
});

const mapDispatchFromProps = dispatch => ({
  resetReducer: name => dispatch(resetReducer(name)),
  getCompaigns: data => dispatch(getData(data)),
  addCompaings: body => dispatch(addData(body)),
  deleteCompaings: id => dispatch(deleteData(id)),
  changeStatus: (data, id) => dispatch(changeData(data, id)),
  editCompaings: (id, data) => dispatch(editData(id, data)),
  resetCampaignsData: () => dispatch(resetData()),
  getCompaignById: id => dispatch(getDataById(id))
});

export default withRouter(
  connect(
    mapStateFromProps,
    mapDispatchFromProps
  )(CompaignsContainer)
);
