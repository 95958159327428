import React, { useEffect } from "react";
import { Menu } from "semantic-ui-react";

const Tabs = ({ getData, activeItem, setActiveItem, userType, provider }) => {
  useEffect(() => {
    if (activeItem === "Campaign") {
      getData({
        type: "campaigns",
        filter: { userType: "publisher" },
        provider
      });
    }
    if (activeItem === "Date") {
      getData({
        type: "date",
        filter: { userType: ["publisher"] },
        provider
      });
    }
    if (activeItem === "Country") {
      getData({
        type: "country",
        filter: { userType: "publisher" },
        provider
      });
    }
    if (activeItem === "AdSpace") {
      getData({
        type: "pubAdspaces",
        filter: { userType: "publisher" },
        provider
      });
    }
  }, [activeItem, getData, userType]);
  console.log(userType);
  return (
    <Menu pointing secondary color="blue">
      <Menu.Item
        name="Date"
        active={activeItem === "Date"}
        onClick={() => setActiveItem("Date")}
      />
      <Menu.Item
        name={userType === "publisher" ? "AdSpace" : "Campaign"}
        active={activeItem === "Campaign" || activeItem === "AdSpace"}
        onClick={() =>
          userType === "publisher"
            ? setActiveItem("AdSpace")
            : setActiveItem("Campaign")
        }
      />
      <Menu.Item
        name="Country"
        active={activeItem === "Country"}
        onClick={() => setActiveItem("Country")}
      />
    </Menu>
  );
};

export default Tabs;
