import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import InnerPagesComponent from "../../components/InnerPagesComponent/InnerPagesComponent";
import DashboardComponent from "../../components/DashboardComponent/DashboardComponent";
import { getDashboardData } from "../../store/actions/DashboarActions";

class PublisherDashboardContainer extends Component {
  componentDidMount() {
    const { getDashboardData, optionsData: { provider } } = this.props;
    getDashboardData({ userType: "publisher", provider });
  }

  render() {
    const {
      optionsData: {
        options: { logo },
        displayName
      },
      dashboardData
    } = this.props;

    return (
      <Fragment>
        <InnerPagesComponent pageName="Dashboard" logo={logo}>
          <Helmet>
            <title>Dashboard | {displayName} Control Panel</title>
          </Helmet>
          <DashboardComponent
            dashboardData={dashboardData}
            userType="publisher"
          />
        </InnerPagesComponent>
      </Fragment>
    );
  }
}

const mapStateFromProps = store => ({
  optionsData: store.DashboardReducer.optionsData,
  dashboardData: store.DashboardReducer.dashboardData
});

const mapDispatchFromProps = dispatch => ({
  getDashboardData: data => dispatch(getDashboardData(data))
});

export default withRouter(
  connect(
    mapStateFromProps,
    mapDispatchFromProps
  )(PublisherDashboardContainer)
);
