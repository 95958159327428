import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Col, Row } from "reactstrap";
import { Header } from "semantic-ui-react";
import ForgotPasswordComponent from "../../components/Auth/ForgotPasswordComponent/ForgotPasswordComponent";
import { RecoverPasswordAction } from "../../store/actions/ForgotPasswordAction";
import "../LoginContainer/LoginContainer.scss";

const ForgotPasswordContainer = ({
  dashboardReducer,
  dashboardReducer: {
    optionsData: { displayName }
  },
  recoverPassword,
  verifyPassword
}) => {
  const handleSubmit = userName => recoverPassword(userName);

  return (
    <div className="row">
      <Helmet>
        <title>Forgot Password | {displayName} Control Panel</title>
      </Helmet>
      <ForgotPasswordComponent
        dashboardReducer={dashboardReducer}
        handleSubmit={handleSubmit}
      />
      {verifyPassword && <Redirect to="/verify-password" />}
      <Row className="left-side-container">
        <Col
          lg="12"
          md="8"
          sm="8"
          className="d-flex justify-content-center p-0 forgot-password-quote"
        >
          <Header
            textAlign="center"
            size="huge"
            style={{
              color: "white",
              fontSize: "1.7rem",
              fontStyle: "italic"
            }}
          >
            &ldquo;{" "}
            <span className="login-quote">
              The best advertising should make you{" "}
              <span className="marked-word login-quote">nervous</span> about
              what you&apos;re buying ... There is too much smart-ass
              advertising today and not enough that{" "}
              <span className="marked-word login-quote">emotionally moves</span>{" "}
              consumers to go out and buy something
            </span>{" "}
            &ldquo;
            <br />
            <span className="d-block text-right mt-3 login-quote">
              Marry Wells Lawrence
            </span>
          </Header>
        </Col>
      </Row>
      <div className="loginBack col-sm-6 p-0" />
    </div>
  );
};

const mapStateFromProps = store => ({
  dashboardReducer: store.DashboardReducer,
  verifyPassword: store.recoverPasswordReducer.success
});

const mapDispatchToProps = dispatch => ({
  recoverPassword: userName => dispatch(RecoverPasswordAction(userName))
});

export default connect(
  mapStateFromProps,
  mapDispatchToProps
)(ForgotPasswordContainer);
