import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap";
import "semantic-ui-css/semantic.min.css";
import { IntlProvider, addLocaleData } from "react-intl";
import en from "react-intl/locale-data/en";
import am from "react-intl/locale-data/am";
import store from "./store";
import App from "./App";
import { msg } from "./config/messages";

addLocaleData(en);
addLocaleData(am);

render(
  <IntlProvider
    locale={msg().languageWithoutRegionCode}
    messages={msg().messages}
  >
    <Provider store={store}>
      <App />
    </Provider>
  </IntlProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
