import React, { Component } from "react";
import _ from "lodash";
import Loader from "../../shared/Loader/index";
import Step1 from "./Step1/Step1";
import Step2 from "./Step2/Step2";
import Step3 from "./Step3/Step3";
import { ENV } from "../../environment";
import "./EditCompaignsComponent.scss";

const regExp = {
  url: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/
};
class EditCompaignsComponent extends Component {
  state = {
    step: "1",
    loader: false,
    data: {}
  };

  request = {
    creative: {
      url: {}
    }
  };

  componentDidMount() {
    const { id, getCompaignById, editCompaign, provider } = this.props;
    getCompaignById({ id, provider });
    this.updateEditableCampaign(editCompaign);
  }

  componentDidUpdate(prevProps) {
    const { editCompaign } = this.props;
    if (
      !_.isEqual(editCompaign, prevProps.editCompaign) &&
      prevProps.editCompaign
    ) {
      this.updateEditableCampaign(editCompaign);
    }
  }

  componentWillUnmount() {
    const { resetCampaignsData } = this.props;
    resetCampaignsData();
  }

  updateEditableCampaign = campaign => this.setState({ data: campaign });

  handleAdultChange = () => {
    const {data} = this.state;
    const mutable = {...data};
    mutable.adult = !data.adult;
    this.request.adult =  mutable.adult
    this.setState({data:{...mutable}});
    setTimeout(() => console.log(this.state))
  };

  handleGambleChange = () => {
    const {data} = this.state;
    const mutable = {...data};
    mutable.gambling = !data.gambling;
    this.request.gambling =  mutable.gambling
    this.setState({data:{...mutable}});
  };

  onStepChange = step => {
    this.setState({ step });
  };

  onChangeTitle = value => {
    const { data } = this.state;
    const mutable = { ...data };

    mutable.title = value;
    this.request.title = value;
    this.setState({ data: mutable });
  };

  onChangeUrlType = value => {
    const { data } = this.state;
    data.creative.urlType = value;
    data.creative.url = {};
    this.request.creative = {
      ...this.request.creative,
      urlType: value,
      url: {}
    };
    this.setState({ data });
  };

  onChangeUrels = (value, type) => {
    const urls = document.getElementsByClassName("urlInput");
    const { data } = this.state;
    // data.creative.url
    if (this.state.data.creative.urlType === "webside") {
      data.creative.url.webSiteUrl = value;
      // this.request.creative.url['webSiteUrl']=value
      if (!regExp.url.test(value)) {
        urls[0].style.border = "1px solid red";
      } else {
        urls[0].style.border = "1px solid #e0e4e8";
      }
    } else if (this.state.data.creative.urlType === "app") {
      if (type === "google") {
        data.creative.url.googleUrl = value;
        // this.request.creative.url['googlePlay']=value
        if (!regExp.url.test(value)) {
          urls[0].style.border = "1px solid red";
        } else {
          urls[0].style.border = "1px solid #e0e4e8";
        }
      } else if (type === "appStore") {
        data.creative.url.appStoreUrl = value;
        // this.request.creative.url['appStore']=value
        if (!regExp.url.test(value)) {
          urls[1].style.border = "1px solid red";
        } else {
          urls[1].style.border = "1px solid #e0e4e8";
        }
      }
    }
    this.setState({ data });
    this.request.creative.url = data.creative.url;
  };

  onSkipAbleChange = value => {
    const { data } = this.state;
    data.creative.skippable = value;
    this.request.creative.skippable = value === "true";
    this.setState({ data });
  };

  onFlatVideoAd = (file, fileUrl, duration) => {
    const { data } = this.state;
    data.creative.flatVideo.url = fileUrl;
    this.setState({ flatVideo: { data: file, duration }, data });
  };

  onSceneVideoAd = (file, fileUrl, duration) => {
    const { data } = this.state;
    data.creative.video.url = fileUrl;
    this.setState({ sceneVideo: { data: file, duration }, data });
  };

  on360videosAdd = (file, fileUrl, duration, name) => {
    const { data } = this.state;
    if (name === "video480") {
      data.creative.videos[name] = {
        type: "video480",
        duration,
        file,
        url: fileUrl
      };
    }
    if (name === "video1080") {
      data.creative.videos[name] = {
        type: "video1080",
        duration,
        file,
        url: fileUrl
      };
    }
    if (name === "video2k") {
      data.creative.videos[name] = {
        type: "video2k",
        duration,
        file,
        url: fileUrl
      };
    }
    if (name === "video4k") {
      data.creative.videos[name] = {
        type: "video4k",
        duration,
        file,
        url: fileUrl
      };
    }
    this.setState({
      data,
      videos360: data.creative.videos
    });
  };

  onOsTargeting = name => {
    const { data } = this.state;
    data.osTargeting = name;
    this.request.osTargeting = name;
    this.setState({ data });
  };

  onOsChange = () => {
    const { data } = this.state;
    const os = document.getElementsByClassName("oscheckbox");
    const osTargeting = [];
    for (let i = 0; i < os.length; i++) {
      if (os[i].checked) {
        osTargeting.push(os[i].value);
      }
    }
    if (osTargeting.length > 0) {
      data.osTargeting = osTargeting;
    }
    if (osTargeting.length === 0) {
      data.osTargeting = "";
    }
    this.request.osTargeting = data.osTargeting;
    this.setState({ data });
  };

  ongeoTargeting = name => {
    const { data } = this.state;
    data.geoTargeting = name;
    this.request.geoTargeting = name;
    this.setState({ data });
  };

  oncategoryTargeting = name => {
    const { data } = this.state;
    data.categoryTargeting = name;
    this.request.categoryTargeting = name;
    this.setState({ data });
  };

  ontimeTargeting = name => {
    const { data } = this.state;
    if (name === "all") {
      data.timeTargeting = name;
    } else {
      data.timeTargeting = [{ startTime: "", endTime: "" }];
    }
    this.request.timeTargeting = data.timeTargeting;
    this.setState({ data });
  };

  onChangeTimes = (index, time, type) => {
    const { data } = this.state;
    data.timeTargeting[index][type] = time;
    this.request.timeTargeting = data.timeTargeting;
    this.setState({ data });
  };

  onTimeLineDelete = index => {
    const { data } = this.state;
    const timeTargeting = [];
    data.timeTargeting.map((item, key) => {
      if (key !== index) {
        timeTargeting.push(item);
      }
    });
    data.timeTargeting = timeTargeting;
    this.request.timeTargeting = data.timeTargeting;
    this.setState({ data });
  };

  onAddTimeLine = () => {
    const { data } = this.state;
    data.timeTargeting.push({ startTime: "", endTime: "" });
    this.request.timeTargeting = data.timeTargeting;
    this.setState({ data });
  };

  onPricingChanges = (name, value) => {
    const { data } = this.state;
    data[name] = Number(value.replace(/\D/g, ""));
    this.request[name] = Number(value.replace(/\D/g, ""));
    this.setState({ data });
  };

  onSave = async (data) => {
    if (Object.entries(this.request.creative.url).length === 0) {
      delete this.request.creative.url;
      // if(Object.entries(this.request.creative).length===0){
      //     delete this.request.creative
      // }
    }
    if (this.state.flatVideo) {
      this.setState({ loader: true });
      this.request.creative.flatVideo = {
        duration: this.state.flatVideo.duration,
        url: `https://adlooker-creative.s3.${
          ENV.REGION
        }.amazonaws.com/flatVideo/${this.state.data.id}/flatVideo.mp4`
      };
      await this.props.onCompainCreate(
        this.state.flatVideo.data,
        this.state.data.id,
        "flatVideo",
        "flatVideo.mp4"
      );
      this.setState({ loader: false });
    }
    if (this.state.sceneVideo) {
      this.setState({ loader: true });
      this.request.creative.video = {
        duration: this.state.sceneVideo.duration,
        url: `https://adlooker-creative.s3.${
          ENV.REGION
        }.amazonaws.com/scenead/${this.state.data.id}/scenead.mp4`
      };
      await this.props.onCompainCreate(
        this.state.sceneVideo.data,
        this.state.data.id,
        "scenead",
        "scenead.mp4"
      );
      this.setState({ loader: false });
    }
    if (this.state.videos360) {
      for (const key in this.state.videos360) {
        if (key === "video480" && this.state.videos360[key].file) {
          this.setState({ loader: true });
          this.request.creative.videos = {
            ...this.request.creative.videos,
            video480: {
              duration: this.state.videos360[key].duration,
              url: `https://adlooker-creative.s3.${
                ENV.REGION
              }.amazonaws.com/videoad/${this.state.data.id}/video480.mp4`
            }
          };
          await this.props.onCompainCreate(
            this.state.videos360[key].file,
            this.state.data.id,
            "videoad",
            "video480.mp4"
          );
          this.setState({ loader: false });
        }
        if (key === "video1080" && this.state.videos360[key].file) {
          this.setState({ loader: true });
          this.request.creative.videos = {
            ...this.request.creative.videos,
            video1080: {
              duration: this.state.videos360[key].duration,
              url: `https://adlooker-creative.s3.${
                ENV.REGION
              }.amazonaws.com/videoad/${this.state.data.id}/video1080.mp4`
            }
          };
          await this.props.onCompainCreate(
            this.state.videos360[key].file,
            this.state.data.id,
            "videoad",
            "video1080.mp4"
          );
          this.setState({ loader: false });
        }
        if (key === "video2k" && this.state.videos360[key].file) {
          this.setState({ loader: true });
          this.request.creative.videos = {
            ...this.request.creative.videos,
            video2k: {
              duration: this.state.videos360[key].duration,
              url: `https://adlooker-creative.s3.${
                ENV.REGION
              }.amazonaws.com/videoad/${this.state.data.id}/video2k.mp4`
            }
          };
          await this.props.onCompainCreate(
            this.state.videos360[key].file,
            this.state.data.id,
            "videoad",
            "video2k.mp4"
          );
          this.setState({ loader: false });
        }
        if (key === "video4k" && this.state.videos360[key].file) {
          this.setState({ loader: true });
          this.request.creative.videos = {
            ...this.request.creative.videos,
            video4k: {
              duration: this.state.videos360[key].duration,
              url: `https://adlooker-creative.s3.${
                ENV.REGION
              }.amazonaws.com/videoad/${this.state.data.id}/video4k.mp4`
            }
          };
          await this.props.onCompainCreate(
            this.state.videos360[key].file,
            this.state.data.id,
            "videoad",
            "video4k.mp4"
          );
          this.setState({ loader: false });
        }
      }
    }
    this.request.status = this.state.data.status;
    await console.log('this.state.data.id',this.state.data.id)
    await console.log('this.request',this.request)
    if(data){
      return this.request['frequencyCapping']=data
    }

    await this.props.editCompaings(this.state.data.id, { ...this.request, provider: this.props.provider });
    await console.log(this.request);
  };

  render() {
    const {
      addCompaignsData: { pending },
      editCompaign
    } = this.props;
    const { loader, step, data } = this.state;
    console.log(data)
    return (
      <div className="container-non-responsive new-campaigns">
        {pending && <Loader />}
        {loader && <Loader />}
        {editCompaign.pending && <Loader />}
        {data ? (
          <div className="row">
            <div className="col-sm-9 new-campaigns-body">
              <div className="shadow-widget colored-blue">
                <ul className="nav nav-tabs">
                  <li
                    className={step === "1" ? "active" : ""}
                    onClick={() => this.onStepChange("1")}
                  >
                    <a>Step 1 | General</a>
                  </li>
                  <li
                    className={step === "2" ? "active" : ""}
                    onClick={() => this.onStepChange("2")}
                  >
                    <a>Step 2 | Creative</a>
                  </li>
                  <li
                    className={step === "3" ? "active" : ""}
                    onClick={() => this.onStepChange("3")}
                  >
                    <a>Step 3 | Targetings</a>
                  </li>
                </ul>
                {step === "1" && (
                  <Step1
                    title={this.state.data.title}
                    onStepChange={this.onStepChange}
                    onChangeTitle={this.onChangeTitle}
                  />
                )}
                {step === "2" && (
                  <Step2
                    urlType={data.creative.urlType}
                    onChangeUrlType={this.onChangeUrlType}
                    onStepChange={this.onStepChange}
                    url={data.creative.url}
                    onChangeUrels={this.onChangeUrels}
                    platform={data.platform}
                    adType={this.state.data.adType}
                    skippable={data.creative.skippable}
                    onSkipAbleChange={this.onSkipAbleChange}
                    creative={data.creative}
                    onFlatVideoAd={this.onFlatVideoAd}
                    onSceneVideoAd={this.onSceneVideoAd}
                    on360videosAdd={this.on360videosAdd}
                    checkMarks={this.state}
                    handleAdultChange={this.handleAdultChange}
                    handleGambleChange={this.handleGambleChange}
                  />
                )}
                {step === "3" && (
                  <Step3
                    onStepChange={this.onStepChange}
                    osTargeting={data.osTargeting}
                    onOsTargeting={this.onOsTargeting}
                    onOsChange={this.onOsChange}
                    geoTargeting={data.geoTargeting}
                    ongeoTargeting={this.ongeoTargeting}
                    categoryTargeting={data.categoryTargeting}
                    oncategoryTargeting={this.oncategoryTargeting}
                    timeTargeting={data.timeTargeting}
                    ontimeTargeting={this.ontimeTargeting}
                    onChangeTimes={this.onChangeTimes}
                    onTimeLineDelete={this.onTimeLineDelete}
                    onAddTimeLine={this.onAddTimeLine}
                    maxBid={data.maxBid}
                    onPricingChanges={this.onPricingChanges}
                    budget={data.budget}
                    dailyBudget={data.dailyBudget}
                    onSave={this.onSave}
                    frequencyCapping={data.frequencyCapping}
                  />
                )}
              </div>
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </div>
    );
  }
}

export default EditCompaignsComponent;
