import React, { Component } from 'react';
import {some, isEmpty} from 'lodash'

class WireTransfer extends Component {
    state = {
        requestData: {
            firstName: '',
            lastName: '',
            address: '',
            city: '',
            prov: '',
            zipCode: '',
            sin: '',
            bankName: '',
            bankAddress: '',
            bankPhone: '',
            bankCode: '',
            transitNumber: '',
            accountNumber: ''
        }
    }
    onChangeRequestData = (key, value) => {
        let requestData = this.state.requestData;
        requestData[key] = value;
        this.setState({requestData})
    }
    onPay = () => {
      let data = {
        type: "wireTransfer",
        cost: this.props.amount.toString(),
        billingInfo: this.state.requestData,
        provider: this.props.provider
      }
      this.props.coinWithDraw(data)
    }
    render() {
        return (
            <div className="coinPaymentsContainer">
                <h5>Payment Details</h5>
                <div className="wireTransferpart">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <small>First Name</small>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={this.state.firstName}
                                    onChange={(e)=>this.onChangeRequestData("firstName",e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <small>Last Name</small>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={this.state.lastName}
                                    onChange={(e)=>this.onChangeRequestData("lastName",e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <small>Address</small>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={this.state.address}
                                    onChange={(e)=>this.onChangeRequestData("address",e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <small>City</small>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={this.state.city}
                                    onChange={(e)=>this.onChangeRequestData("city",e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <small>Prove</small>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={this.state.prov}
                                    onChange={(e)=>this.onChangeRequestData("prov",e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <small>Zip Code</small>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={this.state.zipCode}
                                    onChange={(e)=>this.onChangeRequestData("zipCode",e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <small>Sin</small>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={this.state.sin}
                                    onChange={(e)=>this.onChangeRequestData("sin",e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <small>Bank Name</small>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={this.state.bankName}
                                    onChange={(e)=>this.onChangeRequestData("bankName",e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <small>Bank Address</small>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={this.state.bankAddress}
                                    onChange={(e)=>this.onChangeRequestData("bankAddress",e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <small>Bank Phone</small>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={this.state.bankPhone}
                                    onChange={(e)=>this.onChangeRequestData("bankPhone",e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <small>Bank Code</small>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={this.state.bankCode}
                                    onChange={(e)=>this.onChangeRequestData("bankCode",e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <small>Transit Number</small>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={this.state.transitNumber}
                                    onChange={(e)=>this.onChangeRequestData("transitNumber",e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <small>Account Number</small>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={this.state.accountNumber}
                                    onChange={(e)=>this.onChangeRequestData("accountNumber",e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <button className="payButton" onClick={this.onPay} disabled={this.props.amount < this.props.withdrawalGateways[1].min || this.props.amount > this.props.withdrawalGateways[1].max || some(this.state.requestData, isEmpty)}>Pay </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default WireTransfer;
