import React, { Component } from "react";
import "./ReferralsComponent.scss";
import { includes } from "lodash";
import Table from "./Table/Table";

class ReferralsComponent extends Component {
  state = {
    sub: null
  };

  componentDidMount() {
    for (const key in localStorage) {
      if (includes(key, "userData")) {
        const userData = JSON.parse(localStorage[key]);
        userData.UserAttributes.map(item => {
          if (item.Name === "sub") {
            this.setState({ sub: item.Value });
          }
        });
      }
    }
  }

  handleFocus = event => event.target.select();

  render() {
    const { referralsData, getReferralsLast, provider } = this.props;
    const { sub } = this.state;
    return (
      <React.Fragment>
        <div
          className="content-header clearfix"
          style={{ borderBottom: "1px solid lightgray", marginBottom: "20px" }}
        >
          <h1>REFFERALS REPORTS</h1>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="shadow-widget p-3">
              <div className="widget-header clearfix">
                <h2>Your Referral LINK:</h2>
              </div>
              <input
                type="text"
                className="referrer-url"
                value={`https://apidev.adlooker.com/registration?ref=${sub}`}
                onFocus={this.handleFocus}
              />
            </div>
            <Table
              referralsData={referralsData}
              getReferralsLast={getReferralsLast}
              provider={provider}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="referral-info shadow-widget colored-blue">
              <div className="widget-header clearfix">
                <h2 className="pt-0">How it works</h2>
              </div>
              <p>
                By inviting your friends to join Adlooker comunity youre
                starting earn revenue.
              </p>
              <h6>Why join?</h6>
              <ul>
                <li>
                  For every publisher you refer to the Adlooker network who
                  starts running Adlooker ads on their site, you will earn{" "}
                  <b>5%</b> of their revenue <b>FOR EVER</b>, starting on the
                  date you refer them!{" "}
                </li>
                <li>It will give you stable income for many years.</li>
                <li>
                  Get paid faster. Adlooker Referral program payment threshold
                  is only a <b>$100 minimum.</b>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ReferralsComponent;
