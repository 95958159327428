import React, { Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import InnerPagesComponent from "../../components/InnerPagesComponent/InnerPagesComponent";
import ReportingsComponent from "../../components/ReportingsComponent/ReportingsComponent";
import { getReports, getReportsLast } from "../../store/actions/reportsActions";
import { resetReducer } from "../../store/actions/resetReducerAction";

const PublisherReportingsContainer = ({
  optionsData: {
    options: { logo },
    displayName
  },
  getReports,
  resetReducer,
  reportsData,
  getReportsLast
}) => {
  return (
    <Fragment>
      <InnerPagesComponent pageName="" logo={logo}>
        <Helmet>
          <title>Reports | {displayName} Control Panel</title>
        </Helmet>
        <ReportingsComponent
          userType="publisher"
          getReports={getReports}
          resetReducer={resetReducer}
          reportsData={reportsData}
          getReportsLast={getReportsLast}
        />
      </InnerPagesComponent>
    </Fragment>
  );
};

const mapStateFromProps = store => ({
  optionsData: store.DashboardReducer.optionsData,
  reportsData: store.reportsReducer.reportsData
});

const mapDispatchFromProps = dispatch => ({
  getReports: data => dispatch(getReports(data)),
  resetReducer: name => dispatch(resetReducer(name)),
  getReportsLast: data => dispatch(getReportsLast(data))
});

export default withRouter(
  connect(
    mapStateFromProps,
    mapDispatchFromProps
  )(PublisherReportingsContainer)
);
