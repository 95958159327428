import React, { Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import InnerPagesComponent from "../../components/InnerPagesComponent/InnerPagesComponent";
import FAQComponent from "../../components/FAQComponent/FAQComponet";

const FAQContainer = ({
  optionsData: {
    options: { logo },
    displayName
  }
}) => {
  return (
    <Fragment>
      <InnerPagesComponent pageName="FAQ" logo={logo}>
        <Helmet>
          <title>FAQ | {displayName} Control Panel</title>
        </Helmet>
        <FAQComponent />
      </InnerPagesComponent>
    </Fragment>
  );
};

const mapStateFromProps = store => ({
  optionsData: store.DashboardReducer.optionsData
});

export default withRouter(connect(mapStateFromProps)(FAQContainer));
