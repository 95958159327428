import React from "react";
import { connect } from "react-redux";
import { Row, Col, Container } from "reactstrap";
import { Form, Button } from "semantic-ui-react";
import "./ForgotPasswordComponent.scss";
import usePasswordField from "../../../shared/Forms/Inputs/usePasswordField";
import { submitRecoveredPassword } from "../../../store/actions/ForgotPasswordAction";

const VerifyPasswordComponent = ({
  dashboardReducer: {
    optionsData: {
      options: { logo }
    }
  },
  handleSubmit,
}) => {
  const [
    password,
    confirmPassword,
    handleChange,
    valid,
    pristine,
    setBlur
  ] = usePasswordField();

  return (
    <Container fluid className="p-0 m-0 verify-password-container">
      <Row className="w-100 m-0">
        <Col className="d-flex align-items-center justify-content-center verify-password-col">
          <Row className="w-100">
            <Col sm="12" className="d-flex justify-content-center">
              <img src={logo} alt="" className="logo" />
            </Col>
            <Col sm="12" className="d-flex justify-content-center">
              <span>Verify New Password</span>
            </Col>
            <Form className="verify-password-form col-sm-12 justify-content-center">
              <Form.Input
                error={!valid}
                type="password"
                label="Password"
                placeholder="Password"
                name="password"
                inputClass="input-with-icon log-reg-input"
                icon="lock"
                dropdownIcon={false}
                className="w-50 m-auto"
                value={password}
                onChange={({ target: { value } }) =>
                  handleChange("password", value)
                }
              />
              <Form.Input
                type="password"
                label="Confirm Password"
                placeholder="Confirm Password"
                name="password"
                inputClass="input-with-icon log-reg-input"
                icon="lock"
                className="w-50 m-auto mt-2 mb-2"
                dropdownIcon={false}
                value={confirmPassword}
                onChange={({ target: { value } }) =>
                  handleChange("confirm", value)
                }
                onBlur={() => setBlur(true)}
              />
              <Button
                className="forgot-btn row w-50 m-0 d-block"
                color="linkedin"
                disabled={false}
                // onClick={() => handleSubmit(userName)}
              >
                Submit
              </Button>
            </Form>
          </Row>
        </Col>
        <Col sm="6" className="loginBack p-0" />
      </Row>
    </Container>
  );
};

const mapStateFromProps = store => ({
  dashboardReducer: store.DashboardReducer
});
const mapDispatchFromProps = dispatch => ({
  handleSubmit: (username, code, password) =>
    dispatch(submitRecoveredPassword(username, code, password))
});

export default connect(
  mapStateFromProps,
  mapDispatchFromProps
)(VerifyPasswordComponent);
