import React, { Component } from 'react';
import { Select } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import Countries from '../../../config/countries.json';
import TimePicker from 'react-time-picker';

class Step3 extends Component {

  state = {
    options: [
      {
        value: 'none',
        text: 'none',
        key: 'none',
      },
      {
        value: 1,
        text: '1',
        key: '1',
      },
      {
        value: 6,
        text: '6',
        key: '6',
      },
      {
        value: 12,
        text: '12',
        key: '12',
      },
      {
        value: 24,
        text: '24',
        key: '24',
      },
      {
        value: 'session',
        text: 'session',
        key: 'session',
      },
    ],
    selectedFrequency: null,
  };
  
  onTimesRender = times => {
    let zones = [];
    times.map((item, key) => {
      zones.push(
        <div className="row" key={key} style={{ marginTop: '10px' }}>
          <div className="col-md-3">
            <input
              type="time"
              className="form-control"
              value={item.startTime}
              onChange={e =>
                this.props.onChangeTimes(key, e.target.value, 'startTime')
              }
            />
          </div>
          <div className="col-md-3">
            <input
              type="time"
              className="form-control"
              value={item.endTime}
              onChange={e =>
                this.props.onChangeTimes(key, e.target.value, 'endTime')
              }
            />
          </div>
          {times.length > 1 && (
            <div className="col-md-1">
              <button
                className="btn btn-danger trash-time"
                onClick={() => this.props.onTimeLineDelete(key)}
              >
                <i className="fa fa-trash" />
              </button>
            </div>
          )}
        </div>
      );
    });
    return zones;
  };
  
  onCheckTimes = () => {
    let valid = false;
    if (this.props.timeTargeting === 'custom') {
      this.props.timeTargeting.map(item => {
        if (!item.startTime) {
          valid = true;
        } else if (!item.endTime) {
          valid = true;
        }
      });
    }
    return valid;
  };

  render() {
    console.log("this.props.frequencyCapping",this.props.frequencyCapping)
    return (
      <div className="step3">
        <div className="row">
          <div className="col-md-7">
            <div className="form-group">
              <small>OS targeting</small>
              <form>
                <input
                  type="radio"
                  name="all"
                  checked={this.props.osTargeting === 'all' ? true : false}
                  onChange={() => this.props.onOsTargeting('all')}
                  className="radiButtuns"
                />
                <label className="radio-inline all">All</label>
                <input
                  type="radio"
                  name="custom"
                  checked={this.props.osTargeting !== 'all' ? true : false}
                  onChange={() => this.props.onOsTargeting('custom')}
                  className="radiButtuns"
                />
                <label className="radio-inline">Custom</label>
              </form>
              {this.props.osTargeting !== 'all' && (
                <div className="checkbox-content clearfix OS">
                  <div className="custom-control custom-checkbox custom-control-inline">
                    <input
                      type="checkbox"
                      className="custom-control-input oscheckbox"
                      id="defaultInline1"
                      value="android"
                      checked={
                        this.props.osTargeting['0'] === 'android' ||
                        this.props.osTargeting['1'] === 'android'
                          ? true
                          : false
                      }
                      onChange={() => this.props.onOsChange()}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="defaultInline1"
                    >
                      Android
                    </label>
                  </div>
                  <div className="custom-control custom-checkbox custom-control-inline">
                    <input
                      type="checkbox"
                      className="custom-control-input oscheckbox"
                      id="defaultInline2"
                      value="ios"
                      checked={
                        this.props.osTargeting['0'] === 'ios' ||
                        this.props.osTargeting['1'] === 'ios'
                          ? true
                          : false
                      }
                      onChange={() => this.props.onOsChange()}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="defaultInline2"
                    >
                      IOS
                    </label>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group-text">
              <p style={{ fontSize: '12px' }}>
                OS targeting is target option for detecting OS: android, iOS and
                etc
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-7">
            <div className="form-group">
              <small>Geo targeting</small>
              <form>
                <input
                  type="radio"
                  name="all"
                  checked={this.props.geoTargeting === 'all' ? true : false}
                  onChange={() => this.props.ongeoTargeting('all')}
                  className="radiButtuns"
                />
                <label className="radio-inline all">All</label>
                <input
                  type="radio"
                  name="custom"
                  checked={this.props.geoTargeting !== 'all' ? true : false}
                  onChange={() => this.props.ongeoTargeting('custom')}
                  className="radiButtuns"
                />
                <label className="radio-inline">Custom</label>
              </form>
              {this.props.geoTargeting !== 'all' && (
                <div className="checkbox-content clearfix Geo">
                  <div className="form-group">
                    <select
                      className="form-control"
                      onChange={e => this.props.ongeoTargeting(e.target.value)}
                      value={this.props.geoTargeting}
                    >
                      <option value="">---Select---</option>
                      {Countries.countries.map((item, key) => {
                        return (
                          <option value={item.code} key={key}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group-text">
              <p style={{ fontSize: '12px' }}>
                Target your ad by choosing Geo targeting. Its helping you to
                check exact countries where the ads will be shown.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-7">
            <div className="form-group">
              <small>Category targeting</small>
              <form>
                <input
                  type="radio"
                  name="all"
                  checked={
                    this.props.categoryTargeting === 'all' ? true : false
                  }
                  onChange={() => this.props.oncategoryTargeting('all')}
                  className="radiButtuns"
                />
                <label className="radio-inline all">All</label>
                <input
                  type="radio"
                  name="custom"
                  checked={
                    this.props.categoryTargeting !== 'all' ? true : false
                  }
                  onChange={() => this.props.oncategoryTargeting('custom')}
                  className="radiButtuns"
                />
                <label className="radio-inline">Custom</label>
              </form>
              {this.props.categoryTargeting !== 'all' && (
                <div className="checkbox-content clearfix Geo">
                  <div className="form-group">
                    <select
                      className="form-control"
                      onChange={e =>
                        this.props.oncategoryTargeting(e.target.value)
                      }
                      value={this.props.categoryTargeting}
                    >
                      <option value="">---Select---</option>
                      <option value="business">Business</option>
                      <option value="creativity">Creativity</option>
                      <option value="education">Education</option>
                      <option value="entertainment">Entertainment</option>
                      <option value="lifestyle">Lifestyle</option>
                      <option value="news">News</option>
                      <option value="productivity">Productivity</option>
                      <option value="social">Social</option>
                      <option value="shopping">Shopping</option>
                    </select>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group-text">
              <p style={{ fontSize: '12px' }}>
                Category targeting is target option for choosing what category
                websites your ad will be shown.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-7">
            <div className="form-group">
              <small>Time Targeting</small>
              <form>
                <input
                  type="radio"
                  name="all"
                  checked={this.props.timeTargeting === 'all' ? true : false}
                  onChange={() => this.props.ontimeTargeting('all')}
                  className="radiButtuns"
                />
                <label className="radio-inline all">All</label>
                <input
                  type="radio"
                  name="custom"
                  checked={this.props.timeTargeting !== 'all' ? true : false}
                  onChange={() => this.props.ontimeTargeting('custom')}
                  className="radiButtuns"
                />
                <label className="radio-inline">Custom</label>
              </form>
              {this.props.timeTargeting !== 'all' && (
                <div className="checkbox-content clearfix Geo">
                  <div className="row">
                    <div className="col-md-3">
                      <strong>Start Time</strong>
                    </div>
                    <div className="col-md-3">
                      <strong>Time</strong>
                    </div>
                  </div>
                  {this.onTimesRender(this.props.timeTargeting)}
                  <button
                    className="btn btn-success add-time"
                    onClick={() => this.props.onAddTimeLine()}
                  >
                    Add
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group-text">
              <p style={{ fontSize: '12px' }}>
                Target your ad to be shown in exact time in exact time zone.
              </p>
            </div>
          </div>
        </div>
        <div className="col-sm-2 pl-0 d-flex-column" style={{marginBottom:'1rem'}}>
          <small className="Frequency Capping Targeting d-block">
            Frequency Capping Targeting
          </small>
          <Select
            placeholder={this.props.frequencyCapping.toString()}
            options={this.state.options}
            defaultValue={this.props.frequencyCapping}
            className="mr-4 edit-campaigns-select"
            onChange={(e, data) => {
              this.setState({ selectedFrequency: data.value });
            }}
          />
        </div>
        <div className="row">
          <div className="col-md-2">
            <div className="form-group" style={{ position: 'relative' }}>
              <small>
                Max Bid CPM <span className="required">*</span>
              </small>
              <input
                type="text"
                className="form-control"
                placeholder="0"
                value={this.props.maxBid}
                onChange={e =>
                  this.props.onPricingChanges('maxBid', e.target.value)
                }
              />
              <span className="dollarSpan">$</span>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group-text">
              <p style={{ fontSize: '12px' }}>
                Max Bid is the maximum prize that you're going to pay for 1000
                impression.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-2">
            <div className="form-group" style={{ position: 'relative' }}>
              <small>
                Total Budget <span className="required">*</span>
              </small>
              <input
                type="text"
                className="form-control"
                placeholder="0"
                value={this.props.budget}
                onChange={e =>
                  this.props.onPricingChanges('budget', e.target.value)
                }
              />
              <span className="dollarSpan">$</span>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group-text">
              <p style={{ fontSize: '12px' }}>
                Total budget is the whole prize which will be spent for
                advertisement. First you must fill your balance to make an
                approval of your ad.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-2">
            <div className="form-group" style={{ position: 'relative' }}>
              <small>Daily Budget</small>
              <input
                type="text"
                className="form-control"
                placeholder="0"
                value={this.props.dailyBudget}
                onChange={e =>
                  this.props.onPricingChanges('dailyBudget', e.target.value)
                }
              />
              <span className="dollarSpan">$</span>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group-text">
              <p style={{ fontSize: '12px' }}>
                Daily budget is the size of your campaign budget for daily
                bases.
              </p>
            </div>
          </div>
        </div>
        <div className="footer-buttons">
          <Link className="btn btn-default" to="/control/advertiser/campaigns">
            Cancel
          </Link>
          <button
            className="btn btn-primary pull-right"
            disabled={
              this.props.osTargeting === '' ||
              this.props.osTargeting === 'custom' ||
              (this.props.geoTargeting === 'custom' ||
                this.props.geoTargeting === '') ||
              (this.props.categoryTargeting === 'custom' ||
                this.props.categoryTargeting === '') ||
              (this.props.maxBid === '' || !this.props.maxBid) ||
              (this.props.budget === '' || !this.props.budget) ||
              this.onCheckTimes()
            }
            onClick={() => this.props.onSave(this.state.selectedFrequency)}
          >
            Save
          </button>
          <button
            className="btn btn-default pull-right"
            onClick={() => this.props.onStepChange('2')}
            style={{ marginRight: '5px' }}
          >
            Back
          </button>
        </div>
      </div>
    );
  }
}

export default Step3;
