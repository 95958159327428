import React from 'react';

const SdkModal = (props) => {
    return <div className="modalContainer" onClick={props.onCloseSdk}>
        <div className="modalContent">
            <div className="modal-header">
                <h4 className="modal-title">SDK CODE</h4>
                <button className="close" onClick={props.onCloseSdk}>
                    <span>×</span>
                </button>
            </div>
            <div className="modal-body">
                <div className="chose-type">
                    <form className="ng-pristine ng-valid ng-touched">
                        <label className="inline" for="sdk">
                            <span className="styled-input styled-radio fa fa-circle-o checked">
                                <input id="sdk" type="radio" value="sdk" className="ng-pristine ng-valid ng-touched" />
                            </span>SDK
                    </label>
                    </form>
                </div>
                <div className="ng-star-inserted">
                    <form className="script-type ng-untouched ng-pristine ng-valid">
                        <div className="textarea">
                            <p >For android development please download <a href="/files/sdk/AdLooker_android_SDK.zip" target="_blank">Android SDK</a> archive. </p>
                            <p>For ios development please download <a href="/files/sdk/AdLooker_ios_SDK.zip" target="_blank">IOS SDK</a> archive. </p>
                            <h4 style={{margin: "5px 0"}}>Values which must be set in Application </h4>
                            <p>App key (<code>ADLOOKER_APP_KEY</code>): &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong></strong></p>
                            <p>Ad space key (<code >ADLOOKER_ADSPACE_KEY</code>): &nbsp;&nbsp;<strong></strong></p>
                        </div>
                    </form>
                    <div className="alert alert-info">
                        <strong>Code Installation Instructions:</strong>
                        <p>Please read instruction including in SDK archive. Instruction file name for Android SDK - ReadMe.txt. Instruction folder name for IOS SDK - Help.</p>
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                <button className="btn btn-default" onClick={props.onCloseSdk}>Close</button>
            </div>
        </div>
    </div>
};

export default SdkModal