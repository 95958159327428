import { handleActions } from "redux-actions";
import { REGISTRATION, RESET_REGISTRATION } from "../constants";

const initialState = {
  success: false,
  pending: false,
  redirect: false,
  error: null
};

const RegistrationReducer = handleActions(
  {
    [REGISTRATION.PENDING]: (state = initialState) => ({
      ...state,
      pending: true
    }),

    [REGISTRATION.SUCCESS]: (state = initialState, { payload }) => ({
      ...state,
      pending: false,
      success: true,
      message: payload,
      redirect: true
    }),

    [REGISTRATION.ERROR]: (state = initialState, { error }) => ({
      ...state,
      pending: false,
      success: false,
      error
    }),

    [RESET_REGISTRATION]: (state = initialState) => ({
      ...state,
      redirect: false
    })
  },
  initialState
);

export default RegistrationReducer;
