import { handleActions } from "redux-actions";
import {
  GET_REPORTS,
  GET_REPORTS_LAST,
  RESET_REPORTS_REDUCER
} from "../constants";

const defaultState = {
  reportsData: {
    pending: false,
    fetched: false,
    error: null,
    data: null,
    LastEvaluatedKey: null
  }
};

const reportsReducer = handleActions(
  {
    [GET_REPORTS.PENDING]: (state = defaultState) => ({
      ...state,
      reportsData: {
        pending: true,
        fetched: false,
        error: null,
        data: null
      }
    }),
    [GET_REPORTS.SUCCESS]: (
      state = defaultState,
      { payload: { reports, LastEvaluatedKey } }
    ) => ({
      ...state,
      reportsData: {
        pending: false,
        fetched: true,
        error: null,
        data: reports,
        LastEvaluatedKey
      }
    }),
    [GET_REPORTS_LAST.SUCCESS]: (
      state = defaultState,
      { payload: { LastEvaluatedKey, Items } }
    ) => ({
      ...state,
      reportsData: {
        pending: false,
        fetched: true,
        error: null,
        data: [...state.reportsData.data, ...Items],
        LastEvaluatedKey
      }
    }),
    [GET_REPORTS.ERROR]: (state = defaultState, { error }) => ({
      ...state,
      reportsData: {
        pending: false,
        fetched: false,
        error,
        data: null
      }
    }),
    [RESET_REPORTS_REDUCER]: (state = defaultState) => ({
      ...state,
      reportsData: {
        pending: false,
        fetched: false,
        error: null,
        data: null
      }
    })
  },
  defaultState
);

export default reportsReducer;
