import React from "react";
import { Chart } from "react-google-charts";
import getCountries from "../../../config/useCountries";
import "./GeoChart.scss";

const GeoChart = ({
  dashboardData: {
    data: { topCountries }
  }
}) => {
  const trafficData = [["Country", "Sessions"]];
  topCountries.forEach(item => {
    const countries = getCountries();
    countries.filter(it => {
      return (
        item.country === it.value && trafficData.push([it.text, item.count])
      );
    });
  });

  return (
    <div className="col-md-5">
      <div className="shadow-widget" style={{ borderRadius: "5px" }}>
        <div className="row">
          <div className="col-md-12">
            <div className="todays-boxes">
              <p className="titile">Where are your customers?</p>
            </div>
          </div>
          <div className="col-md-12">
            <Chart
              chartType="GeoChart"
              width="100%"
              height="300px"
              data={trafficData}
              options={{
                colorAxis: { colors: ["blue"] },
                legend: "none",
                fontName:
                  "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji",
                chartArea: { width: "100%", height: "70%" }
              }}
            />
            <Chart
              chartType="BarChart"
              width="100%"
              height="285px"
              data={trafficData}
              options={{
                colorAxis: { colors: ["blue"] },
                legend: { position: "none" },
                chartArea: { width: "70%", height: "80%" },
                bar: { groupWidth: trafficData.length > 2 ? "30%" : "15%" }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeoChart;
