import localeData from './languages.json';
import availableLanguages from './langs.json';
import * as _ from 'lodash';

const url = new URL(window.location.href);
let pathLang = url.searchParams.get('lang');

if (localStorage.language === 'undefined') {
  localStorage.removeItem('language');
}

  if (!localStorage.language) {
    function replace(string) {
        string = string.split(/[_-]+/);
        let left = string[0].toLowerCase();
        let right = (string[1] ? string[1] : left).toUpperCase();
        string = left + '_' + right;
        return string;
    }

    let language = (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage;

    language = replace(language);
    const isExists = _.find(availableLanguages, (value, key) => key === language);
    localStorage.setItem('language', isExists ? language : 'en_US');
  } else {
    const isExists = _.find(availableLanguages, (value, key) => key === localStorage.language);
    localStorage.setItem('language', isExists ? localStorage.language : 'en_US');
  }

const language = localStorage.language;

const languageWithoutRegionCode = pathLang ? pathLang.toLowerCase().split(/[_-]+/)[0] : language.toLowerCase().split(/[_-]+/)[0];


const messages = localeData[languageWithoutRegionCode] || localeData[language] || localeData.en;

export const msg = () => {
  return {
    messages,
    languageWithoutRegionCode
  }
};
