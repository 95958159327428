import React, { Component } from "react";
import { Link } from "react-router-dom";
import Table from "../../shared/SemanticTable/Table";
import Loader from "../../shared/Loader/index";
import "./CompaignsComponent.scss";

class CompaignsComponent extends Component {
  state = {
    cells: [
      { name: "Campaign Title", value: null, key: "title", order: 0 },
      { name: "Type", value: null, key: "platform", order: 1 },
      { name: "Ad Type", value: null, key: "adType", order: 2 },
      { name: "Max Bid", value: null, key: "maxBid", order: 3 },
      { name: "Daily Budget", value: null, key: "dailyBudget", order: 4 },
      { name: "Total Budget", value: null, key: "budget", order: 5 },
      { name: "Status", value: null, key: "status", order: 6 },
      { name: "Actions", value: true, key: "actions", order: 7 }
    ]
  };

  componentDidMount() {
    const {
      optionsData: { provider },
      getCompaigns
    } = this.props;
    getCompaigns({ provider });
  }

  render() {
    const {
      compaignsStatus,
      deleteCompaignsData,
      compaignsData,
      deleteCompaings,
      changeStatus,
      getCompaigns,
      provider
    } = this.props;

    const { cells } = this.state;
    return (
      <React.Fragment>
        {compaignsStatus.pending && <Loader />}
        {deleteCompaignsData.pending && <Loader />}
        <div
          className="content-header clearfix pl-0 pr-0"
          style={{ borderBottom: "1px solid lightgray", marginBottom: "20px" }}
        >
          <h1>CAMPAIGNS</h1>
          <Link
            to="/control/advertiser/campaigns/create"
            className="btn btn-success"
          >
            New Campaign
          </Link>
        </div>
        <Table
          cells={cells}
          data={compaignsData.data}
          compaignsData={compaignsData}
          pending={compaignsData.pending}
          action={deleteCompaings}
          infinite
          lastEvaluatedKey={compaignsData.LastEvaluatedKey}
          getItems={getCompaigns}
          changeStatus={changeStatus}
          compaignsStatus={compaignsStatus}
          deleteCompaignsData={deleteCompaignsData}
          provider={provider}
        />
      </React.Fragment>
    );
  }
}

export default CompaignsComponent;
