import { useState } from "react";

const useOptions = () => {
  const [mediaType] = useState([
    { value: "App", text: "App", key: "app" },
    { value: "Website", text: "Website", key: "Website" }
  ]);

  const [category] = useState([
    { value: "Business", text: "Business", key: "Business" },
    { value: "Creativity", text: "Creativity", key: "Creativity" },
    { value: "Education", text: "Education", key: "Education" },
    { value: "Entertainment", text: "Entertainment", key: "Entertainment" },
    { value: "Lifestyle", text: "Lifestyle", key: "Lifestyle" },
    { value: "News", text: "News", key: "News" },
    { value: "Productivity", text: "Productivity", key: "Productivity" },
    { value: "Social", text: "Social", key: "Social" },
    { value: "Shopping", text: "Shopping", key: "Shopping" }
  ]);

  const [genre] = useState([
    { value: "360video", text: "360° video", key: "360video" },
    { value: "Action", text: "Action", key: "Action" },
    { value: "Adventure", text: "Adventure", key: "Adventure" },
    { value: "Casual", text: "Casual", key: "Casual" },
    { value: "Comedy", text: "Comedy", key: "Comedy" },
    { value: "Creative story", text: "Creative story", key: "Creative story" },
    { value: "Productivity", text: "Productivity", key: "Productivity" },
    { value: "Educational", text: "Educational", key: "Educational" },
    { value: "Exploration", text: "Exploration", key: "Exploration" },
    { value: "Horror", text: "Horror", key: "Horror" },
    {
      value: "Interactive video",
      text: "Interactive video",
      key: "Interactive video"
    },
    { value: "Live event", text: "Live event", key: "Live event" },
    { value: "Medical", text: "Medical", key: "Medical" },
    { value: "Music", text: "Music", key: "Music" },
    { value: "Racing", text: "Racing", key: "Racing" },
    { value: "Real estate", text: "Real estate", key: "Real estate" },
    { value: "RPG", text: "RPG", key: "RPG" },
    { value: "Science", text: "Science", key: "Science" },
    { value: "Shooter", text: "Shooter", key: "Shooter" },
    { value: "Sports", text: "Sports", key: "Sports" },
    { value: "Simulation", text: "Simulation", key: "Simulation" },
    { value: "Strategy", text: "Strategy", key: "Strategy" },
    { value: "Tools", text: "Tools", key: "Tools" },
    { value: "Travel", text: "Travel", key: "Travel" },
    { value: "War", text: "War", key: "War" }
  ]);

  return [mediaType, category, genre];
};

export default useOptions;
