import React from "react";

const loader = () => {
  return (
    <div
      className="loaderContainer"
      style={{ width: "100%", height: "80px", position: "relative" }}
    >
      <div className="cssload-loader" style={{ top: "40px" }}>
        <div className="cssload-side" />
        <div className="cssload-side" />
        <div className="cssload-side" />
        <div className="cssload-side" />
        <div className="cssload-side" />
        <div className="cssload-side" />
        <div className="cssload-side" />
        <div className="cssload-side" />
      </div>
    </div>
  );
};

const InfoButtons = ({ BillingInfoData: { pending }, balance }) => {
  return (
    <div className="col-md-3 pr-0">
      <a className="info-tiles tiles-success" style={{ color: "white" }}>
        <div className="tiles-heading">
          <div className="text-center ng-binding">Balance</div>
        </div>
        <div className="tiles-body">
          {pending && loader()}
          {balance && balance.balance !== null && (
            <div className="text-center ng-binding">
              $ {balance.balance.toFixed(2)}
            </div>
          )}
        </div>
      </a>
      {localStorage.customerType !== "advertiser" && (
        <a className="info-tiles tiles-warning" style={{ color: "white" }}>
          <div className="tiles-heading">
            <div className="text-center ng-binding">PENDING WITHDRAWAL</div>
          </div>
          <div className="tiles-body">
            {pending && loader()}
            {balance && balance.pendingBalance !== null && (
              <div className="text-center ng-binding">
                $ {balance.pendingBalance.toFixed(2)}
              </div>
            )}
          </div>
        </a>
      )}
      {localStorage.customerType !== "advertiser" && (
        <a className="info-tiles tiles-danger" style={{ color: "white" }}>
          <div className="tiles-heading">
            <div className="text-center ng-binding">WITHDRAWN</div>
          </div>
          <div className="tiles-body">
            {pending && loader()}
            {balance && balance.withdrawnBalance !== null && (
              <div className="text-center ng-binding">
                $ {balance.withdrawnBalance.toFixed(2)}
              </div>
            )}
          </div>
        </a>
      )}
    </div>
  );
};

export default InfoButtons;
