import React, { useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import InnerPagesComponent from "../../components/InnerPagesComponent/InnerPagesComponent";
import DashboardComponent from "../../components/DashboardComponent/DashboardComponent";
import { getDashboardData } from "../../store/actions/DashboarActions";

const AdvertiserDashboardContainer = ({
  getDashboard,
  optionsData: {
    displayName,
    options: { logo },
    provider
  },
  dashboardData
}) => {
  useEffect(() => {
    getDashboard({ userType: "advertiser", provider });
  }, [getDashboard]);
  return (
    <Fragment>
      <InnerPagesComponent pageName="Dashboard" logo={logo}>
        <Helmet>
          <title>Dashboard | {displayName} Control Panel</title>
        </Helmet>
        <DashboardComponent
          dashboardData={dashboardData}
          userType="advertiser"
        />
      </InnerPagesComponent>
    </Fragment>
  );
};

const mapStateFromProps = store => ({
  optionsData: store.DashboardReducer.optionsData,
  dashboardData: store.DashboardReducer.dashboardData,
  dashboardReducer: store.DashboardReducer
});

const mapDispatchFromProps = dispatch => ({
  getDashboard: data => dispatch(getDashboardData(data))
});

export default withRouter(
  connect(
    mapStateFromProps,
    mapDispatchFromProps
  )(AdvertiserDashboardContainer)
);
