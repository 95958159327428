import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Table from './Table/Table';
import SdkModal from './SdkModal/SdkModal';
import './AdSpacesComponent.scss';
import Loader from '../../shared/Loader/index';

class AdSpacesComponent extends Component {
    state={
        SdkModal: false,
        deletedModalId: ''
    }
    componentWillMount(){
        let body = {
            appId: this.props.id,
            provider: this.props.optionsData.provider
        }
        this.props.getAdSpaces(body)
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.deleteAddSpacesData.fetched !== this.props.deleteAddSpacesData.fetched && !nextProps.adSpacesData.fetched) {
            this.setState({ addNewModal: false })
            let body = {
                appId: this.props.id,
                provider: this.props.optionsData.provider
            }
            this.props.getAdSpaces(body)
        }
    }
    onOpenSdk=()=>{
        this.setState({SdkModal: true})
    }
    onCloseSdk=()=>{
        this.setState({SdkModal: false})
    }
    onDeleteModal = (id) => {
        this.setState({ deletedModalId: id })
    }
    render() {
        return (
            <React.Fragment>
                <div className="content-header clearfix" style={{ borderBottom: "1px solid lightgray", marginBottom: "20px" }}>
                    <h1><Link to='/control/publisher/apps'>APPS</Link> | AD SPACES</h1>
                    <Link className="btn btn-success" to={`/control/publisher/apps/adspaces/create/${this.props.id}`}>Create Ad Space</Link>
                </div>
                <Table
                    adSpacesData={this.props.adSpacesData}
                    onOpenSdk={this.onOpenSdk}
                    onDeleteModal={this.onDeleteModal}
                    id={this.props.id}
                />
                {
                    this.state.SdkModal && 
                    <SdkModal
                        onCloseSdk={this.onCloseSdk}
                    />
                }
                {this.state.deletedModalId && <div className="modalContainer" onClick={() => this.onDeleteModal('')}>
                    <div className="deleteModalContent">
                        <div className="modal-message">
                            <span>Are you sure you want to delete? This action can’t be undone.</span>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-danger" onClick={() => this.props.deleteAdSpaces(this.state.deletedModalId)}>Confirm</button>
                            <button className="btn btn-default" onClick={() => this.onDeleteModal('')}>Close</button>
                        </div>
                    </div>
                </div>}
                {this.props.deleteAddSpacesData.pending && <Loader />}
            </React.Fragment>
        );
    }
}

export default AdSpacesComponent;
