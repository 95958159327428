import React, { Component } from 'react';
import { Link } from 'react-router-dom';

const Step1 = ({ title, onChangeTitle, platform, onStepChange }) => {
  return (
    <div className="step1">
      <div className="row">
        <div className="col-md-3">
          <div className="form-group">
            <small>
              Campaign name <span className="required">*</span>
            </small>
            <input
              type="text"
              placeholder="Campaign name"
              className="form-control"
              value={title}
              onChange={e => onChangeTitle(e.target.value)}
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group-text">
            <p style={{ fontSize: '12px' }}>
              Campaign name is title of your campaign which is displayed in main
              campaign section.
            </p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3">
          <div className="form-group">
            <small>
              Where to perform <span className="required">*</span>
            </small>
            <select className="form-control" value={platform} disabled={true}>
              <option value="AR">AR</option>
              <option value="VR">VR</option>
            </select>
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group-text">
            <p style={{ fontSize: '12px' }}>
              Advertisement platform is place where your campaign will be
              displayed.
            </p>
          </div>
        </div>
      </div>
      <div className="footer-buttons">
        <Link className="btn btn-default" to="/control/advertiser/campaigns">
          Cancel
        </Link>
        <button
          className="btn btn-primary pull-right"
          onClick={() => onStepChange('2')}
          disabled={title === ''}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Step1;
