import React from "react";
import "numeral/locales/pt-br";
import { Link } from "react-router-dom";
import { Table as STable, Icon } from "semantic-ui-react";
import _ from "lodash";
import "./index.scss";
import RenderCell from "./RenderCell";

const RenderRows = ({ data, cells, action, id, changeStatus, provider }) => {
  return (
    data &&
    _.map(cells, (value, key) => {
      return value.name === "Actions" ? (
        <STable.Cell
          key={data.id}
          style={{ verticalAlign: "middle" }}
          className="text-center shared-table-cell"
        >
          <Link to={`/control/advertiser/campaigns/edit/${id}`}>
            <Icon
              circular
              name="edit outline"
              size="large"
              color="grey"
              style={{ opacity: 0.8 }}
            />
          </Link>
          {data.status === "active" && (
            <Icon
              circular
              name="pause"
              size="large"
              color="grey"
              style={{ cursor: "pointer", opacity: 0.8 }}
              onClick={() => {
                changeStatus({ status: "paused", provider }, id);
              }}
            />
          )}
          {data.status === "paused" && (
            <Icon
              circular
              name="play"
              size="large"
              color="grey"
              style={{ cursor: "pointer", opacity: 0.8 }}
              onClick={() => {
                changeStatus({ status: "active", provider }, id);
              }}
            />
          )}
          <Icon
            circular
            name="trash alternate outline"
            size="large"
            color="grey"
            style={{ cursor: "pointer", opacity: 0.8 }}
            onClick={() => action({ id, provider })}
          />
        </STable.Cell>
      ) : (
        <STable.Cell
          key={key}
          style={{ verticalAlign: "middle" }}
          className="text-center"
        >
          <RenderCell data={data} value={value} />
        </STable.Cell>
      );
    })
  );
};

export default RenderRows;
