import React, { useState } from "react";
import { Link } from "react-router-dom";
import InfoButtons from "./InfoButtons/InfoButtons";
import Table from "../../shared/SemanticTable/Table";

const BillingComponent = ({
  BillingInfoData: { balance },
  BillingInfoData
}) => {
  const [cells] = useState([
    { name: "#", value: null, key: "transactionId", order: 0 },
    { name: "Amount", value: null, key: "amount", order: 1 },
    { name: "Method", value: null, key: "method", order: 2 },
    { name: "Date", value: null, key: "created_at", order: 3 },
    { name: "Status", value: null, key: "status", order: 4 },
    { name: "Actions", value: true, key: "actions", order: 5 }
  ]);
  return (
    <React.Fragment>
      <div
        className="content-header clearfix pl-0 pr-0"
        style={{ borderBottom: "1px solid lightgray", marginBottom: "20px" }}
      >
        <h1>BILLING</h1>
        {(localStorage.customerType === "both" ||
          localStorage.customerType === "publisher") && (
          <Link className="btn btn-success pull-right" to="/control/withdraw">
            Withdraw
          </Link>
        )}
        {(localStorage.customerType === "both" ||
          localStorage.customerType === "advertiser") && (
          <Link
            className="btn btn-success pull-right"
            style={{ marginRight: "10px" }}
            to="/control/loadmoney"
          >
            Load Money
          </Link>
        )}
      </div>
      <div className="row p-3 justify-content-centers">
        <div className="col-sm-9 p-0 d-flex-column">
          <Table
            cells={cells}
            data={BillingInfoData.invoices}
            pending={BillingInfoData.pending}
          />
        </div>
        <InfoButtons balance={balance} BillingInfoData={BillingInfoData} />
      </div>
    </React.Fragment>
  );
};

export default BillingComponent;
