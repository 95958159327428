import React, { useState, useEffect } from "react";
import {
  Button,
  Input,
  Divider,
  Popup,
  Form as SemanticForm,
  Message
} from "semantic-ui-react";
import { Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import "./LoginComponent.scss";
import {
  email as emailPatter,
  password as passwordPattern
} from "../../../config/regex";

const Form = ({ handleSubmit, loading, error }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    return emailPatter.test(username) && passwordPattern.test(password)
      ? setDisabled(false)
      : setDisabled(true);
  }, [username, password]);

  const handleChange = (type, value) =>
    type === "email" ? setUsername(value) : setPassword(value);

  const onSubmit = () => handleSubmit({ username, password });

  return (
    <Col
      lg="6"
      md="10"
      sm="8"
      xs="8"
      className="login-form d-flex-column justify-content-center p-0"
    >
      <SemanticForm error>
        <Col className="login-email p-0">
          <SemanticForm.Field>
            {error && error.message && !loading && (
              <Message error header="Login Error" content={error.message} />
            )}
            <Input
              className="w-100"
              type="email"
              placeholder="Username"
              icon="user"
              iconPosition="left"
              value={username}
              onChange={({ target: { value } }) => handleChange("email", value)}
            />
          </SemanticForm.Field>
        </Col>
        <Col className="login-password p-0">
          <SemanticForm.Field className="mt-3 mb-3">
            <Popup
              position="left center"
              content="Password field should contain [A-z] , [1-9] , [!-*]"
              trigger={
                <Input
                  className="w-100"
                  type="password"
                  placeholder="Password"
                  icon="lock"
                  iconPosition="left"
                  value={password}
                  onChange={({ target: { value } }) =>
                    handleChange("password", value)
                  }
                />
              }
            />
          </SemanticForm.Field>
        </Col>
        <Col className="login-submit-btn p-0">
          <Button
            color="google plus"
            size="large"
            className="w-100 mb-2 submit-login"
            onClick={onSubmit}
            disabled={disabled || loading}
            loading={loading}
          >
            Sign In
          </Button>
        </Col>
      </SemanticForm>
      <Col className="d-flex justify-content-center p-0">
        <Link to="/forgot-password">
          <small className="forgot-password">Forgot password?</small>
        </Link>
      </Col>
      <Row className="justify-content-center social-media">
        <Divider
          horizontal
          style={{ fontWeight: "normal" }}
          className="pl-5 pr-5 col-sm-12"
        >
          <span style={{ lineHeight: "0px" }}>Or</span>
        </Divider>
        {/* <span>Continue with social media</span> */}
        <span>
          If you are not yet registered with us follow the link below:
        </span>
      </Row>
      {/* <Row className="w-100 justify-content-center m-0">
        <Button color="facebook" className="facebook-chip chip p-3">
          <Icon name="facebook f" className="m-0" size="large" />
        </Button>
        <Button color="twitter" className="twitter-chip chip p-3">
          <Icon name="twitter" className="m-0" size="large" />
        </Button>
        <Button color="google plus" className="google-chip chip p-3">
          <Icon name="google plus g" className="m-0" size="large" />
        </Button>
        <Button color="linkedin" className="linkedin-chip chip p-3">
          <Icon name="linkedin" className="m-0" size="large" />
        </Button>
      </Row> */}
      <Row className="w-100 justify-content-center m-0">
        <Button
          as={Link}
          to="/registration"
          color="linkedin"
          size="large"
          className="w-100 mt-2 register-login"
          disabled={loading}
        >
          Registration
        </Button>
      </Row>
    </Col>
  );
};

export default Form;
