import React, { useState } from "react";
import { Button, Form } from "semantic-ui-react";
import { Row, Col } from "reactstrap";
import "../LoginComponent/LoginComponent.scss";
import "./ForgotPasswordComponent.scss";

const ForgotPasswordComponent = React.memo(
  ({
    dashboardReducer: {
      optionsData: {
        options: { logo }
      }
    },
    handleSubmit
  }) => {
    const [userName, setUserName] = useState("");
    return (
      <section className="recover-container col-sm-6 p-0 d-flex align-items-center">
        <Row className="w-100 align-items-center justify-content-center">
          <Col
            sm="12"
            className="login-header d-flex flex-column align-items-center justify-content-center"
          >
            <span style={{ fontSize: "1.5rem" }}>Recover Password</span>
            <img src={logo} className="logo mt-3 mb-3" alt="" />
          </Col>
          <Col sm="12" className="inputs-box d-flex justify-content-center p-0">
            <Form className="login-form col-sm-6">
              <Form.Input
                type="email"
                label={
                  <label style={{ fontWeight: "normal" }}>
                    Recover your password
                  </label>
                }
                // value={props.containerState.data.email}
                placeholder="example@gmail.com"
                name="email"
                onChange={({ target: { value } }) => setUserName(value)}
                value={userName}
              />
            </Form>
          </Col>
          <Col
            sm="6"
            className="button-section forgot-password d-flex justify-content-between"
          >
            <Button
              className="back-btn col-sm-5 m-0"
              color="red"
              onClick={() => window.history.back()}
            >
              Back
            </Button>
            <Button
              className="forgot-btn col-sm-5 m-0"
              color="linkedin"
              disabled={false}
              onClick={() => handleSubmit(userName)}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </section>
    );
  }
);

export default ForgotPasswordComponent;
