import React, { useEffect } from "react";
import { Header, Message } from "semantic-ui-react";
import { Row, Col } from "reactstrap";
import Form from "./Form";
import "./LoginComponent.scss";

const LoginComponent = React.memo(
  ({
    dashboardReducer: {
      optionsData: {
        options: { logo },
        provider,
        displayName
      }
    },
    handleSubmit,
    loading,
    error,
    registration,
    resetRegistration
  }) => {
    useEffect(() => {
      return () => {
        resetRegistration();
      };
    });

    return (
      <section className="container-fluid d-flex login-container justify-content-center align-items-center p-0">
        <Row
          className="w-100 align-items-center justify-content-center"
          style={{ maxHeight: "max-content" }}
        >
          <Col
            sm="12"
            md="6"
            className="d-flex align-items-center"
            style={{ flexDirection: "column" }}
          >
            <span style={{ fontSize: "1.5rem" }}>Wellcome To</span>
            <Col
              sm="6"
              className="login-header d-flex justify-content-center p-0 mt-3 mb-3 align-items-center"
            >
              <img src={logo} className="logo" alt="" />
            </Col>
            <Col sm="6" className="mb-3">
              {registration.redirect &&
                registration.message &&
                !error &&
                !loading && (
                  <Message
                    success
                    header="Your user registration was successful"
                    content={`Congratulations, You have successfully registered in ${displayName} VR &
                          AR Advertising Network, Please follow with email verification and
                          your support team will contact you shortly for your account
                          approval.`}
                  />
                )}
            </Col>
            <Form handleSubmit={handleSubmit} loading={loading} error={error} />
          </Col>
          <Col className="col-sm-6 left-side-bg" />
          <Row className="left-side-container">
            <Col
              lg="12"
              md="8"
              sm="8"
              className="d-flex justify-content-center p-0"
            >
              <Header
                textAlign="center"
                size="huge"
                style={{
                  color: "white",
                  fontSize: "1.7rem",
                  fontStyle: "italic"
                }}
              >
                &ldquo;{" "}
                <span className="login-quote">
                  The best advertising should make you{" "}
                  <span className="marked-word login-quote">nervous</span> about
                  what you&apos;re buying ... There is too much smart-ass
                  advertising today and not enough that{" "}
                  <span className="marked-word login-quote">
                    emotionally moves
                  </span>{" "}
                  consumers to go out and buy something
                </span>{" "}
                &ldquo;
                <br />
                <span className="d-block text-right mt-3 login-quote">
                  Marry Wells Lawrence
                </span>
              </Header>
            </Col>
          </Row>

          <div className="button-section col-sm-6 d-flex justify-content-center p-0" />
        </Row>
      </section>
    );
  }
);

export default LoginComponent;
