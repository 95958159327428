import React, { useState, useEffect } from "react";
import { Button, Modal, Form, Select, Message } from "semantic-ui-react";
import { Col } from "reactstrap";
import useOptions from "../App/useOptions";

const regExp = {
  url: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/
};

const CreateApp = ({ open, toggle, handleSubmit, provider }) => {
  const [mediaType, category, genre] = useOptions();
  const [disabled, setDisabled] = useState(true);

  const [initialState, setState] = useState([
    {
      value: "",
      label: "Media Type",
      key: "media_type",
      placeholder: "App",
      options: [...mediaType]
    },
    {
      value: "",
      label: "Category",
      key: "category",
      placeholder: "Business",
      options: [...category]
    },
    {
      value: "",
      label: "Genre",
      key: "genre",
      placeholder: "360video",
      options: [...genre]
    },
    {
      value: "",
      label: "Name",
      key: "name",
      placeholder: "John Doe"
    },
    {
      value: "",
      label: "URL",
      key: "url",
      placeholder: "https://example.com"
    }
  ]);

  const [form, setForm] = useState({
    category: "",
    genre: "",
    media_type: "",
    name: "",
    provider: "",
    url: ""
  });

  useEffect(() => {
    if (regExp.url.test(form.url)) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [form]);

  const handleChange = (value, index) =>
    setState(prevState => {
      const mutable = prevState.map((it, i) => {
        if (index === i) {
          const item = { ...it };
          item.value = value;
          setForm({ ...form, [it.key]: value });
          return item;
        }
        return it;
      });
      return [...mutable];
    });

  const onSubmit = () => {
    if (!disabled) {
      handleSubmit({ ...form, provider });
      toggle();
    }
  };

  return (
    <Col>
      <Modal
        size="tiny"
        open={open}
        onClose={toggle}
        style={{ position: "relative", height: "auto" }}
      >
        <Modal.Header>Add New App / Website</Modal.Header>
        <Modal.Content>
          <Form>
            {initialState.map((it, index) => {
              if (it.options) {
                return (
                  <Form.Dropdown
                    control={Select}
                    label={it.label}
                    key={it.key}
                    options={it.options}
                    onChange={(e, { value }) => handleChange(value, index)}
                    placeholder={it.placeholder}
                  />
                );
              }
              return (
                <Form.Field key={it.key}>
                  <label>{it.label}</label>
                  <input
                    placeholder={it.placeholder}
                    onChange={({ target: { value } }) =>
                      handleChange(value, index)
                    }
                  />
                </Form.Field>
              );
            })}
            {form.url && disabled && (
              <Message
                className="d-flex"
                error
                header="Invalid URL"
                content="Please fill valid URL."
              />
            )}
            <Button color="green" onClick={onSubmit} disabled={disabled}>
              Add New App
            </Button>
            <Button color="red" onClick={toggle}>
              Close
            </Button>
          </Form>
        </Modal.Content>
      </Modal>
    </Col>
  );
};

export default CreateApp;
