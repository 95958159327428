import React, { useState, useEffect } from "react";

const usePasswordField = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [valid, setValid] = useState(true);
  const [pristine, setPristine] = useState(true);
  const [blur, setBlur] = useState(false);

  useEffect(() => {
    if (password && confirmPassword && password !== confirmPassword && blur) {
      setValid(false);
    } else {
      setValid(true);
    }
  }, [blur, confirmPassword, password]);

  useEffect(() => {
    if (password && confirmPassword) {
      setPristine(false);
    }
  }, [confirmPassword, password]);

  const handleChange = (type, value) =>
    type === "password" ? setPassword(value) : setConfirmPassword(value);

  return [password, confirmPassword, handleChange, valid, pristine, setBlur];
};

export default usePasswordField;
