import React, { useState, useCallback } from "react";
import { Col } from "reactstrap";
import { Form, Button } from "semantic-ui-react";
import SelectCountries from "./SelectCountries";
import "./RegistrationSecondComponent.scss";

const RegistrationSecondComponent = React.memo(
  ({ logo, secondStep, loading, userInfo }) => {
    console.log(userInfo);
    const [data, setValue] = useState([
      { value: "", type: "text", key: "first_name", label: "First Name" },
      { value: "", type: "text", key: "last_name", label: "Last Name" },
      { value: "", type: "text", key: "address", label: "Address" },
      { value: "", type: "text", key: "address2", label: "Address 2" },
      { value: "", type: "number", key: "postal_code", label: "Zip Code" },
      { value: "", type: "number", key: "phone", label: "Phone Number" },
      { value: "", type: "select", key: "country", label: "Country" },
      { value: "", type: "text", key: "city", label: "City" },
      {
        value: "",
        type: "select",
        key: "legal",
        label: "Legal",
        options: [
          {
            value: "individual",
            text: "Individual - Natural Person",
            key: "individual"
          },
          {
            value: "company",
            text: "Company - Legal Person",
            key: "company"
          }
        ]
      }
    ]);

    const handleChange = useCallback((value, id) => {
      if (value === "company") {
        return setValue(prevState => {
          const mutable = prevState.map(it => ({ ...it }));
          const state = mutable.map((field, index) => {
            const item = { ...field };
            if (index === id) {
              item.value = value;
            }
            return item;
          });

          state.push({
            value: "",
            type: "text",
            key: "companyName",
            label: "Company Name"
          });
          return [...state];
        });
      }

      if (value === "individual") {
        return setValue(prevState =>
          prevState
            .map((field, index) => {
              const mutable = { ...field };
              if (index === id) {
                mutable.value = value;
              }
              return mutable;
            })
            .filter(it => it.key !== "companyName")
        );
      }

      return setValue(prevState =>
        prevState.map((field, index) => {
          const mutable = { ...field };
          if (index === id) {
            mutable.value = value;
          }
          return mutable;
        })
      );
    }, []);

    const handleSubmit = () => {
      const formValues = {};
      data.forEach(it => {
        formValues[it.key] = it.value;
      });
      secondStep(formValues);
    };

    return (
      <Col
        sm="6"
        className="login d-flex justify-content-center align-items-center registration-second-container"
      >
        <Form
          className="row w-100 registration-second-form"
          style={{ height: "max-content" }}
        >
          <Col
            sm="12"
            className="d-flex justify-content-center flex-column align-items-center mb-4"
          >
            <span style={{ fontSize: "1.7rem" }}>Registration Second Step</span>
            <img src={logo} alt="" className="logo mt-3 mb-3" />
          </Col>
          {data.map((field, index) => {
            if (field.type === "text" || field.type === "number") {
              return (
                <Form.Input
                  fluid
                  key={field.key}
                  label={field.label}
                  placeholder={field.label}
                  type={field.type}
                  value={field.value}
                  className="col-sm-6"
                  required={field.key !== "address2" && true}
                  onChange={({ target: { value } }) =>
                    handleChange(value, index)
                  }
                />
              );
            }
            return (
              <SelectCountries
                field={field}
                key={field.key}
                onChange={handleChange}
                id={index}
                defaultValue={userInfo}
              />
            );
          })}
          <Col sm="12" className="d-flex justify-content-center">
            <Button
              color="linkedin"
              size="large"
              className="col-sm-6 mt-2 reg-btn"
              type="submit"
              onClick={() => handleSubmit()}
              loading={loading}
            >
              Save
            </Button>
          </Col>
        </Form>
      </Col>
    );
  }
);

export default RegistrationSecondComponent;
