import { forEach } from "lodash";
import {
  LOGIN,
  CHECK_EMAIL,
  REGISTRATION,
  VERIFY_EMAIL,
  PROFILE_INFO,
  RESET_REGISTRATION,
  RESET_LOGIN_ERRORS,
  GET_USER_INFO
} from "../constants";
import Auth from "../../shared/Auth/Auth";
import CommonAction from "../../shared/Http/Http";
import {ENV} from "../../environment";

export const LoginAction = ({ username, password }) => async dispatch => {
  try {
    dispatch({ type: LOGIN.PENDING });
    const user = await Auth.signIn(username, password);
    if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
      dispatch({ type: LOGIN.PASSWORD_CHANGE_REQUIRED, payload: user });
    } else if (user.challengeName === "SMS_MFA") {
      dispatch({ type: LOGIN.MFA_REQUIRED, payload: user });
    } else {
      const {
        signInUserSession: {
          accessToken,
          idToken: { jwtToken }
        },
        attributes: sessionAttributes
      } = await Auth.currentAuthenticatedUser();
      /**
       * @Session_Destructuring
       */
      const attributes = await Auth.userAttributes(user);
      const token = accessToken.jwtToken;
      const idToken = jwtToken;
      const payload = { token, idToken };

      localStorage.setItem("customerType", sessionAttributes["custom:type"]);
      localStorage.setItem("userToken", token);
      localStorage.setItem("user", JSON.stringify(payload));

      forEach(attributes, ({ Name, Value }) => {
        if (Name === "custom:first_time" && Value === "false") {
          localStorage.setItem("first_time", "false");
          payload.firstTime = false;
        } else if (Name === "custom:first_time" && Value === "true") {
          localStorage.setItem("first_time", "true");
          payload.firstTime = true;
        }
      });

      dispatch({ type: LOGIN.SUCCESS, payload });
    }
  } catch (error) {
    const mutable = { ...error };
    if (error.message === "PostAuthentication failed with error 407.") {
      mutable.message =
        "Your account is not approved yet. Please give us some time to get your account approved.";
      dispatch({ type: LOGIN.ERROR, error: mutable });
    } else if (error.message === "User is not confirmed.") {
      mutable.message =
        "Your account is not confirmed. Please enter to your email and follow procedure instructions.";
      dispatch({ type: LOGIN.ERROR, error: mutable });
    } else if (error.message === "PostAuthentication failed with error 408.") {
      mutable.message =
        "Your account is blocked. Please contact customer support for further assistance.";
      dispatch({ type: LOGIN.ERROR, error: mutable });
    } else dispatch({ type: LOGIN.ERROR, error });
  }
};

export const LoginPasswordChangeAction = ({ user, password }) => dispatch => {
  dispatch({ type: LOGIN.PENDING });
  Auth.completeNewPassword(user, password, {})
    .then(() => {
      Auth.currentAuthenticatedUser()
        .then(session => {
          const token = session.signInUserSession.accessToken.jwtToken;
          const idToken = session.signInUserSession.idToken.jwtToken;
          const payload = { token, idToken };
          localStorage.setItem("userToken", token);
          localStorage.setItem("user", JSON.stringify(payload));
          dispatch({ type: LOGIN.SUCCESS, payload });
        })
        .catch(error => dispatch({ type: LOGIN.ERROR, error }));
    })
    .catch(error => dispatch({ type: LOGIN.ERROR, error }));
};

export const LoginMFAAction = ({ user, code }) => dispatch => {
  try {
    dispatch({ type: LOGIN.PENDING });
    Auth.confirmSignIn(user, code, "SMS_MFA")
      .then(() => {
        Auth.currentAuthenticatedUser()
          .then(session => {
            const token = session.signInUserSession.accessToken.jwtToken;
            const idToken = session.signInUserSession.idToken.jwtToken;
            const payload = { token, idToken };
            localStorage.setItem("userToken", token);
            localStorage.setItem("user", JSON.stringify(payload));
            dispatch({ type: LOGIN.SUCCESS, payload });
          })
          .catch(error => dispatch({ type: LOGIN.ERROR, error }));
      })
      .catch(error => dispatch({ type: LOGIN.ERROR, error }));
  } catch (error) {
    dispatch({ type: LOGIN.ERROR, error: error });
  }
};

export const LogoutAction = () => async dispatch => {
  try {
    dispatch({ type: LOGIN.LOGOUT_PENDING });

    await Auth.signOut();
    localStorage.removeItem("userToken");
    localStorage.removeItem("user");
    dispatch({ type: LOGIN.LOGOUT_SUCCESS });
  } catch (error) {
    dispatch({ type: LOGIN.LOGOUT_ERROR, error });
  }
};

export const getUserInfo = body =>
  CommonAction("GET", null, `control/profile/info`, GET_USER_INFO, body);

export const CheckEmail = ({ email, provider }) =>
  CommonAction(
    "GET",
    null,
    `control/verify-reg-email?email=${email}&provider=${provider}`,
    CHECK_EMAIL,
    null
  );

export const onRegistration = body =>
  CommonAction("POST", null, `control/registration`, REGISTRATION, body);

export const onVerifyEmail = body =>
  CommonAction("POST", null, `control/user/verify-email`, VERIFY_EMAIL, body);

export const onProfileInfo = body =>
  CommonAction("PUT", null, `control/profile/info`, PROFILE_INFO, body);

export const resetRegistration = () => ({ type: RESET_REGISTRATION });

export const resetLoginErrors = () => ({ type: RESET_LOGIN_ERRORS });
