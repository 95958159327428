import React, { useEffect, useState, useCallback } from "react";
import { Row, Col } from "reactstrap";
import moment from "moment";
import Table from "../../shared/SemanticTable/Table";
import "./ReportingsComponent.scss";
import Loader from "../../shared/Loader";
import useCells from "../../shared/SemanticTable/useCells";
import Tabs from "../../shared/SemanticTable/Tabs";
import getCountries from "../../config/useCountries";

const ReportingsComponent = ({ reportsData, getReports, userType, provider }) => {
  const [activeItem, setActiveItem] = useState("Date");
  const [cells] = useCells(reportsData.data, activeItem);
  const [dataMapped, setDataMapped] = useState(null);

  useEffect(() => {
    if (reportsData.data) {
      const countries = getCountries();
      const mapped = reportsData.data.map(it => {
        const item = { ...it };
        if (item.country) {
          countries.forEach(country => {
            if (item.country === country.value) {
              item.country = country.text;
            }
          });
        }
        return item;
      });
      setDataMapped([...mapped]);
    }
  }, [reportsData]);

  return (
    <React.Fragment>
      <div
        className="content-header clearfix"
        style={{ borderBottom: "1px solid lightgray", marginBottom: "20px" }}
      >
        <h1>REPORTS</h1>
      </div>
      <div className="container-non-responsive">
        <Row>
          <Col sm="12">
            <div className="tab">
              <Tabs
                provider={provider}
                getData={getReports}
                activeItem={activeItem}
                setActiveItem={setActiveItem}
                userType={userType}
              />
            </div>
          </Col>
        </Row>
      </div>
      {reportsData.pending && <Loader />}
      <Table
        cells={cells}
        data={dataMapped}
        isLoading={reportsData.pending}
        infinite
        type="reports"
        lastEvaluatedKey={reportsData.LastEvaluatedKey}
        getItems={getReports}
        provider={provider}
      />
    </React.Fragment>
  );
};

export default ReportingsComponent;
