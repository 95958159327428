import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import InnerPagesComponent from "../../components/InnerPagesComponent/InnerPagesComponent";
import ReportingsComponent from "../../components/ReportingsComponent/ReportingsComponent";
import {
  getReports,
  getReportsLast,
  resetReportsReducer
} from "../../store/actions/reportsActions";
import { ChangeCompaingsStatus } from "../../store/actions/CompaignsActions";

const AdvertiserReportingsContainer = ({
  optionsData: {
    options: { logo },
    displayName,
    provider
  },
  getReports,
  resetReducer,
  reportsData,
  getReportsLast
}) => {
  return (
    <div>
      <InnerPagesComponent pageName="" logo={logo}>
        <Helmet>
          <title>Reports | {displayName} Control Panel</title>
        </Helmet>
        <ReportingsComponent
          userType="advertiser"
          getReports={getReports}
          resetReducer={resetReducer}
          reportsData={reportsData}
          getReportsLast={getReportsLast}
          provider={provider}
        />
      </InnerPagesComponent>
    </div>
  );
};

const mapStateFromProps = store => ({
  optionsData: store.DashboardReducer.optionsData,
  reportsData: store.reportsReducer.reportsData
});

const mapDispatchFromProps = dispatch => ({
  getReports: data => dispatch(getReports(data)),
  getReportsLast: data => dispatch(getReportsLast(data)),
  changeStatusAction: data => dispatch(ChangeCompaingsStatus(data)),
  resetReducer: () => dispatch(resetReportsReducer())
});

export default withRouter(
  connect(
    mapStateFromProps,
    mapDispatchFromProps
  )(AdvertiserReportingsContainer)
);
