import React from "react";
import { connect } from "react-redux";
import AWS from "aws-sdk";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import ReactS3Uploader from "react-s3-uploader";
import { ENV } from "../../environment";
import InnerPagesComponent from "../../components/InnerPagesComponent/InnerPagesComponent";
import CreateCompaignsComponent from "../../components/CreateCompaignsComponent/CreateCompaignsComponent";
import {
  addCompaings as addData,
  resetCampaignsDataReducer as resetData
} from "../../store/actions/CompaignsActions";

const CompaignsCreateContainer = ({
  addCompaings,
  addCompaignsData,
  optionsData: {
    options: { logo },
    displayName,
    provider
  }
}) => {
  const onCompainCreate = (file, id, fileName, name) => {
    console.log("FILE =>", file);
    AWS.config.update({
      accessKeyId: ENV.AWS_accessKey,
      secretAccessKey: ENV.AWS_secretKey,
      region: ENV.REGION
    });
    const s3 = new AWS.S3({ signatureVersion: "v4" });
    const params = {
      Bucket: "adlooker-creative",
      Key: `${fileName}/${id}/${name}`,
      Body: file && file.data ? file.data : file,
      "x-amz-acl": "public-read",
      ContentType: file.data && file.data.type ? file.data.type : file.type
    };
    // partSize: file.data.size > 5242880 ? file.data.size : 5242880,
    // partSize: file.size,
    // "x-amz-acl": "public-read",
    // queueSize: 1
    const upload = s3.upload(
      params,
      <ReactS3Uploader
        signingUrl="/s3/sign"
        signingUrlMethod="GET"
        accept="image/*"
        s3path="/uploads/"
        // preprocess={this.onUploadStart}
        // onProgress={this.onUploadProgress}
        // onError={this.onUploadError}
        // onFinish={this.onUploadFinish}
        // signingUrlHeaders={{ additional: headers }}
        // signingUrlQueryParams={{ additional: query - params }}
        signingUrlWithCredentials // in case when need to pass authentication credentials via CORS
        uploadRequestHeaders={{ "x-amz-acl": "public-read" }} // this is the default
        contentDisposition="auto"
        scrubFilename={filename => filename.replace(/[^\w\d_\-.]+/gi, "")}
        server="http://cross-origin-server.com"
        // inputRef={cmp => (this.uploadInput = cmp)}
        autoUpload
      />
    );
    upload.on("httpUploadProgress", progress => {
      console.log(progress);
    });
    return upload.promise();
  };

  return (
    <div>
      <InnerPagesComponent pageName="CAMPAIGNS | NEW CAMPAIGN" logo={logo}>
        <Helmet>
          <title>Campaigns | {displayName} Control Panel</title>
        </Helmet>
        <CreateCompaignsComponent
          onCompainCreate={onCompainCreate}
          addCompaings={addCompaings}
          addCompaignsData={addCompaignsData}
          provider={provider}
        />
      </InnerPagesComponent>
    </div>
  );
};

const mapStateToProps = store => ({
  addCompaignsData: store.CompaignsReducer.addCompaigns,
  optionsData: store.DashboardReducer.optionsData
});

const mapDispatchToProps = dispatch => ({
  addCompaings: body => dispatch(addData(body)),
  resetCampaignsData: () => dispatch(resetData())
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CompaignsCreateContainer)
);
