const prod_configs = {
  COGNITO_USERPOOL : 'us-west-2_AsyXJdF44',
  COGNITO_CLIENT : '6t1fvagj8efacio5iqsf4k1aer',
  COGNITO_IDENTITY_POOL : 'us-west-2:cf147044-a91e-4197-9216-c95280feff4f',
  REGION: 'us-west-2',
  HOST : 'https://api.adlooker.com',
  AWS_accessKey: 'AKIA2XTX3DG3CZADQDTX',
  AWS_secretKey: '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'
};

export default prod_configs;
